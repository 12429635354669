import axios from "../helpers/axiosConfig";
import { getLocalStorageData, getSessionId } from "./storageData";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';

export const GetEntityListofDocumentsByLoanId = async (data) => {
    try {
        const response = await axios.get(`/api/Document/GetEntityListofDocumentsByLoanId`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}


export const GetDocumentList = async (data) => {
    try {
        const response = await axios.get(`/api/Document/GetDocumentList`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        throw err;
    }
}

export const UpdateDocumentVerify_Active = async (data) => {
    try {
        const response = await axios.get(`/api/Document/UpdateDocumentVerify_Active`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        return err
    }
}

export const UploadDocument = async (formData, params) => {
    try {
        const response = await axios.post(`/api/Document/UploadDocument`, formData, { params });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        return err
    }
}


export const GetDocumentsHistory = async (data) => {
    try {
        const response = await axios.get(`/api/Document/GetDocumentsHistory`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        return err
    }
}


export const GetDownloadAllDocuments = async (data, url) => {
    try {
        const response = await axios.get(`/api/Document/${url}`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        return err
    }
}

const createFinalDataParms = (data) => {
    let dataParams = {};
    if (data.DocumentType === "BGD" && data.Status === "To sign" && data.FileName === "") {
        dataParams = {
            LoanId: data.LoanId,
            fileName: data.TD_Name,
            DwnFileName: data.Name,
            ownerId: data.OwnerID,
            UserId: data.UserId,
            Doctype: data.DocType,
            DownloadDocumentURL: "/api/Document/GetDownload_BGD_TemplateSystemDocuments"
        };
    } else {
        dataParams = {
            filename: data.FileName,
            isSigned: data.Status === "Completed",
            Status: data.Status,
            DocumentType: data.DocumentType,
            DownloadDocumentURL: "/api/Document/GetDownloadDocumentHistoryFile"
        };
    }
    return dataParams;
}

export const GetDownloadDocumentHistoryFile = async (data) => {
    try {
        const isInsideWebView = window.ReactNativeWebView !== undefined;
        if (isInsideWebView) {
            const finalParams = [];
            Object.values(data).forEach(async (element) => {
                const dataParams = createFinalDataParms(element);
                finalParams.push({ ...dataParams });
            });
            window.ReactNativeWebView.postMessage(JSON.stringify({ action: "downloadPdf", finalParams, sessionId: getSessionId(), authToken: getLocalStorageData("token") }));
        } else {
            const zip = new JSZip();
            await Promise.all(
                Object.values(data).map(async (element) => {
                    const dataParams = createFinalDataParms(element);
                    const DownloadDocumentURL = dataParams.DownloadDocumentURL;

                    // Fetch the document from the server
                    const response = await axios.get(DownloadDocumentURL, {
                        params: {
                            ...dataParams,
                            SessionId: getSessionId()
                        },
                        responseType: 'arraybuffer'
                    });

                    if (response.data) {
                        const blob = new Blob([response.data], { type: 'application/pdf' });
                        // Add document to the zip if more than 1
                        if (Object.values(data).length > 1) {
                            zip.file(`${element.FileName || element.filename || element.Name}.pdf`, blob);
                        } else {
                            // Directly download the single document
                            const link = document.createElement('a');
                            link.download = `${element.FileName || element.filename || element.Name}.pdf`;
                            link.href = window.URL.createObjectURL(blob);
                            link.click();
                            window.URL.revokeObjectURL(link.href);
                        }
                    }
                })
            );

            // Check if multiple documents were selected and download a zip
            if (Object.values(data).length > 1) {
                const zipBlob = await zip.generateAsync({ type: "blob" });
                saveAs(zipBlob, 'documents.zip'); // Save the ZIP file
            }
        }
        return true;
    } catch (err) {
        console.error(err);
        return false;
    }
};


export const ViewDocumentInBrowser = async (data, type = false) => {
    try {
        const ViewDocumentURL = type ? `/api/Document/GetDownload_BGD_TemplateSystemDocuments` : `/api/Document/GetDownloadDocumentHistoryFile`;
        const response = await axios.get(ViewDocumentURL, {
            params: { ...data, SessionId: getSessionId() },
            responseType: 'arraybuffer'
        });
        if (response.data) {
            return response;
        }
    } catch (err) {
        console.log(err);
        return err;
    }
}


export const SendDocumentToEmail = async (data, params) => {
    try {
        const response = await axios.post(`/api/Document/SendDocumentToEmail`, data,
            {
                params: {
                    SessionId: getSessionId(),
                    ...params
                }
            }
        );
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        return err
    }
}

export const MarkAsCompletedDocuments = async (data) => {
    try {
        const response = await axios.post(`/api/Document/MarkAsCompletedDocuments`, data,
            {
                params: {
                    SessionId: getSessionId()
                }
            }
        );
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        return err
    }
}

export const GetAllDocumentList = async () => {
    try {
        const response = await axios.get(`/api/Document/GetAllDocumentList`, { params: { SessionId: getSessionId() } });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        return err
    }
}

export const GetAGDDocumentDetailsById = async (data) => {
    try {
        const response = await axios.get(`/api/Document/GetAGDDocumentDetailsById`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        return err
    }
}

export const DownloadZipFileWithURL = async (FileName) => {
    try {
        const response = await axios.get(`/api/Document/DownloadZip`, {
            params: { FileName: FileName },
            responseType: 'blob',
        });

        if (response.data) {
            const blob = new Blob([response.data], { type: 'application/zip' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'sample.zip';
            link.click();

            // Revoke the URL to free memory
            window.URL.revokeObjectURL(link.href);
            return response;
        }
    } catch (error) {
        console.log(error);

    }
}

export const InsertUpdateAGDDocument = async (data, params) => {
    try {
        const response = await axios.post(`/api/Document/InsertUpdateAGDDocument`, data,
            {
                params: {
                    SessionId: getSessionId(),
                    ...params
                }
            }
        );
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        return err
    }
}

export const SaveDocumentCoordinates = async (data) => {
    try {
        const response = await axios.post(`/api/Document/SaveDocumentCoordinates`, data,
            {
                params: {
                    SessionId: getSessionId()
                }
            }
        );
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        return err
    }
}

export const SentSignatureForAGD = async (data) => {
    try {
        const response = await axios.post(`/api/Document/SentSignatureForAGD`, data,
            {
                params: {
                    SessionId: getSessionId()
                }
            }
        );
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        return err
    }
}

export const SentSignatureForBGD = async (data) => {
    try {
        const response = await axios.post(`/api/Document/SentSignatureForBGD`, data,
            {
                params: {
                    SessionId: getSessionId()
                }
            }
        );
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        return err
    }
}

export const MultipleDocumentSentSignature = async (data, userId) => {
    try {
        const response = await axios.post(`/api/Document/MultipleDocumentSentSignature`, data,
            {
                params: {
                    SessionId: getSessionId(),
                    CreatedBy: userId
                }
            }
        );
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        return err
    }
}

export const GetHelloSignDownloadRequest = async (data) => {
    try {
        data['Type'] = data['Type'].replaceAll("'", "");
        data['events'] = data['event']
        delete data['event']
        const instance = axios.create()
        const response = await instance.get(`/api/Document/GetHelloSignDownloadRequest`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        return err
    }
}

export const CancelHelloSignSignature = async (data) => {
    try {
        const response = await axios.post(`/api/Document/CancelHelloSignSignature`, data,
            {
                params: {
                    SessionId: getSessionId()
                }
            });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        return err
    }
}


export const RemindHelloSignSignature = async (data) => {
    try {
        const response = await axios.post(`/api/Document/RemindHelloSignSignature`, data,
            {
                params: {
                    SessionId: getSessionId()
                }
            });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        return err
    }
}

export const GetDownloadUnCompletedDocumentList = async (data) => {
    try {
        const response = await axios.get(`/api/Document/GetDownloadUnCompletedDocumentList`, {
            params: data,
            responseType: 'arraybuffer' // Ensure the response is in arraybuffer format
        });
        if (response.data) {
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'uncompleted_documents.docx';
            link.click();
            window.URL.revokeObjectURL(link.href);
            return response;
        }
    } catch (err) {
        console.log(err);
        return err;
    }
}

export const SendUnCompletedDocumentList = async (data) => {
    try {
        const response = await axios.get(`/api/Document/SendUnCompletedDocumentList`,
            {
                params: {
                    SessionId: getSessionId(),
                    LoanId: data.LoanId,
                    MailId: data.MailId.toString()
                }
            }
        );
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        return err;
    }
}

export const InsertUpdateDocumentReminder = async (data, timezone) => {
    try {
        const response = await axios.post(`/api/Document/InsertUpdateDocumentReminder`, data,
            {
                params: {
                    SessionId: getSessionId(),
                    myTimeZoneId: timezone
                }
            });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        return err;
    }
}

export const GetDocumentReminderDetails = async (data) => {
    try {
        const response = await axios.get(`/api/Document/GetDocumentReminderDetails`, { params: data });
        if (response.data) {
            return response.data;
        }
    } catch (err) {
        console.log(err);
        return err;
    }
}

export const DownloadHelloSignedFile = async (data) => {
    try {
        const response = await axios.get(`/api/Document/DownloadHelloSignedFile`, {
            params: data,
            responseType: 'arraybuffer'
        });
        if (response.data) {
            const blob = new Blob([response.data], { type: 'application/pdf' });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = 'signed_document.pdf';
            link.click();
            window.URL.revokeObjectURL(link.href);
            return response;
        }
    } catch (err) {
        console.log(err);
        return err;
    }
}