/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import Modal from 'react-bootstrap/Modal'
import close_popup from '../assets/icons/close_popup.svg';
import DynamicTable from '../plugins/DynamicTable';
import { userLenderLogsColumns } from '../helpers/DropdownsAndMappers';
import axios from '../helpers/axiosConfig';
import Constants from '../helpers/Constants';
import { useDispatch, useSelector } from 'react-redux';
import reducersAndActions from '../_redux/slices/index';
import { encryptText } from '../helpers/DynamicFunctions';
import { getSessionId } from '../services/storageData';
import Preloader from '../plugins/Preloader';
import { Link } from 'react-router-dom';
import ShowRatingQuestions from '../reusablecomponets/ShowRatingQuestions';
import moment from 'moment';
import { debounce } from 'lodash';

const tempAppIndexData = {
    id: 0,
    communication: 0, communicationText: "Frequency",
    expertise: 0, expertiseText: "SBA",
    guidance: 0, guidanceText: "Loan Structuring",
    reuseLender: 0, reuseLenderText: "Communication",
    efficiency: 0, efficiencyText: "Confusing",
    factorsInfluence: "",
    comment: ""
}

const UserLenderRating = ({ info }) => {
    const dispatch = useDispatch();

    const user = useSelector((state) => state.auth);

    const [loading, setLoading] = useState(false);
    const [userLoanApplications, setUserLoanApplications] = useState([]);

    const [userLenderLogs, setUserLenderLogs] = useState([]);
    const [totalData, setTotalData] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [pageIndex, setPageIndex] = useState(1);

    const [emailFields, setEmailFields] = useState({ to: '', body: '', loanId: '', applicationNumber: '', createdDate: '', borrowAmount: '', businessCategory: '', typeOfBusiness: '', applicationStatus: '', lenderId: '', advisorId: '', advisorName: '' });

    // Accordion state
    const [accordionIndex, setAccordionIndex] = useState({});
    const [appIndexData, setAppIndexData] = useState({});

    const [totalDealClosed, setTotalDealClosed] = useState(0);
    const [averageRating, setAverageRating] = useState(0.0);

    const accordianClass = (id, isDisabled) => {
        return accordionIndex[id] || isDisabled ? "open-user-applicaion-dtls-accordian" : "closed-user-applicaion-dtls-accordian";
    }

    // Fetch inquiries whenever page size or page index changes
    const debounceDoucmentList = useCallback(debounce(() => {
        getUsersLoanApplications();
    }, 1000), [info, user, pageIndex, pageSize]);

    useEffect(() => {
        debounceDoucmentList();
        return () => {
            debounceDoucmentList.cancel();
        };
    }, [debounceDoucmentList]);


    // API call to get user's loan applications
    const getUsersLoanApplications = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`/api/User/GetLoanApplicationListByLenderId`, {
                params: {
                    UserId: info.id,
                    SessionId: localStorage.getItem('sessionId'),
                }
            });
            setLoading(false);
            const finalData = response.data.resultObject
            if (response.status === 200) {

                const accordionList = {};
                const appIndexList = {};
                const ratingData = {}

                finalData.lstApplicationRating && finalData.lstApplicationRating.forEach((item) => {
                    ratingData[item.loanId + "_" + item.advisorId] = item
                });

                finalData.lstLoanDetail && finalData.lstLoanDetail.forEach((item) => {
                    accordionList[item.loanId + "_" + item.advisorId] = false
                    appIndexList[item.loanId + "_" + item.advisorId] = ratingData.hasOwnProperty(item.loanId + "_" + item.advisorId) ? ratingData[item.loanId + "_" + item.advisorId] : tempAppIndexData
                });

                setAccordionIndex(accordionList);
                setAppIndexData(appIndexList);

                setUserLoanApplications(finalData.lstLoanDetail !== null ? finalData.lstLoanDetail : []);
                setAverageRating(finalData.avgRating || 0.0)
                setTotalDealClosed(finalData.noOfDeals)

                // Only here we handle pagination from frontend side becuase in this api we got other data as well
                const paginate = (array, pageNumber, pageSize) => {
                    const startIndex = (pageNumber - 1) * pageSize;
                    return array.slice(startIndex, startIndex + pageSize);
                };

                const totalLength = finalData.lstRatingLogs !== null ? finalData.lstRatingLogs.length : 0;
                setTotalData(totalLength)
                setUserLenderLogs(totalLength > 0 ? paginate(finalData.lstRatingLogs, pageIndex, pageSize) : [])
            }
        } catch (error) {
            setLoading(false);
            console.error('Error fetching user loan applications: ', error);
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: error.message, type: "error" }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        }
    };

    const handleAccordion = (index) => {
        setAccordionIndex({ ...accordionIndex, [index]: !accordionIndex[index] });
    }

    const setHardDataOfOpenAI = (value, key) => {
        const [fieldName, appId] = key.split('_')
        setAppIndexData({ ...appIndexData, [appId]: { ...appIndexData[appId], [fieldName]: value } })
    }

    //Email Popup function
    const [sendEmailPopupShow, setSendEmailPopup] = useState(false);
    const sendEmailPopupClose = () => setSendEmailPopup(false);

    //Submitted Rating Popup function
    const [showSubmittedRatingPopup, setSubmittedRatingPopup] = useState(false);
    const showSubmittedRatingPopupClose = () => setSubmittedRatingPopup(false);
    const [ratingDataToShow, setRatingDataToShow] = useState([]);
    const [ratingIndexData, setRatingIndexData] = useState({});

    const dynamicTableDisplay = (data, index) => {
        return (
            <tr>
                <td><b>{data.userName}</b></td>
                <td>{data.userType}</td>
                <td>{moment.utc(data.commentDate).local().format("MMM DD, YYYY")}</td>
                <td>{data.transaction}</td>
                <td><Link onClick={() => handleShowRatingPopView(data)}>{(parseFloat(data.rating)).toFixed(2) || 0.0}</Link></td>
                <td>{data.admin}</td>
            </tr>
        )
    }

    const handleShowRatingPopView = async (data) => {
        try {
            const tempRatingData = await axios.get('/api/User/GetApplicationRatingDetailsById', { params: { SessionId: getSessionId(), Id: data['id'] } });
            setRatingDataToShow([{ ...tempRatingData.data.resultObject }]);
            setRatingIndexData({ [data.loanId + "_" + data.advisorId]: { ...tempRatingData.data.resultObject } });
            setSubmittedRatingPopup(true);
        } catch (error) {
            console.log(error)
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: error.response.data.message, type: "error" }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        }
    }

    const sendEmailForRating = async () => {
        setLoading(true);
        const encodedURL = `appLoanID=${emailFields.loanId}&appNumber=${emailFields.applicationNumber}&createdDate=${emailFields.createdDate}&borrowAmount=${emailFields.borrowAmount}&businessCategory=${emailFields.businessCategory}&typeOfBusiness=${emailFields.typeOfBusiness}&applicationStatus=${emailFields.applicationStatus}&lenderId=${emailFields.lenderId}&advisorId=${emailFields.advisorId}`;

        const data = await axios.get('/api/User/SendApplicationRatingToAdvisor', {
            params: {
                SessionId: getSessionId(),
                Email: emailFields.to,
                Body: emailFields.body,
                AdvisorName: emailFields.advisorName,
                LenderName: info.firstName + " " + info.lastName,
                WebURL: `/submit-lenders-rating-by-advisor?data=${encryptText(encodedURL)}`
            }
        });
        setLoading(false);
        if (data.data.status === 200) {
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: data.data.message, type: "success" }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        } else {
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: data?.response?.data?.title, type: "error" }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        }
        setSendEmailPopup(false);
        setEmailFields({ to: '', body: '', loanId: '', applicationNumber: '', createdDate: '', borrowAmount: '', businessCategory: '', typeOfBusiness: '', applicationStatus: '', lenderId: '', advisorId: '', advisorName: '' });
    }

    const handleRatingApprovals = async (loanId, advisorId) => {
        try {
            if (appIndexData[loanId + "_" + advisorId]['factorsInfluence'] === "") {
                dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "Please fill all the required fields", type: "error" }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                document.getElementById('factorsInfluence_' + loanId + "_" + advisorId).focus();
                return;
            }
            setLoading(true);
            const data = {
                ...appIndexData[loanId + "_" + advisorId],
                approvedBy: user.user.id,
                createdBy: user.user.id,
                rating: "",
            }
            const response = await axios.post('/api/User/InsertApplicationRating', data, { params: { SessionId: getSessionId() } });
            setLoading(false);
            if (response.data.status === 200) {
                dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.data.message, type: "success" }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                getUsersLoanApplications();
            } else {
                dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.response.data.message, type: "error" }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
            }
        } catch (error) {
            setLoading(false);
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: error.response.data.message, type: "error" }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        }
    }

    return (
        <>
            {loading ? <Preloader /> : (
                <>
                    <div className="user-rating-content pt-3">
                        <div className='row'>
                            <div className='col-12'>
                                <form>
                                    <div className='d-flex gap-20 pb-2'>
                                        <div className='user-dtls-count-box'>
                                            <p>number of deals closed:<b>{totalDealClosed || 0}</b></p>
                                        </div>
                                        <div className='user-dtls-count-box'>
                                            <p>average rating:<b>{(parseFloat(averageRating)).toFixed(2) || 0.0}</b></p>
                                        </div>
                                    </div>

                                    <ShowRatingQuestions
                                        data={userLoanApplications}
                                        indexData={appIndexData}
                                        isDisabled={false}
                                        setEmailFields={setEmailFields}
                                        setSendEmailPopup={setSendEmailPopup}
                                        setAppIndexData={setAppIndexData}
                                        setHardDataOfOpenAI={setHardDataOfOpenAI}
                                        accordianClass={accordianClass}
                                        handleAccordion={handleAccordion}
                                        handleRatingApprovals={handleRatingApprovals}
                                        info={info}
                                        accordionIndex={accordionIndex}
                                        user={user}
                                    />

                                    <div className='row mt-4'>
                                        <div className='col-12'>
                                            <h3 className="lm-card-heading">Logs </h3>

                                            <DynamicTable
                                                data={userLenderLogs}
                                                dynamicTableDisplay={dynamicTableDisplay}
                                                columns={userLenderLogsColumns}
                                                changePageSize={setPageSize}
                                                pageSize={pageSize}
                                                total={totalData}
                                                setList={setUserLenderLogs}
                                                setPageIndex={setPageIndex}
                                                pageIndex={pageIndex}
                                            />

                                        </div>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>

                    {/*Send Email Popup / Modal */}
                    <Modal
                        className='sendEmailPopup'
                        size="md"
                        show={sendEmailPopupShow}
                        onHide={() => setSendEmailPopup(false)}
                        aria-labelledby="sendEmailPopupLabel"
                        backdrop="static"
                        keyboard={false}
                        centered
                    >
                        <div className="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="sendEmailPopupLabel">Send email</h5>
                                    <button type="button" className="close" onClick={sendEmailPopupClose}>
                                        <span aria-hidden="true"><img src={close_popup} alt="" /></span>
                                    </button>
                                </div>
                                <form>
                                    <Modal.Body>
                                        <div className="form-group">
                                            <label htmlFor="Recipient">Recipient</label>
                                            <input type="text" className="form-control" id="Recipient" value={emailFields.to} disabled />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="Notes">Message</label>
                                            <textarea className="form-control" id="Notes" placeholder="Enter Message" value={emailFields.body} onChange={(e) => setEmailFields({ ...emailFields, body: e.target.value })}></textarea>
                                        </div>
                                    </Modal.Body>
                                    <div className="modal-footer la-app-modal-footer">
                                        <button type="button" className="lm-button2" onClick={sendEmailPopupClose}>Cancel</button>
                                        <button type="button" className="lm-button1" onClick={sendEmailForRating}>Send</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Modal>
                    {/* Send Email Popup / Modal */}


                    {/*submitted Rating Popup Popup / Modal */}
                    <Modal
                        className="fade-down drawer-modal rating_popup requirements-drawer"
                        show={showSubmittedRatingPopup}
                        onHide={() => setSubmittedRatingPopup(false)}
                        aria-labelledby="showSubmittedRatingPopup"
                    >
                        <div className="drawer-head d-flex justify-content-between">
                            <div>
                                <h3>Advisor - {ratingDataToShow[0]?.businessName}</h3>
                                <span className="text-light">Performance</span>
                            </div>
                            <button type="button" className="close" onClick={showSubmittedRatingPopupClose}>
                                <span aria-hidden="true"><img src={close_popup} alt="" /></span>
                            </button>
                        </div>
                        <div className="drawer-body mt-3 pb-0">
                            <div className="user-rating-content">
                                <ShowRatingQuestions
                                    data={ratingDataToShow}
                                    indexData={ratingIndexData}
                                    isDisabled={true}
                                    setEmailFields={setEmailFields}
                                    setSendEmailPopup={setSendEmailPopup}
                                    setAppIndexData={setAppIndexData}
                                    setHardDataOfOpenAI={setHardDataOfOpenAI}
                                    accordianClass={accordianClass}
                                    handleAccordion={handleAccordion}
                                    handleRatingApprovals={handleRatingApprovals}
                                    info={info}
                                    accordionIndex={accordionIndex}
                                    user={user}
                                />
                            </div>
                        </div>
                    </Modal>
                    {/* submitted Rating Popup Popup / Modal */}
                </>
            )
            }
        </>
    );

}

export default UserLenderRating;