/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, createRef } from "react";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import Select from "react-select";
import Modal from "react-bootstrap/Modal";
import DatePicker from "react-datepicker";
import { SidePanel, ProfileHeader } from "@components";
import Preloader from "../../plugins/Preloader";
import { FieldGroup, FormBuilder, Validators, FieldControl, } from "react-reactive-form";
import FormField from "../../components/FormField/FormField";
import { GetBussinessNameListByLoanId, GetBusinessHistoryDetailsById, InsertUpdateBusinessHistory, } from "../../services/loanApplicationServices";
import { getSessionId } from "../../services/storageData";
import { decryptText, formatDate, validStringValidator } from "../../helpers/DynamicFunctions";
import Constants from "../../helpers/Constants";
import reducersAndActions from "../../_redux/slices/index";
import OpenAIComponent from "../../components/OpenAI/OpenAIComponent";
import CreatableSelect from "react-select/creatable";
import FormTextarea from "../../reusablecomponets/FormTextArea";
import { staticStatesList } from "../../helpers/staticContentData";

let loanId;
let BusinessNameOptionsTemp = [];
let stateLists = [];

function BusinessHistory({ backPreviousTab }) {
  const dispatch = useDispatch();
  const stateRef = createRef();
  const location = useLocation()
  const params = useParams();

  const [BusinessHistoryForm, setBusinessHistoryForm] = React.useState(
    FormBuilder.group({
      businessHistoryID: [0],
      yearOfBusinessEstablished: [""],
      acquireBusiness: ["", [Validators.required, validStringValidator]],
      natureOfbusiness: ["", [Validators.required, validStringValidator]],
      keyCustomers: ["", [Validators.required, validStringValidator]],
      targetMarket: ["", [Validators.required]],
      majorCompetitors: ["", [Validators.required, validStringValidator]],
      competitors: ["", [Validators.required, validStringValidator]],
      majorSuppliers: ["", [Validators.required, validStringValidator]],
      generalGeographicMarket: ["", [Validators.required]],
      loanBenefit: ["", [Validators.required, validStringValidator]],
      loanFacilitate: ["", [Validators.required, validStringValidator]],
      noSBAEmployee: [""],
      noFormerSBAEmployee: [""],
      noMemberOfCongress: [""],
      noGovernmentEmployee: [""],
      noMemberEmployeeOfSmallBusiness: [""],
      establishedDate: ["", [Validators.required]],
      establishedState: ["", [Validators.required]],
      businessName: [""],
      additionalInformation: [""],
      targetMarketError: [false],
      generalGeographicMarketError: [false],
    })
  );

  const [BusinessOptions, setBusinessOptions] = useState([]);
  const [selectedBusinessOption, setSelectedBusinessOption] = useState(null);
  const [businessError, setBusinessError] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(reducersAndActions.actions.sessionActions.setLoading(loading));
  }, [loading]);

  const [selectOptions, setSelectOptions] = useState({ targetMarket: [], generalGeographicMarket: [] });

  // Popup Function
  const [PreviousTabPopupShow, setPreviousTabPopup] = useState(false);
  const PreviousTabPopupClose = () => setPreviousTabPopup(false);

  // datepicker function

  const userdetails = location.state?.data
  useEffect(() => {
    document.title = "Business History - Loan Application";
    let oid = decryptText(params.loanId);
    setBusinessHistoryForm(BusinessHistoryForm);

    if (userdetails) {
      loanId = userdetails.data ? userdetails.data.loanId : userdetails.ApplicationNumber.slice(-4)
    } else {
      loanId = oid;
    }
    BusinessHistoryForm.reset();
    getMasterArrayList();
  }, []);


  const getMasterArrayList = async () => {
    try {
      setLoading(true);
      stateLists = staticStatesList

      // Fetch business names
      let queryObj = { LoanId: loanId, SessionId: getSessionId() };
      let data2 = await GetBussinessNameListByLoanId(queryObj);

      // Check if data2 is valid and has a successful status
      if (data2 && data2.status === 200) {
        let responseData2 = JSON.parse(data2.resultObject);

        const BusinessNameOptionsTemp = responseData2.map(item => ({
          label: item.BusinessName + (item.IsPrimary ? " (Primary)" : ""),
          value: item.Id,
          isPrimary: item.IsPrimary
        }));

        setBusinessOptions(BusinessNameOptionsTemp);
        const primaryOption = BusinessNameOptionsTemp.find(option => option.isPrimary);
        const defaultOption = primaryOption || BusinessNameOptionsTemp[0];
        setSelectedBusinessOption(defaultOption);

        if (defaultOption) {
          await GetBusinessHistoryDetail(defaultOption.value);
        }

      } else {
        console.error('Failed to fetch data or no data available');
      }
    } catch (err) {
      console.error('Error fetching data:', err);
    } finally {
      setLoading(false);
    }
  };

  const GetBusinessHistoryDetail = React.useCallback(async (businessId) => {
    try {
      setLoading(true);
      BusinessHistoryForm.reset();

      if (businessId && parseInt(businessId) > 0) {
        let queryObj = { LoanId: businessId, SessionId: getSessionId() };
        BusinessHistoryForm.patchValue({ loanId: businessId });

        const modelBusinessHistoryDetail = await GetBusinessHistoryDetailsById(queryObj);

        if (modelBusinessHistoryDetail.status === 200) {
          let finalData = modelBusinessHistoryDetail.resultObject;

          if (Object.keys(finalData).length > 0) {

            const tempTargetMarket = (finalData.targetMarket && finalData.targetMarket.split(',').map(item => ({ value: item, label: item }))) || [];
            const tempGeneralGeographicMarket = (finalData.generalGeographicMarket && finalData.generalGeographicMarket.split(',').map(item => ({ value: item, label: item }))) || []

            BusinessHistoryForm.patchValue({
              ...finalData,
              loanId: finalData.loanId || businessId,
              targetMarket: finalData.targetMarket || "",
              generalGeographicMarket: finalData.generalGeographicMarket || "",
              additionalInformation: finalData.additionalInformation || "",
            });

            setSelectOptions({ targetMarket: tempTargetMarket, generalGeographicMarket: tempGeneralGeographicMarket });

            if (finalData.establishedState !== "") {
              for (let elem = 0; elem < stateLists.length; elem++) {
                let elemvalue = stateLists[elem];
                if (elemvalue.value === finalData.establishedState) {
                  BusinessHistoryForm.patchValue({ establishedState: elemvalue, });
                }
              }
            }

            if (finalData.businessName !== "") {
              for (let elem = 0; elem < BusinessNameOptionsTemp.length; elem++) {
                let elemvalue = BusinessNameOptionsTemp[elem];
                if (elemvalue.value === finalData.businessName) {
                  BusinessHistoryForm.patchValue({ businessName: elemvalue, });
                }
              }
            }
          }
        }
        setLoading(false);
      }
    } catch (error) {
      console.error("Error in GetSelectedBusinessDetail:", error);
    } finally {
      setLoading(false);
    }
  }, [BusinessHistoryForm, setLoading]);;

  useEffect(() => {
    if (BusinessOptions.length > 0 && !selectedBusinessOption) {
      const primaryOption = BusinessOptions.find(option => option.isPrimary);
      const defaultOption = primaryOption || BusinessOptions[0];
      setSelectedBusinessOption(defaultOption);
      if (defaultOption) {
        GetBusinessHistoryDetail(defaultOption.value);
      }
    }
  }, [BusinessOptions, selectedBusinessOption, GetBusinessHistoryDetail]);

  const handleSubmit = () => {
    if (BusinessHistoryForm.status !== "INVALID") {
      setLoading(true);

      let finalObj = {
        ...BusinessHistoryForm.value,
        loanId: BusinessHistoryForm.value.businessName?.value || selectedBusinessOption?.value,
        SessionId: getSessionId(),
        establishedDate: formatDate(BusinessHistoryForm.value.establishedDate),
      };

      if (finalObj.businessHistoryID === null || finalObj.businessHistoryID === undefined) {
        finalObj.businessHistoryID = 0;
      }

      finalObj = {
        ...finalObj,
        modifiedBy: JSON.parse(localStorage.getItem("user"))?.id || 0,
        businessName: BusinessHistoryForm.value.businessName?.value || selectedBusinessOption?.value,
        establishedState: BusinessHistoryForm.value.establishedState.value,
        noSBAEmployee: BusinessHistoryForm.value.noSBAEmployee === "Yes" ? true : false,
        IsPrimaryLinked: BusinessHistoryForm.value.IsPrimaryLinked === "Yes" ? true : false,
        noMemberOfCongress: BusinessHistoryForm.value.noMemberOfCongress === "Yes" ? true : false,
        noFormerSBAEmployee: BusinessHistoryForm.value.noFormerSBAEmployee === "Yes" ? true : false,
        noGovernmentEmployee: BusinessHistoryForm.value.noGovernmentEmployee === "Yes" ? true : false,
        noMemberEmployeeOfSmallBusiness: BusinessHistoryForm.value.noMemberEmployeeOfSmallBusiness === "Yes" ? true : false,
      };

      InsertUpdateBusinessHistory(finalObj)
        .then((data) => {
          if (data.status === 200) {
            setLoading(false);
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: data.message, type: "success", }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
            BusinessHistoryForm.reset(BusinessHistoryForm.value, { keepValues: true });
          } else {
            setLoading(false);
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: data.message, type: "error", }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
          }
        })
        .catch((err) => {
          setLoading(false);
          dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: err.message, type: "error", }));
          setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        });
    } else {
      markAllAsTouched(BusinessHistoryForm);
      dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "Please fill all the required fields", type: "error", }));
      setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
      setLoading(false);
    }
  };

  // Marking all input fields as touched if the form is not valid
  const markAllAsTouched = (formGroup) => {
    if (stateRef.current) stateRef.current.focus();
    Object.keys(formGroup.controls).forEach((controlName) => {
      const control = formGroup.get(controlName);
      if (control && control.status === "INVALID") {
        if (controlName === 'generalGeographicMarket') BusinessHistoryForm.patchValue({ generalGeographicMarketError: true });
        if (controlName === 'targetMarket') BusinessHistoryForm.patchValue({ targetMarketError: true });
        control.active = true;
        control.touched = true;
        if (document.getElementById(controlName)) {
          document.getElementById(controlName).focus();
        }
      }
    });
  };


  const handleSelectMulti = (name, selectedOption) => {
    const MAX_OPTIONS = 10;
    const MAX_VALUE_LENGTH = 50;

    // Ensure selectedOption is always an array
    const currentSelectedValues = selectedOption || [];

    // Filter valid options based on criteria
    const filteredList = currentSelectedValues.filter(opt => opt.value.trim() !== "" && opt.value.length <= MAX_VALUE_LENGTH);

    // Check if the number of selected options exceeds the limit
    if (filteredList.length > MAX_OPTIONS) {
      dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "You can't select more than 10 options", type: "error", }));
      setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);

      // Restore previous options
      setSelectOptions(prevOptions => ({ ...prevOptions, [name]: prevOptions[name] || [], }));
      return;
    }

    // Update form and state with valid filtered options
    setSelectOptions(prevOptions => ({ ...prevOptions, [name]: filteredList }));

    // Update form control value
    BusinessHistoryForm.controls[name].setValue(filteredList.map(opt => opt.value).join(','));
    BusinessHistoryForm.controls[`${name}Error`].setValue(filteredList.length === 0);
  };

  const checkForBackButton = () => {
    if (BusinessHistoryForm.dirty) {
      setPreviousTabPopup(true);
    } else {
      backPreviousTab();
    }
  };

  const handleSelectChange = (event) => {
    if (event.value !== selectedBusinessOption.value) {
      setSelectedBusinessOption(event);
      setSelectOptions({ targetMarket: [], generalGeographicMarket: [] });
      if (event) {
        setBusinessError("");
        GetBusinessHistoryDetail(event.value);
      } else {
        setBusinessError("Please select a business");
      }
    }
  };

  return (
    <>
      {/* SidePanel */}
      <SidePanel />

      {/* ProfileHeader */}
      <ProfileHeader />

      {loading ? (
        <Preloader />
      ) : (
        <div>
          <div className="lm-form-box pb-5">
            <FieldGroup
              control={BusinessHistoryForm}
              render={({ get, invalid }) => (
                <form action="">
                  <div className="lm-card">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <FieldControl
                            name="businessName"
                            render={({ handler, touched, hasError, meta }) => (
                              <div className="form-group">
                                <label htmlFor="businessName">Select Business<span className="asterisk">*</span>
                                </label>
                                <Select
                                  className={`basic-single ${businessError ? 'is-invalid' : ''}`}
                                  classNamePrefix="select"
                                  id="businessName"
                                  name="businessName"
                                  options={BusinessOptions}
                                  onChange={handleSelectChange}
                                  value={selectedBusinessOption}
                                />
                                {businessError && <div className="invalid-feedback">{businessError}</div>}
                              </div>
                            )}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      {/* <!-- lm common divider to break the section  --> */}
                      <div className="lm-divider my-3 mx-3"></div>
                      {/* <!-- lm common divider to break the section  --> */}

                      <div className="col-12 label text-dark mb-2">
                        <b>OVERVIEW</b>
                      </div>

                      <div className="col-md-6 col-lg-3">
                        <div className="form-group">
                          <FieldControl
                            name="establishedState"
                            render={({ handler, touched, hasError, meta }) => (
                              <div className="form-group">
                                <label htmlFor="Established_state">
                                  Established state
                                  <span className="asterisk">*</span>
                                </label>
                                <Select
                                  className="basic-single"
                                  classNamePrefix="select"
                                  id="establishedState"
                                  name="establishedState"
                                  ref={stateRef}
                                  options={stateLists}
                                  {...handler()}
                                />

                                {/* Error messages below */}
                                <span className="error-message">
                                  {touched &&
                                    hasError("required") &&
                                    `Established state is required`}
                                </span>
                              </div>
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-3">
                        <div className="form-group input-date">
                          <FieldControl
                            name="establishedDate"
                            render={({ handler, touched, hasError, meta }) => (
                              <div className="form-group input-date">
                                <label htmlFor="establishedDate">
                                  Established Date<span className="asterisk">*</span>
                                </label>
                                <DatePicker
                                  showIcon
                                  toggleCalendarOnIconClick
                                  {...handler()}
                                  selected={
                                    BusinessHistoryForm.value.establishedDate
                                  }
                                  onChange={(date) => {
                                    BusinessHistoryForm.patchValue({
                                      establishedDate: date ? formatDate(date) : null,
                                    });
                                  }}
                                  onKeyDown={(e) => {
                                    e.preventDefault();
                                  }}
                                  isClearable={true}
                                  id="establishedDate"
                                  dateFormat="MM/d/yyyy"
                                  placeholderText="MM/DD/YYYY"
                                  yearDropdownItemNumber={100}
                                  scrollableYearDropdown
                                  showMonthDropdown
                                  showYearDropdown
                                />
                              </div>
                            )}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <FormField
                            name="natureOfbusiness"
                            label=" Nature of business"
                            placeholder="Enter Nature of business"
                            id="natureOfbusiness"
                            required={true}
                            errorMessage={"This field is required"}
                            maxlength={250}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="targetMarket">Target market<span className="asterisk">*</span></label>
                          <CreatableSelect
                            name="targetMarket"
                            id="targetMarket"
                            className="basic-single emailbox-input"
                            classNamePrefix="select"
                            value={selectOptions.targetMarket}
                            onChange={(e) => handleSelectMulti("targetMarket", e)}
                            placeholder={<div className="email-list-placeholder"><span className="placeholder-add-sign">+</span> Add</div>}
                            isMulti
                            isClearable
                          />
                          {BusinessHistoryForm.value.targetMarketError && selectOptions.targetMarket?.length === 0 && <div className='error-message mt-2 mb-3'>This field is required</div>}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label htmlFor="generalGeographicMarket">General geographic market<span className="asterisk">*</span></label>
                          <CreatableSelect
                            name="generalGeographicMarket"
                            id="generalGeographicMarket"
                            className="basic-single emailbox-input"
                            classNamePrefix="select"
                            value={selectOptions.generalGeographicMarket}
                            onChange={(e) => handleSelectMulti("generalGeographicMarket", e)}
                            placeholder={<div className="email-list-placeholder"><span className="placeholder-add-sign">+</span> Add</div>}
                            isMulti
                            isClearable
                          />
                          {BusinessHistoryForm.value.generalGeographicMarketError && selectOptions.generalGeographicMarket?.length === 0 && <div className='error-message mt-2 mb-3'>This field is required</div>}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group AI_btn_field">
                          <FormTextarea
                            name="competitors"
                            label="Current competitive advantage"
                            placeholder="Enter Current competitive advantage"
                            id="competitors"
                            required={true}
                            errorMessage={"This field is required"}
                            maxlength={250}
                          />
                          <OpenAIComponent
                            fieldName="competitors"
                            formGroup={BusinessHistoryForm}
                            type="textarea"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group AI_btn_field">
                          <FormTextarea
                            name="acquireBusiness"
                            label=" When and how did current owners acquire the business?"
                            placeholder="Enter When and how did current owners acquire the business?"
                            id="acquireBusiness"
                            required={true}
                            errorMessage={"This field is required"}
                            maxlength={250}
                          />
                          <OpenAIComponent
                            fieldName="acquireBusiness"
                            formGroup={BusinessHistoryForm}
                            type="textarea"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group AI_btn_field">
                          <FormTextarea
                            name="keyCustomers"
                            label="Key customers"
                            placeholder="Enter Key customers"
                            id="keyCustomers"
                            required={true}
                            errorMessage={"This field is required"}
                            maxlength={250}
                          />
                          <OpenAIComponent
                            fieldName="keyCustomers"
                            formGroup={BusinessHistoryForm}
                            type="textarea"
                          />
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div className="form-group AI_btn_field">
                          <FormTextarea
                            name="majorCompetitors"
                            label="Major competitors"
                            placeholder="Enter Major competitors"
                            id="majorCompetitors"
                            required={true}
                            errorMessage={"This field is required"}
                            maxlength={250}
                          />
                          <OpenAIComponent
                            fieldName="majorCompetitors"
                            formGroup={BusinessHistoryForm}
                            type="textarea"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group AI_btn_field">
                          <FormTextarea
                            name="majorSuppliers"
                            label="Major suppliers"
                            placeholder="Enter Major suppliers"
                            id="majorSuppliers"
                            required={true}
                            errorMessage={"This field is required"}
                            maxlength={250}
                          />
                          <OpenAIComponent
                            fieldName="majorSuppliers"
                            formGroup={BusinessHistoryForm}
                            type="textarea"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group AI_btn_field">
                          <FormTextarea
                            name="loanBenefit"
                            label="How will the proposed loan benefit the business?"
                            placeholder="Enter How will the proposed loan benefit the business?"
                            id="loanBenefit"
                            required={true}
                            errorMessage={"This field is required"}
                            maxlength={250}
                          />
                          <OpenAIComponent
                            fieldName="loanBenefit"
                            formGroup={BusinessHistoryForm}
                            type="textarea"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group AI_btn_field">
                          <FormTextarea
                            name="loanFacilitate"
                            label="How will the proposed loan facilitate new employment opportunities?"
                            placeholder="Enter How will the proposed loan facilitate new employment opportunities?"
                            id="loanFacilitate"
                            required={true}
                            errorMessage={"This field is required"}
                            maxlength={250}
                          />
                          <OpenAIComponent
                            fieldName="loanFacilitate"
                            formGroup={BusinessHistoryForm}
                            type="textarea"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group AI_btn_field">
                          <FormTextarea
                            name="additionalInformation"
                            label="Additional information"
                            placeholder="Enter Additional information"
                            id="additionalInformation"
                            maxlength={250}
                          />
                          <OpenAIComponent
                            fieldName="additionalInformation"
                            formGroup={BusinessHistoryForm}
                            type="textarea"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="sticky-btn-footer d-flex justify-content-end">
                    <button type="button" className="lm-button2" onClick={checkForBackButton}>Back</button>
                    <button type="button" className="lm-button1 ml-3" onClick={handleSubmit}>Save</button>
                  </div>
                </form>
              )}
            />
          </div>

          {/* <!--back to Previous Tab Popup / Modal --> */}
          <Modal
            className="mark-comp-popup-cust-size"
            show={PreviousTabPopupShow}
            onHide={() => setPreviousTabPopup(false)}
            aria-labelledby="PreviousTabPopupLabel"
            backdrop="static"
            keyboard={false}
            centered
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
              <div className="modal-content">
                <div className="modal-body signout-modal">
                  <h3 className="pt-4">
                    Going back will lead to the loss of unsaved data
                  </h3>
                </div>
                <div className="modal-footer la-app-modal-footer">
                  <button
                    type="button"
                    className="lm-button2"
                    onClick={PreviousTabPopupClose}
                  >
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="lm-button1"
                    onClick={backPreviousTab}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </Modal>
          {/* <!--back to Previous Tab Popup / Modal --> */}
        </div>
      )}
    </>
  );
}

export default BusinessHistory;
