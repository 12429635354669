/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import axiosInstance from '../../../helpers/axiosConfig'; // Adjust the path to where your Axios instance is defined
import { useDispatch, useSelector } from 'react-redux';
import reducersAndActions from '../../../_redux/slices';
import Constants from '../../../helpers/Constants';
import Preloader from '../../../plugins/Preloader';

const AccountSettings = ({ activeTab }) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.editProfile.data);
    const userData = useSelector((state) => state.auth.user);

    const [preloader, setPreloader] = useState(false)
    const [profileData, setProfileData] = useState({});

    const [tfaChecked, setTfaChecked] = useState(false);
    const [autoExpirePasswordChecked, setAutoExpirePasswordChecked] = useState(false);
    const [limitDocumentAccessChecked, setLimitDocumentAccessChecked] = useState(false);


    useEffect(() => {
        setPreloader(true)
        axiosInstance.get(`/api/User/MyProfile?userId=${userData?.id}&SessionId=${localStorage?.getItem('sessionId')}`)
            .then((res) => {
                setPreloader(false)
                setProfileData(res.data.resultObject)
                setTfaChecked(res.data.resultObject.isTFAChecked)
                setAutoExpirePasswordChecked(res.data.resultObject.ispasswordExpired)
                setLimitDocumentAccessChecked(res.data.resultObject.isProfilePrivacy)
            })
            .catch(error => {
                setPreloader(false)
                console.log(error)
            })
    }, [])

    const handleToggleSwitch = useCallback((toggleName, isChecked) => {
        setPreloader(true);

        const apiUrl = '/api/User/SaveTFAStatus';
        const status = isChecked ? 1 : 0; // Use isChecked to set the status directly
        const storedSessionId = localStorage.getItem('sessionId');

        // Construct the query string
        const queryParams = `?userId=${userData.id}&status=${status}&SessionId=${storedSessionId}&Type=${toggleName === '0' ? "TFS" : "AutoExpire"}`;

        axiosInstance.post(`${apiUrl}${queryParams}`)
            .then(response => {
                setPreloader(false);
                if (response.data?.status === 200) {
                    if (toggleName === '0') {
                        setTfaChecked(isChecked);
                    } else if (toggleName === '1') {
                        setAutoExpirePasswordChecked(isChecked);
                    }
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.data.message }));
                    setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                } else {
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.data.message, type: "error" }));
                    setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                }
            })
            .catch(error => {
                setPreloader(false);
                console.error('Error:', error);
            });

    }, [dispatch]);

    const hanldeLimitAccess = async () => {
        try {
            const apiUrl = '/api/User/MyProfile';
            const formDataToSend = new FormData();

            formDataToSend.append('Id', userData.id);
            formDataToSend.append('SessionId', localStorage.getItem('sessionId'));

            formDataToSend.append('Email', user.email);
            formDataToSend.append('FirstName', user.firstName);
            formDataToSend.append('LastName', user.lastName);
            formDataToSend.append('CompanyEntity', user.companyEntity);
            formDataToSend.append('OfficeNumber', user.officeNumber);
            formDataToSend.append('MobileNumber', user.mobileNumber);

            formDataToSend.append('IsProfilePrivacy', !limitDocumentAccessChecked);
            formDataToSend.append('IsTFAChecked', tfaChecked);
            formDataToSend.append('IspasswordExpired', autoExpirePasswordChecked);

            formDataToSend.append('DocuSignIs', profileData.docuSignIs);
            formDataToSend.append('AgdemailDoc', profileData.agdemailDoc);
            formDataToSend.append('IsTransactionAssigned', profileData.isTransactionAssigned);


            if (user.imagePath === "" || user.imagePath.includes('null')) {
                formDataToSend.append('postedFile', '');
            } else {
                const byteString = atob(user.imagePath.split(',')[1]);
                const mimeString = user.imagePath.split(',')[0].split(':')[1].split(';')[0];
                const ab = new ArrayBuffer(byteString.length);
                const ia = new Uint8Array(ab);
                for (let i = 0; i < byteString.length; i++) {
                    ia[i] = byteString.charCodeAt(i);
                }
                const blob = new Blob([ab], { type: mimeString });
                formDataToSend.append('postedFile', blob, 'avatar.png');
            }

            const queryParams = `?userId=${user.id}&SessionId=${localStorage.getItem('sessionId')}`;
            const response = await axiosInstance.post(`${apiUrl}${queryParams}`, formDataToSend, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });
            setPreloader(false)
            if (response.data.status === 200) {
                setLimitDocumentAccessChecked(!limitDocumentAccessChecked)
                dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "Document limit status update successfully" }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
            } else {
                setPreloader(false)
                dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.data.message, type: "error" }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
            }
        } catch (error) {
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: error.message, type: "error" }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        }
    }
    return (
        <div className={`tab-pane fade ${activeTab === "Account_Settings" ? "show active" : ""}`} id="Account_Settings">
            {
                preloader ? <Preloader /> : <div className="lm-card">
                    <h3 className="lm-card-heading">Account Settings</h3>
                    <div className="notification-setting d-flex justify-content-between">
                        <h6>Two-factor authentication</h6>
                        <div className="lm-toggle-switch">
                            <input
                                type="checkbox"
                                id="0"
                                checked={tfaChecked}
                                onChange={(e) => handleToggleSwitch('0', e.target.checked)} />
                            <label htmlFor="0">Toggle</label>
                        </div>
                    </div>
                    <div className="notification-setting d-flex justify-content-between">
                        <h6>Auto expire password</h6>
                        <div className="lm-toggle-switch">
                            <input type="checkbox" id="1" checked={autoExpirePasswordChecked} onChange={(e) => handleToggleSwitch('1', e.target.checked)} />
                            <label htmlFor="1">Toggle</label>
                        </div>
                    </div>
                    {
                        (userData.roleName === 'Borrower' || userData.roleName === "BusinessPartnerOwner") && (
                            <div className='notification-setting '>
                                <div className="d-flex justify-content-between">
                                    <h6>Limit document access</h6>
                                    <div className="lm-toggle-switch">
                                        <input type="checkbox" id="3" checked={limitDocumentAccessChecked} onChange={hanldeLimitAccess} />
                                        <label htmlFor="3">Toggle</label>
                                    </div>
                                </div>
                                <span style={{ fontSize: "12px", color: "grey" }}>Documents are restricted to lenders, advisors and admin</span>
                            </div>
                        )

                    }
                </div>
            }
        </div>
    );
}

export default AccountSettings;
