/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Link } from "react-router-dom";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';
import Modal from 'react-bootstrap/Modal'
import Dropdown from 'react-bootstrap/Dropdown'
import { SidePanel, ProfileHeader } from "@components";
import Preloader from '../../plugins/Preloader';
import Info_tooltip from '../../assets/icons/Info-tooltip.svg';
import Review_icon from '../../assets/icons/Review_icon.svg';
import download_icon from '../../assets/icons/download_icon.svg';
import send_email_icon from '../../assets/icons/send_email_icon.svg';
import version_history_icon from '../../assets/icons/version_history_icon.svg';
import set_esign_icon from '../../assets/icons/set_esign_icon.svg';
import mark_com_icon from '../../assets/icons/mark_com_icon.svg';
import close_popup from '../../assets/icons/close_popup.svg';
import Report_Icon from '../../assets/icons/report-icon.svg';
import pdf_file_icon from '../../assets/img/pdf-file-icon.svg';
import Cancel_sending_for_signature from '../../assets/icons/Cancel_sending_for_signature.svg';
import Send_to_signature from '../../assets/icons/Send_to_signature.svg';
import document_icon from '../../assets/icons/document-icon.svg';
import file_icon from '../../assets/icons/file-icon.svg';
import note_icon from '../../assets/icons/note-icon-dark.svg';
import Remind_to_sign from '../../assets/icons/Remind_to_sign.svg';

import add_note from "../../assets/icons/make-note-icon.svg";
import delete_icon from "../../assets/icons/lm-trash-icon.svg";
import dummy_avtar from "../../assets/img/dummy_avtar.png";
import dummy_avtar_removebg from "../../assets/img/dummy_avtar-removebg.png";

import pass_eye from '../../assets/img/pass_eye.svg';
import { getLocalStorageData, getSessionId } from '../../services/storageData';
import { CancelHelloSignSignature, GetAGDDocumentDetailsById, GetAllDocumentList, GetDocumentList, GetDocumentReminderDetails, GetDocumentsHistory, GetDownloadDocumentHistoryFile, GetDownloadUnCompletedDocumentList, GetEntityListofDocumentsByLoanId, InsertUpdateAGDDocument, InsertUpdateDocumentReminder, MarkAsCompletedDocuments, MultipleDocumentSentSignature, RemindHelloSignSignature, SaveDocumentCoordinates, SendDocumentToEmail, SendUnCompletedDocumentList, UpdateDocumentVerify_Active, UploadDocument, ViewDocumentInBrowser } from '../../services/documentsServices';
import DynamicTable from '../../plugins/DynamicTable';
import { DocumentsListHeader } from '../../helpers/DropdownsAndMappers';
import { debounce } from 'lodash';
import Constants from '../../helpers/Constants';
import reducersAndActions from '../../_redux/slices';
import { useDispatch, useSelector } from 'react-redux';
import convertToLocalTime from '../user/commonFunctions/User';
import { pdfjs, Document, Page } from 'react-pdf';
import '../../assets/AnnotationLayer.css';
import '../../assets/TextLayer.css';
import * as pdfjsLib from "pdfjs-dist";
import { GetApplicationNotes, InsertUpdateApplicationNotes } from '../../services/loanApplicationServices';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import moment from 'moment';
import DatePicker from "react-datepicker";
import { staticTimeZoneMapper } from '../../helpers/staticContentData';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;


const options = {
    cMapUrl: '/cmaps/',
    standardFontDataUrl: '/standard_fonts/',
};

const staticWeekdayList = [
    { label: "Select...", value: "" },
    { label: "Sunday", value: "Sunday" },
    { label: "Monday", value: "Monday" },
    { label: "Tuesday", value: "Tuesday" },
    { label: "Wednesday", value: "Wednesday" },
    { label: "Thursday", value: "Thursday" },
    { label: "Friday", value: "Friday" },
    { label: "Saturday", value: "Saturday" },
]


function Documents(props) {
    const dispatch = useDispatch();

    const user = useSelector((state) => state.auth);
    const userRole = user.user.roleName;
    const id = user.user.id;

    useEffect(() => {
        document.title = "Documents - Loan Application";
        getEntityList();
        setSelectedOutstandingEmails([]);
    }, []);

    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(reducersAndActions.actions.sessionActions.setLoading(loading));
    }, [loading]);

    const [entityList, setEntityList] = useState([]);
    const [selectedEntity, setSelectedEntity] = useState(null);

    // Table check box functionallity
    const [allSelectedRows, setAllSelectedRows] = useState(false);
    const [selectedRows, setSelectedRows] = useState({});
    const [toggleRowSelectionStatuses, setToggleRowSelectionStatuses] = useState({})

    const [documents, setDocumentList] = useState([]);
    const [allDocumentsList, setAllDocumentsList] = useState([]);

    const [totalData, setTotalData] = useState(0);
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(1000);
    const [searchText, setSearchText] = useState("");
    const [status, setStatus] = useState("");

    const [noteComment, setNotesComment] = useState("");
    const [notesList, setNotesList] = useState([]);
    const [selectedNoteToRemove, setSelectedNoteToRemove] = useState(null);
    const [showConfirmNoteRemove, setShowConfirmNoteRemove] = useState(false);
    const [selectedDocumentForNotes, setSelectedDocumentForNotes] = useState(null);


    // Fetch inquiries whenever page size or page index changes
    const debounceDoucmentList = useCallback(debounce(() => {
        setLoading(true);
        setSelectedRows([])
        setToggleRowSelectionStatuses({})
        setSelectedDocumentsForEsign({})
        getDoucmentsListFun()
    }, 1000), [selectedEntity, pageIndex, pageSize, status, searchText]);

    useEffect(() => {
        debounceDoucmentList();
        return () => {
            debounceDoucmentList.cancel();
        };
    }, [debounceDoucmentList]);

    const getEntityList = async () => {
        setLoading(true);
        setPageIndex(1);
        const params = {
            SessionId: getSessionId(),
            LoanId: props.loanid
        };
        const entityList = await GetEntityListofDocumentsByLoanId(params);
        if (entityList.status === 200) {
            const temp = JSON.parse(entityList.resultObject).map((entity) => {
                return { value: entity.Id, label: entity.EntityName }
            });
            setEntityList(temp);
            setSelectedEntity(temp[0]['value']);
            setSelectedEntityForAddDocument(temp[0]['value']);
        }
    }


    useEffect(() => {
        getAllDocumentsList();
    }, [selectedEntity]);

    const getAllDocumentsList = async () => {
        const documentList = await GetAllDocumentList();
        if (documentList.status === 200) {
            try {
                const temp = JSON.parse(documentList.resultObject) || [];
                const finalList = temp.map((doc) => ({
                    value: doc.DocumentId,
                    label: doc.DocumentName,
                }))
                setAllDocumentsList(finalList);
            } catch (error) {
                setAllDocumentsList([]);
                console.error(error)
            }
        }
    }


    const getDoucmentsListFun = async () => {
        const params = {
            SessionId: getSessionId(),
            LoanId: props.loanid,
            EntityId: selectedEntity,
            PageIndex: pageIndex,
            PageSize: pageSize,
            Status: status,
            SearchText: searchText,
            IsBorrower: userRole === "Borrower" ? 1 : 0
        };
        const documentList = await GetDocumentList(params);
        if (documentList.status === 200) {
            try {
                setDocumentList(JSON.parse(documentList.resultObject));
                let total = documentList.message.split(" ");
                setTotalData(Number(total[1]));
                setAllSelectedRows(false);
                console.log(JSON.parse(documentList.resultObject))
            } catch (error) {
                setDocumentList([]);
                setTotalData(0);
                setAllSelectedRows(false);
                console.error(error)
            }
        }
        setLoading(false);
    }



    const toggleRowSelection = (id, data) => {
        setSelectedRows((prev) => {
            prev[id] ? delete prev[id] : prev[id] = data;
            return { ...prev };
        });


        // Toggle status using the ID as the key
        setToggleRowSelectionStatuses(temp => {
            temp[id] ? delete temp[id] : temp[id] = data.Status;
            return { ...temp };
        });
    };

    // Popup function
    const [newDocumentPopupShow, setNewDocumentPopup] = useState(false);
    const newDocumentPopupClose = () => setNewDocumentPopup(false);

    const [markCompletePopupShow, setMarkCompletePopup] = useState(false);
    const markCompletePopupClose = () => setMarkCompletePopup(false);

    const [cancelESignaturePopupShow, setcancelESignaturePopup] = useState(false);
    const cancelESignaturePopupClose = () => setcancelESignaturePopup(false);

    const [generateReportPopupShow, setGenerateReportPopup] = useState(false);
    const generateReportPopupClose = () => setGenerateReportPopup(false);

    const [versionHistoryPopupShow, setVersionHistoryPopup] = useState(false);
    const versionHistoryPopupClose = () => setVersionHistoryPopup(false);

    const [uploadDocumentPopupShow, setUploadDocumentPopup] = useState(false);
    const uploadDocumentPopupClose = () => setUploadDocumentPopup(false);

    const [sendEmailPopupShow, setSendEmailPopup] = useState(false);
    const sendEmailPopupClose = () => setSendEmailPopup(false);

    const [ReminderPopupShow, setReminderPopup] = useState(false);
    const ReminderPopupClose = () => setReminderPopup(false);

    const [resetReminderShow, setResetReminder] = useState(false);
    const resetReminderClose = () => setResetReminder(false);

    const [ESignaturePopupShow, setESignaturePopup] = useState(false);
    const ESignaturePopupClose = () => setESignaturePopup(false);

    const [deleteLastSignPositionShow, setDeleteLastSignPosition] = useState(false);
    const deleteLastSignPositionClose = () => setDeleteLastSignPosition(false);

    const [deleteAllSignPositionShow, setDeleteAllSignPosition] = useState(false);
    const deleteAllSignPositionClose = () => setDeleteAllSignPosition(false);

    const [sendForSignaturePopUp, setSendForSignaturePopUp] = useState(false);
    const sendForSignatureClose = () => setSendForSignaturePopUp(false);

    // Note Popup function
    const [addNoteDrawerShow, setNoteDrawer] = useState(false);
    const noteDrawerClose = () => setNoteDrawer(false);

    // Requirements tabs
    const [activeTab, setActiveTab] = useState("");

    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
        setSecondPersionSign(false)
        setSelectedTabOption(1)
    };

    const handlePreviousTab = () => {
        const documentIds = Object.keys(selectedDocumentForEsign);
        const currentIndex = documentIds.indexOf(activeTab);
        if (currentIndex > 0) {
            setActiveTab(documentIds[currentIndex - 1]);
            setSecondPersionSign(false)
            setSelectedTabOption(1)
        }
    };

    const handleNextTab = () => {
        const documentIds = Object.keys(selectedDocumentForEsign);
        const currentIndex = documentIds.indexOf(activeTab);
        if (currentIndex < documentIds.length - 1) {
            setActiveTab(documentIds[currentIndex + 1]);
            setSecondPersionSign(false)
            setSelectedTabOption(1)
        }
    };


    // Tooltip messages for different statuses
    const statusTooltipMessages = {
        "Sent for signature": "The document is out for borrower's signature",
        "Completed": "The document is ready for loan review",
        "Uploaded": "The document is uploaded and signature is required. Please set the signature block",
        "To sign": "The document is ready for sending for signature",
        "To upload": "File needs to be uploaded"
    };

    // select Dropdown options
    const statusOptions = [
        { value: '', label: 'All statuses' },
        { value: 'Completed', label: 'Completed' },
        { value: 'Uploaded', label: 'Uploaded' },
        { value: 'Sent for signature', label: 'Sent for signature' },
        { value: 'To sign', label: 'To sign' },
        { value: 'To upload', label: 'To upload' },
    ]

    const animatedComponents = makeAnimated();

    const showNotificationToaster = (message, type) => {
        dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: message, type: type }));
        setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
    }

    // ------------------------------------------------
    const [selectedDocumentForCompelete, setSelectedDocumentForCompelete] = useState({});
    const handleConfirmMarkAsCompelete = async () => {
        const params = {
            id: parseInt(selectedDocumentForCompelete.Id),
            loanId: parseInt(props.loanid),
            entityId: parseInt(selectedEntity),
            documentId: parseInt(selectedDocumentForCompelete.DocumentId),
            UserId: parseInt(user.user.id),
            fileName: selectedDocumentForCompelete.FileName
        }
        const changeStatus = await MarkAsCompletedDocuments(params);
        if (changeStatus.status === 200) {
            showNotificationToaster("Document marked as completed", "success");
            markCompletePopupClose();
            getDoucmentsListFun()
        } else {
            showNotificationToaster("An error occurred while updating the document status", "error");
        }
    }

    // ------------------------------------------------
    const [selectedDocumentForCancelSendToSing, setSelectedDocumentForCancelSendToSing] = useState([]);
    const handleConfirmCancelSendToSing = async () => {
        try {
            setLoading(true);
            const params = selectedDocumentForCancelSendToSing.map((element) => {
                return {
                    LoanId: Number(props.loanid),
                    OwnerId: Number(element.OwnerID),
                    EntityId: Number(selectedEntity),
                    DocumentId: Number(element.DocumentId),
                    DocumentName: element.Name,
                    DocumentType: element.DocumentType,
                    UserId: Number(user.user.id),
                }
            });

            const changeStatus = await CancelHelloSignSignature(params);
            if (changeStatus.status === 200) {
                showNotificationToaster("Document signature requests are cancelled", "success");
            } else {
                showNotificationToaster(`An error occurred while canceling the request`, "error");
            }

            setLoading(false);
            // Reset the state and close popup
            setSelectedDocumentForCancelSendToSing([]);
            setcancelESignaturePopup(false);
            getDoucmentsListFun(); // Refresh document list
            setSelectedRows({});
            setToggleRowSelectionStatuses({});
        } catch (error) {
            console.log(error);
            showNotificationToaster(`An error occurred while canceling the request`, "error");
        }
    };


    // ------------------------------------------------
    const handleConfirmReminder = async (data) => {
        try {
            setLoading(true);
            const params = data.map((element) => {
                return {
                    LoanId: Number(props.loanid),
                    OwnerId: Number(element.OwnerID),
                    EntityId: Number(selectedEntity),
                    DocumentId: Number(element.DocumentId),
                    DocumentName: element.Name,
                    DocumentType: element.DocumentType,
                    userId: Number(user.user.id),
                }
            });

            const changeStatus = await RemindHelloSignSignature(params);
            if (changeStatus.status === 200) {
                showNotificationToaster("Reminder sent successfully", "success");
            } else {
                showNotificationToaster(`An error occurred while reminding the request`, "error");
            }

            setLoading(false);
            // Reset the state and close popup
            getDoucmentsListFun(); // Refresh document list
            setSelectedRows({});
            setToggleRowSelectionStatuses({});
        } catch (error) {
            console.log(error);
            showNotificationToaster(`An error occurred while reminding the request`, "error");
        }
    };

    // ------------------------------------------------
    const [selectedEntityForAddDocument, setSelectedEntityForAddDocument] = useState();
    const [selectedRequiredDoucment, setSelectedRequiredDoucment] = useState([]);
    const [tempSelectedRequiredDoucment, setTempSelectedRequiredDoucment] = useState([]);

    const handleDocumentModalClose = () => {
        newDocumentPopupClose()
        setSelectedRequiredDoucment([...tempSelectedRequiredDoucment])
    }

    const getAGDDocumentsList = async () => {
        const params = {
            SessionId: getSessionId(),
            LoanId: props.loanid,
            EntityId: selectedEntityForAddDocument,
        }
        const documentList = await GetAGDDocumentDetailsById(params);
        if (documentList.status === 200) {
            try {
                // Replace escaped quotes with regular quotes
                const correctedString = documentList.resultObject.replace(/\\"/g, '"');
                const tempData = JSON.parse(correctedString) || [];
                const finalData = tempData.map((doc) => ({
                    value: doc.DocumentId,
                    label: doc.DocumentName,
                }));
                setTempSelectedRequiredDoucment(finalData);
                setSelectedRequiredDoucment(finalData);
            } catch (error) {
                setSelectedRequiredDoucment([]);
                console.error(error)
            }
        }
    }

    useEffect(() => {
        setSelectedRequiredDoucment([]);
        getAGDDocumentsList();
    }, [selectedEntityForAddDocument])

    const handleAddNewDocument = async () => {
        setLoading(true)
        const params = {
            LoanId: parseInt(props.loanid),
            EntityId: parseInt(selectedEntityForAddDocument),
            EntityName: entityList.find(entity => entity.value === selectedEntityForAddDocument).label
        }
        const finalData = selectedRequiredDoucment.map(doc => {
            return {
                documentName: doc.label,
                documentId: parseInt(doc.value),
            }
        })
        const updateDocuments = await InsertUpdateAGDDocument(finalData, params);
        if (updateDocuments.status === 200) {
            showNotificationToaster("Documents added successfully", "success");
            setTempSelectedRequiredDoucment([...selectedRequiredDoucment])
            setEmailRecipients([]);
            setEmailNotes("");
            newDocumentPopupClose();
            getDoucmentsListFun()
        } else {
            showNotificationToaster("An error occurred while updating the document status", "error");
        }
        setLoading(false);

    }



    // ------------------------------------------------
    const [sendingEmailDocument, setSendingEmailDocument] = useState([]);
    const [emailRecipients, setEmailRecipients] = useState([]);
    const [emailNotes, setEmailNotes] = useState("");

    const sendDocumentToEmail = async () => {
        if (!emailRecipients.length) {
            showNotificationToaster("Please select at least one recipient", "error");
            return;
        }
        setLoading(true);
        const params = { LoanId: props.loanid, MailIds: emailRecipients.map(recipient => recipient.value).toString() }
        const jsonBody = sendingEmailDocument.map(doc => {
            return {
                documentFileName: doc.DocumentType === "AGD" || (doc.DocumentType === "BGD" && doc.Status === "Completed") ? doc.FileName : doc.Name,
                emailid: emailRecipients.map(recipient => recipient.value).toString(),
                emailTextMessage: emailNotes,
                documentName: doc.DocumentType === "BGD" ? doc.TD_Name : doc.Name,
                ownerId: doc.OwnerID,
                loanId: props.loanid,
                docType: doc.DocType,
                documentId: doc.Id,
                entityId: selectedEntity,
                isSigned: doc.Status === "Completed",
                entityName: doc.Name,
                uuDocumentPath: "",
                email_guid: "",
                documentType: doc.DocumentType
            }
        });
        // Call API to send email
        const sendEmail = await SendDocumentToEmail(jsonBody, params);
        if (sendEmail.status === 200) {
            showNotificationToaster("Document sent to email address", "success");
            setEmailRecipients([]);
            setEmailNotes("");
        } else {
            showNotificationToaster("An error occurred while sending email", "error");
        }
        setLoading(false);
        setSendEmailPopup(false)
    }

    // ------------------------------------------------
    const [file, setFile] = useState(null);
    const [fileURL, setFileURL] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);


    const handleFileChange = async (e) => {
        const selectedFile = e.target.files[0];
        const fileSizeLimit = 20 * 1024 * 1024; // 20 MB in bytes

        if (!selectedFile) return showNotificationToaster("Please select a file", "error");
        if (selectedFile.size > fileSizeLimit) return showNotificationToaster("File size should be less than 20MB", "error");
        if (selectedFile.type !== "application/pdf") return showNotificationToaster("Only PDF files are allowed", "error");


        const fileReader = new FileReader();

        fileReader.onload = async () => {
            try {
                setFile(selectedFile);
                setFileURL(URL.createObjectURL(selectedFile));
                uploadFile(selectedFile);
            } catch (error) {
                showNotificationToaster("Unable to process PDF. Please try again.", "error");
                console.error("PDF processing error:", error);
            }
        };

        fileReader.readAsArrayBuffer(selectedFile);
    };


    const uploadFile = (file) => {
        // Mock upload process
        setUploadProgress(0);
        const uploadInterval = setInterval(() => {
            setUploadProgress((prevProgress) => {
                if (prevProgress >= 100) {
                    clearInterval(uploadInterval);
                    return 100;
                }
                return prevProgress + 10;
            });
        }, 100); // Update progress every 100ms
    };

    const removeDocument = () => {
        setFile(null);
        setUploadProgress(0);
    };

    const handleViewDocument = () => {
        if (fileURL) {
            window.open(fileURL, '_blank');
        }
    };

    // Initialize state with documents' initial values
    const [isActive, setIsActive] = useState([]);
    const [isVerified, setIsVerified] = useState([]);

    // Update state when documents change
    useEffect(() => {
        setIsActive(documents.map(doc => doc.IsActive === "1"));
        setIsVerified(documents.map(doc => doc.Verified === "1"));
    }, [documents]);

    // Handle checkbox state changes for 'isActive'
    const handleActiveChange = async (index) => {
        setLoading(true);
        const params = {
            DocumentType: documents[index].DocumentType,
            Action: "Active",
            LoanId: props.loanid,
            DocumentId: documents[index].Id,
            OwnerId: documents[index].OwnerID,
            Status: !isActive[index],
            SessionId: getSessionId()
        };

        // Call API to update document status
        const updateStatus = await UpdateDocumentVerify_Active(params);
        if (updateStatus.status === 200) {
            showNotificationToaster("Document status updated successfully", "success");
            getDoucmentsListFun()
            setSelectedRows([])
            setAllSelectedRows(false)
            setToggleRowSelectionStatuses({})
            setSelectedDocumentsForEsign({})
        } else {
            showNotificationToaster(updateStatus?.response?.data?.message || "An error occurred while updating the document status", "error");
        }
        setLoading(false);
    };

    // ------------------------------------------------
    const handleVerifyChange = async (index) => {
        if (documents[index].Status !== "Completed") {
            showNotificationToaster("You can't verify until status is completed", "error");
            return false;
        }
        setLoading(true);
        const params = {
            DocumentType: documents[index].DocumentType,
            Action: "Verify",
            LoanId: props.loanid,
            DocumentId: documents[index].Id,
            OwnerId: documents[index].OwnerID,
            Status: !isVerified[index],
            SessionId: getSessionId()
        };

        // Call API to update document status
        const updateStatus = await UpdateDocumentVerify_Active(params);
        if (updateStatus.status === 200) {
            showNotificationToaster("Document status updated successfully", "success");
            setIsVerified(prevStates => {
                const newStates = [...prevStates];
                newStates[index] = !newStates[index];
                return newStates;
            });
            setDocumentList(documents.map((doc, i) => {
                if (i === index) return { ...doc, Verified: isVerified[index] ? "0" : "1" };
                return doc;
            }));
        } else {
            showNotificationToaster(updateStatus?.response?.data?.message || "An error occurred while updating the document status", "error");
        }
        setLoading(false);
    };


    const [borrowerNeedsSign, setBorrowerNeedsSign] = useState(false);
    const [uploadDocumentIndex, setUploadDocumentIndex] = useState(null);
    const [versionHistoryList, setVersionHistoryList] = useState([]);
    const [versionHistoryFileName, setVersionHistoryFileName] = useState("");

    const [selectedTabOption, setSelectedTabOption] = useState(1);
    const [secondPersionSign, setSecondPersionSign] = useState(false);
    const [secondPersonEmail, setSecondPersonEmail] = useState("");

    const [selectedDocumentForEsign, setSelectedDocumentsForEsign] = useState({})
    const [selectedTabOptionIcon, setSelectedTabOptionIcon] = useState({});
    const [totalSignedDocuments, setTotalSignedDocuments] = useState(0);

    const [eSignBlocksList, setESignBlocksList] = useState({});
    const [dateBlocksList, setESignDateBlocksList] = useState({});
    const [textBlocksList, setESignTextBlocksList] = useState({});
    const [secondPersonList, setSecondPersonList] = useState({});

    useEffect(() => {
        if (Object.keys(selectedDocumentForEsign).length) {
            setESignBlocksList({});
            setESignDateBlocksList({});
            setESignTextBlocksList({});
            setSecondPersonList({})
            setSecondPersonEmail("")
            setSecondPersionSign(false)
            setActiveTab(Object.values(selectedDocumentForEsign)[0]?.Id)
        }
    }, [selectedDocumentForEsign])

    useEffect(() => {
        if (selectedTabOption !== 4) {
            setSecondPersionSign(false)
        }
    }, [selectedTabOption])

    const [pagesPerDocument, setPagesPerDocument] = useState({});
    const [pageDimensions, setPageDimensions] = useState({});

    const refs = useRef({});

    // Callback to set refs dynamically
    const setRef = useCallback((key, ref) => {
        refs.current[key] = ref;
    }, []);

    async function getPageDimensions(url) {
        const loadingTask = pdfjsLib.getDocument(url);
        const pdf = await loadingTask.promise;
        const tempDimensions = {};

        for (let i = 1; i <= pdf.numPages; i++) {
            const page = await pdf.getPage(i);
            const viewport = page.getViewport({ scale: 1 });

            tempDimensions[i] = {
                width: viewport.width,
                height: viewport.height,
                orientation: viewport.width > viewport.height ? 'landscape' : 'portrait'
            };
        }

        return tempDimensions;
    }

    function onDocumentLoadSuccess({ numPages: nextNumPages }, document) {
        getPageDimensions(document.url).then((response) => {
            setPageDimensions((prev) => ({ ...prev, [document.Id]: response }));
        });

        setPagesPerDocument((prev) => ({ ...prev, [document.Id]: nextNumPages }));
        setTimeout(() => {
            window.URL.revokeObjectURL(document.url);
        }, 1000);
    }

    function handleMouseClick(event, docId) {
        if (!refs['current'][docId]) return;

        const rect = refs['current'][docId].getBoundingClientRect();
        const x = event.clientX - rect.left;

        // Iterate through each page to determine which page was clicked
        const pages = Array.from(refs['current'][docId].querySelectorAll('.react-pdf__Page'));
        let clickedPageNumber = null;
        let relativeY = null;
        let totalOffsetY = 0;
        let pageHeight = 0;

        for (let page of pages) {
            const pageRect = page.getBoundingClientRect();
            const marginBetweenPages = 40; // Define the margin between pages
            pageHeight = pageRect.height;

            if (
                event.clientX >= pageRect.left &&
                event.clientX <= pageRect.right &&
                event.clientY >= pageRect.top &&
                event.clientY <= pageRect.bottom
            ) {
                clickedPageNumber = parseInt(page.getAttribute('data-page-number'), 10);
                relativeY = event.clientY - pageRect.top; // Y relative to the current page
                break;
            }

            // Add the height of each page to the total offset for next page
            totalOffsetY += pageHeight + marginBetweenPages;
        }

        if (clickedPageNumber === null) {
            return showNotificationToaster("Please click inside the document", "error");
        }

        const pageWidth = pageDimensions[docId][clickedPageNumber]['width']

        // Check if click is within the boundaries, leaving 50px margin on all sides
        if (x < 50 || x > (pageWidth - 50) || relativeY < 50 || relativeY > (pageHeight - 50)) {
            return showNotificationToaster("Please click inside the document area, within the margins", "error");
        }

        // If the click is valid, proceed to add the block
        const block = { x: x - 30, y: relativeY + totalOffsetY + 20, page: clickedPageNumber, pageHeight };

        // Handle adding block to the respective list
        if (selectedTabOption === 1) {
            const prevArray = eSignBlocksList[docId] || [];
            setESignBlocksList((prevBlocks) => ({ ...prevBlocks, [docId]: [...prevArray, block] }));
        } else if (selectedTabOption === 2) {
            const prevArray = dateBlocksList[docId] || [];
            setESignDateBlocksList((prevBlocks) => ({ ...prevBlocks, [docId]: [...prevArray, block] }));
        } else if (selectedTabOption === 3) {
            const prevArray = textBlocksList[docId] || [];
            setESignTextBlocksList((prevBlocks) => ({ ...prevBlocks, [docId]: [...prevArray, block] }));
        } else if (selectedTabOption === 4) {
            const prevArray = secondPersonList[docId] || [];
            setSecondPersonList((prevBlocks) => ({ ...prevBlocks, [docId]: [...prevArray, block] }));
        }
    }


    const showRectangleBlocks = () => {
        const temp = selectedTabOption === 1 ? eSignBlocksList : selectedTabOption === 2 ? dateBlocksList : selectedTabOption === 3 ? textBlocksList : secondPersonList
        return (
            <div>
                {temp.hasOwnProperty(activeTab) && temp[activeTab].map((rect, index) => (
                    <div
                        key={index}
                        style={{
                            position: 'absolute',
                            top: rect.y + 5 - 20,
                            left: rect.x + 30,
                            width: '100px',
                            height: '30px',
                            border: '2px solid #4057E3',
                            pointerEvents: 'none',
                        }}

                    />
                ))}
            </div>
        );
    }


    const handleRemoveLastSign = () => {
        let checkLength = false
        if (selectedTabOption === 1) {
            checkLength = eSignBlocksList[activeTab].length === 1 ? true : false
            setESignBlocksList((prev) => ({ ...prev, [activeTab]: eSignBlocksList[activeTab].slice(0, -1) }));
        } else if (selectedTabOption === 2) {
            checkLength = dateBlocksList[activeTab].length === 1 ? true : false
            setESignDateBlocksList((prev) => ({ ...prev, [activeTab]: dateBlocksList[activeTab].slice(0, -1) }));
        } else if (selectedTabOption === 3) {
            checkLength = textBlocksList[activeTab].length === 1 ? true : false
            setESignTextBlocksList((prev) => ({ ...prev, [activeTab]: textBlocksList[activeTab].slice(0, -1) }));
        } else if (selectedTabOption === 4) {
            checkLength = secondPersonList[activeTab].length === 1 ? true : false
            setSecondPersonList((prev) => ({ ...prev, [activeTab]: secondPersonList[activeTab].slice(0, -1) }));
        }

        if (checkLength) {
            setSelectedTabOptionIcon((pre) => {
                const currentTabData = { ...selectedTabOptionIcon[activeTab], [selectedTabOption]: "date-block-icon" };
                return { ...pre, [activeTab]: currentTabData }
            })
        }
        deleteLastSignPositionClose()
    }

    const handleRemoveAllSign = () => {
        if (selectedTabOption === 1) {
            setESignBlocksList([]);
        } else if (selectedTabOption === 2) {
            setESignDateBlocksList([]);
        } else if (selectedTabOption === 3) {
            setESignTextBlocksList([]);
        } else if (selectedTabOption === 4) {
            setSecondPersonList([])
        }
        setSelectedTabOptionIcon((pre) => {
            const currentTabData = { ...selectedTabOptionIcon[activeTab], [selectedTabOption]: "date-block-icon" };
            return { ...pre, [activeTab]: currentTabData }
        })
        deleteAllSignPositionClose()
    }

    const handleSetSignPositions = () => {
        const getTabData = selectedTabOption === 1 ? eSignBlocksList : selectedTabOption === 2 ? dateBlocksList : textBlocksList;
        if (!getTabData[activeTab]?.length && selectedTabOption !== 4) {
            showNotificationToaster("Please set at least one position", "error");
            return;
        }
        setSelectedTabOptionIcon((pre) => {
            const currentTabData = { ...selectedTabOptionIcon[activeTab], [selectedTabOption]: "mark-complete-icon" };
            return { ...pre, [activeTab]: currentTabData }
        })
    }

    useEffect(() => {
        if (Object.keys(selectedTabOptionIcon).length) {
            let totalCount = 0
            const check = Object.values(selectedTabOptionIcon).forEach(item => {
                if (item['1'] === "mark-complete-icon") {
                    totalCount++
                }
            })
            if (check) {
                setTotalSignedDocuments(totalCount)
            }
        }
    }, [selectedTabOptionIcon])

    const calculateTotalHeightsOfPages = (pageData, pageNo) => {
        return Object.values(pageData).slice(0, pageNo).map(item => item.height).reduce((sum, value) => sum + value, 0);
    }

    const handleReCalculateYAxis = (yaxis, pageNumber, pageHeight, docId) => {
        const pageOrientations = pageDimensions[docId]
        const marginBetweenPages = (pageNumber - 1) * 40

        const withoutOffset = pageNumber > 1 ? pageHeight - Math.abs((yaxis - (marginBetweenPages + calculateTotalHeightsOfPages(pageOrientations, pageNumber)))) : yaxis

        if (pageOrientations[pageNumber]['orientation'] === 'portrait') {
            return pageHeight > 800 && withoutOffset > 500 ? withoutOffset - 30 : withoutOffset
        } else {
            return withoutOffset + 10
        }
    }

    const handleReCalculateXAxis = (xaxis, pageNumber, docId) => {
        const landscapeAdjustment = [
            { threshold: 600, adjustment: 70 },
            { threshold: 400, adjustment: 50 },
            { threshold: 200, adjustment: 30 }
        ];

        const layout = pageDimensions[docId][pageNumber]['orientation'];
        const pageWidth = pageDimensions[docId][pageNumber]['width'];

        // Check if the layout is landscape and the page width exceeds 800
        if (layout === 'landscape' && pageWidth > 800) {
            for (const { threshold, adjustment } of landscapeAdjustment) {
                if (xaxis > threshold) {
                    return xaxis - adjustment;
                }
            }
        }

        return xaxis;
    };

    const handleSubmitSetEsignature = async () => {
        setLoading(true);

        const formatPositions = (blocksList, blockId) => {
            const pages = blocksList[blockId]?.map(item => item.page).join(',') || "";
            const xPositions = blocksList[blockId]?.map(item => handleReCalculateXAxis(item.x, item.page, blockId)).join(',') || "";
            const yPositions = blocksList[blockId]?.map(item => handleReCalculateYAxis(item.y, item.page, item.pageHeight, blockId)).join(',') || "";

            // If either xPositions or yPositions are blank, signPositions will be blank
            const signPositions = xPositions && yPositions ? `${xPositions}#${yPositions}` : "";
            return { pageNo: pages, signPos: signPositions };
        };


        const finalData = Object.values(selectedDocumentForEsign).map(block => {
            const { FileName, Id } = block;
            const eSignPositions = formatPositions(eSignBlocksList, Id);
            const datePositions = formatPositions(dateBlocksList, Id);
            const textPositions = formatPositions(textBlocksList, Id);
            const signPositions = formatPositions(secondPersonList, Id);

            return {
                fileName: FileName,
                loanId: props.loanid,
                entityId: selectedEntity,
                documentId: block.DocumentId,
                entityName: entityList.find(entity => entity.value === selectedEntity).label,
                isSP: isValidEmailOption(secondPersonEmail),
                emailSP: secondPersonEmail,
                signPositions: {
                    pageNo: eSignPositions.pageNo,
                    signPos: eSignPositions.signPos,
                    spPageNo: signPositions.pageNo,
                    spSignPos: signPositions.signPos
                },
                datePositions: {
                    pageNo: datePositions.pageNo,
                    datePos: datePositions.signPos,
                },
                textboxPositions: {
                    pageNo: textPositions.pageNo,
                    textPos: textPositions.signPos,
                }
            };
        });

        const setCordinates = await SaveDocumentCoordinates(finalData);
        if (setCordinates.status === 200) {
            showNotificationToaster("Coordinates has been save successfully", "success");
            getDoucmentsListFun()
        } else {
            showNotificationToaster(setCordinates?.response?.data?.message || "An error occurred while set position", "error");
        }
        setLoading(false);
        setESignaturePopup(false);
        setSelectedDocumentsForEsign({})
        setESignBlocksList({});
        setESignDateBlocksList({});
        setESignTextBlocksList({});
        setSecondPersonList({})
        setSecondPersonEmail("")
        setSelectedRows({})
        setToggleRowSelectionStatuses({})
    }


    // -------------------------------------------------------------------------------------
    const [selectedDocumentForSendToSignature, setSelectedDocumentForSendToSignature] = useState([]);
    const [emailRecipientsForSign, setEmailRecipientsForSign] = useState("");
    const [emailNotesForSign, setEmailNotesForSign] = useState("");

    const handleSendToSignature = async () => {
        if (!isValidEmailOption(emailRecipientsForSign)) {
            showNotificationToaster("Please enter a valid recipient email", "error");
            return;
        }

        setLoading(true)
        const finalData = selectedDocumentForSendToSignature.map(doc => {
            return {
                documentFileName: doc.DocumentType === "AGD" ? doc.FileName : doc.Name,
                emailid: emailRecipientsForSign,
                emailTextMessage: emailNotesForSign,
                documentName: doc.DocumentType === "BGD" ? doc.TD_Name : doc.Name,
                ownerId: parseInt(doc.OwnerID),
                loanId: parseInt(props.loanid),
                docType: doc.DocumentType === "AGD" ? "AGD" : doc.DocType,
                documentId: parseInt(doc.DocumentId),
                entityId: parseInt(selectedEntity),
                isSigned: true,
                entityName: entityList.find(entity => entity.value === selectedEntity).label,
                uuDocumentpath: "",
                email_guid: "",
                documentType: doc.DocumentType
            }
        })

        await MultipleDocumentSentSignature(finalData, user.user.id);
        showNotificationToaster("Document sent for e-signature", "success");

        setLoading(false);

        getDoucmentsListFun()
        setEmailNotesForSign("");
        setEmailRecipientsForSign("");
        setSendForSignaturePopUp(false);
        setSelectedDocumentForSendToSignature([]);
        setSelectedRows({})
        setToggleRowSelectionStatuses({})
    }

    // ---------------------------------------------------------------------
    const handleUploadDocument = async (event) => {
        setLoading(true)
        event.preventDefault();

        let formData = new FormData();
        formData.append("File", file);

        const params = {
            Id: documents[uploadDocumentIndex]?.Id,
            LoanId: props.loanid,
            OnwerId: documents[uploadDocumentIndex].OwnerID,
            EntityId: selectedEntity,
            DocumentId: documents[uploadDocumentIndex].DocumentId,
            isSigned: borrowerNeedsSign, // send true in case of AGD only if selected && false in case of boororwer update
            Verified: documents[uploadDocumentIndex]['DocumentType'] === "AGD" ? false : true, // send false in case of AGD, true in case of BGD
            Name: documents[uploadDocumentIndex].Name,
            UUDocumentPath: "",
            DocumentType: documents[uploadDocumentIndex].DocumentType,
            UserId: user.user.id,
            SessionId: getSessionId()
        }

        // Add any further form submission logic here (e.g., API call)
        const uploadDoc = await UploadDocument(formData, params);
        if (uploadDoc.status === 200) {
            showNotificationToaster("Document uploaded successfully", "success");
            setFile(null);
            setFileURL(null);
            setUploadProgress(0);
            uploadDocumentPopupClose();
            getDoucmentsListFun()
        } else {
            showNotificationToaster(uploadDoc?.response?.data?.message || "An error occurred while updating the document", "error");
        }
        setLoading(false);
    };



    const handleDownloadDocument = async (rows) => {
        setLoading(true)
        const downloadDoc = await GetDownloadDocumentHistoryFile(rows)
        if (downloadDoc) {
            showNotificationToaster("Document downloaded successfully", "success");
        } else {
            showNotificationToaster("An error occurred while downloading the document", "error");
        }
        setLoading(false);

    }

    const handleViewVerisonHistory = async (row) => {
        setLoading(true)
        setVersionHistoryPopup(true);
        setVersionHistoryList([]);
        setVersionHistoryFileName(row.Name);
        const params = {
            SessionId: getSessionId(),
            LoanId: selectedEntity,
            DocumentId: row.Id,
            DocumentType: row.DocumentType,
            OwnerId: row.OwnerID
        }
        const data = await GetDocumentsHistory(params)
        if (data.status === 200) {
            if (data.resultObject) {
                console.log(JSON.parse(data.resultObject))
                setVersionHistoryList(JSON.parse(data.resultObject));
            } else {
                setVersionHistoryList([])
            }
        }
        setLoading(false)
    }

    const handleVersionReview = async (file) => {
        try {
            setLoading(true);
            for (const element of Object.values(file)) {
                let params = {};
                let isType = false;
                if (element.DocumentType === "BGD" && element.Status === "To sign" && (element.FileName === "" || element.DownloadFileName === "")) {
                    params = {
                        LoanId: element.LoanId,
                        fileName: element.TD_Name || element.FileName1,
                        DwnFileName: element.Name || element.DownloadFileName,
                        ownerId: element.OwnerID || element.OwnerId,
                        UserId: user.user.id,
                        Doctype: element.DocType
                    };
                    isType = true;
                } else {
                    params = {
                        filename: element.DownloadFileName || element.FileName,
                        isSigned: element.Status === "Completed" || (element.DocumentType === "BGD" && element.Status === "To sign" && element.DownloadFileName !== ""),
                        Status: element.Status,
                        DocumentType: element.DocumentType
                    };
                }

                const isInsideWebView = window.ReactNativeWebView !== undefined;
                if (isInsideWebView) {
                    window.ReactNativeWebView.postMessage(JSON.stringify({ action: "openPdf", params, isType, sessionId: getSessionId(), authToken: getLocalStorageData("token") }));
                } else {
                    const data = await ViewDocumentInBrowser(params, isType);
                    if (data.status === 200) {
                        const blob = new Blob([data.data], { type: 'application/pdf' });
                        const url = window.URL.createObjectURL(blob);
                        const link = document.createElement('a');
                        link.href = url;
                        link.target = "_blank";
                        link.rel = 'noopener noreferrer';
                        link.click();

                        // Revoke the object URL after 10 minutes
                        setTimeout(() => window.URL.revokeObjectURL(url), 10 * 60 * 1000);
                    } else {
                        showNotificationToaster(`${element?.Name || element?.FileName} document not available`, "error");
                    }
                }
            }
        } catch (error) {
            console.error("Error handling version review:", error);
        } finally {
            setLoading(false);  // Ensure loading is set to false after all operations
        }
    };

    // Notes secion start here ----------------------

    const getNewApplicationNotes = async (documentId) => {
        try {
            const params = {
                loanId: parseInt(props.loanid),
                ownerId: selectedEntity,
                documentId: parseInt(documentId),
                createdBy: 0,
                screenName: "Document"
            }
            const getNotesList = await GetApplicationNotes(params)
            setLoading(false)
            if (getNotesList.status !== 200) {
                dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: getNotesList.message, type: "error", }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
            } else {
                setNotesList(getNotesList.resultObject);
            }
        } catch (err) {
            console.log(err);
            setLoading(false);
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: err.message, type: "error", }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        }
    }

    const openNotesModel = async (documentId) => {
        setLoading(true)
        setNotesList([])
        setNotesComment("")
        setSelectedDocumentForNotes(documentId);
        getNewApplicationNotes(documentId)
        setNoteDrawer(true)
    }


    const handleAddNewNote = async () => {
        if (noteComment && noteComment.trim() !== "") {
            setLoading(true);
            let newNote = {
                "id": 0,
                "loanId": parseInt(props.loanid),
                "ownerId": parseInt(selectedEntity),
                "documentId": parseInt(selectedDocumentForNotes),
                "isDeleted": 0,
                "createdBy": parseInt(user.user.id),
                "notes": noteComment,
                "screenName": "Document"
            };

            const addNewNote = await InsertUpdateApplicationNotes(newNote);
            if (addNewNote.status === 200) {
                dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: addNewNote.message, type: "success", }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                setNotesComment("");
                setLoading(false);
                setNoteDrawer(false);
            } else {
                setLoading(false);
                dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: addNewNote.message, type: "error", }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
            }
        } else {
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "Notes comment is required", type: "error", }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        }
    }

    const handleDeleteNote = async () => {
        try {
            setLoading(true);
            const deletedNoteDetails = notesList.find((note) => note.id === selectedNoteToRemove);
            let newNote = { ...deletedNoteDetails, isDeleted: 1 };
            const deleteNote = await InsertUpdateApplicationNotes(newNote);
            if (deleteNote.status === 200) {
                dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "Note has been deleted successfully", type: "success", }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                setLoading(false);
                setNoteDrawer(false);
            } else {
                setLoading(false);
                dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: deleteNote.message, type: "error", }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
            }
            setShowConfirmNoteRemove(false);
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    // Notes secion end here ----------------------

    const handleESignPositions = async (document) => {
        setLoading(true);
        const finalDataPromises = Object.keys(document).map(async (element) => {
            const params = { SessionId: getSessionId(), filename: document[element].FileName, isSigned: false };
            const data = await ViewDocumentInBrowser(params);

            if (data.status === 200) {
                const blob = new Blob([data.data], { type: 'application/pdf' });
                const url = window.URL.createObjectURL(blob);
                return { url: url, ...document[element] };
            }
            return null;
        });

        // Wait for all promises to resolve
        const finalData = await Promise.all(finalDataPromises);

        let filteredData = {};
        let tabOptionIcon = {};
        finalData.filter(item => item !== null).forEach(item => {
            filteredData[item.Id] = item;
            tabOptionIcon[item.Id] = { 1: "date-block-icon", 2: "date-block-icon", 3: "date-block-icon", 4: "date-block-icon" };
        });

        setLoading(false);
        setSelectedTabOption(1);
        setESignaturePopup(true);
        setSelectedDocumentsForEsign(filteredData);
        setSelectedTabOptionIcon(tabOptionIcon);
    }

    const isValidEmailOption = (email) => {
        const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        return emailRegex.test(email);
    }

    // Checklist options starts here ------------------------------------------------------------

    const [checklistReminderData, setChecklistReminderData] = useState({ id: 0, frequency: "", startDate: "", startDay: "", startTime: "", endType: "EndBy", endDate: "", endAfter: "" });
    const [selectedOutstandingEmails, setSelectedOutstandingEmails] = useState([]);
    const [showOutstandingEmailPopup, setShowOutstandingEmailPopup] = useState(false);

    const getChecklistReminderData = async () => {
        setLoading(true);
        const params = { LoanId: props.loanid, SessionId: getSessionId() };
        const getReminderData = await GetDocumentReminderDetails(params);
        if (getReminderData.status === 200) {
            const data = getReminderData.resultObject
            if (data) {
                setChecklistReminderData({
                    ...data,
                    startTime: data.frequency === "Weekly" && data.startTime ? moment(data.startTime, "h:mm A").format("HH:mm") : "",
                    startDay: data.frequency === "Weekly" && data.startDay ? data.startDay : "",
                    startDate: data.startDate ? moment(data.startDate).format("MM-DD-YYYY") : "",
                    endDate: data.endType === "EndBy" && data.endDate ? moment(data.endDate).format("MM-DD-YYYY") : "",
                    endAfter: data.endType === "endAfter" && data.endAfter ? data.endAfter : ""
                });
            } else {
                setChecklistReminderData({ id: 0, frequency: "", startDate: "", startDay: "", startTime: "", endType: "EndBy", endDate: "", endAfter: "" });
            }
        } else {
            showNotificationToaster(getReminderData?.response?.data?.message || "An error occurred while fetching the reminder data", "error");
        }
        setLoading(false);
        setReminderPopup(true);
    }

    const downloadOutstandingDocumentList = async () => {
        setLoading(true);
        const params = { LoanId: props.loanid, SessionId: getSessionId() };

        const isInsideWebView = window.ReactNativeWebView !== undefined;
        if (isInsideWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ action: "downloadWord", params, sessionId: getSessionId(), authToken: getLocalStorageData("token"), endPoint: "/api/Document/GetDownloadUnCompletedDocumentList" }));
        } else {
            const downloadDoc = await GetDownloadUnCompletedDocumentList(params);
            if (downloadDoc.status === 200) {
                showNotificationToaster("Document downloaded successfully", "success");
            } else {
                showNotificationToaster("An error occurred while downloading the document", "error");
            }
        }
        setLoading(false);
    }

    const emailOutstandingDocumentList = async () => {
        if (selectedOutstandingEmails.length === 0) {
            showNotificationToaster("Please select at least one recipient", "error");
            return;
        }
        setLoading(true);
        const params = { LoanId: props.loanid, MailId: selectedOutstandingEmails.map(element => element.value) };
        const emailDoc = await SendUnCompletedDocumentList(params);
        if (emailDoc.status === 200) {
            showNotificationToaster("Document emailed successfully", "success");
        } else {
            showNotificationToaster("An error occurred while emailing the document", "error");
        }
        setLoading(false)
        setSelectedOutstandingEmails([]);
        setShowOutstandingEmailPopup(false);
    }

    const getUpcomdateDateFromDay = (day) => {
        const currentDate = moment();
        const currentDay = currentDate.format('dddd');
        const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const currentDayIndex = days.indexOf(currentDay);
        const selectedDayIndex = days.indexOf(day);
        const diff = selectedDayIndex - currentDayIndex;
        return currentDate.add(diff, 'days').format("YYYY-MM-DDTHH:mm:ss");
    }

    const handleConfirmChecklistReminder = async () => {
        if (!checklistReminderData.frequency || (checklistReminderData.frequency === "Weekly" && (!checklistReminderData.startDay || !checklistReminderData.startTime))) {
            showNotificationToaster("Please fill all the required fields", "error");
            return;
        }
        setLoading(true);
        const params = {
            loanId: parseInt(props.loanid),
            createdBy: parseInt(user.user.id),
            isDeleted: 0,
            ...checklistReminderData,
            startDate: checklistReminderData.startDay ? getUpcomdateDateFromDay(checklistReminderData.startDay) : moment().format("YYYY-MM-DDTHH:mm:ss"),
            endAfter: checklistReminderData.endAfter === "" ? 0 : checklistReminderData.endAfter,
            startDay: checklistReminderData.startDay ? checklistReminderData.startDay : moment().format('dddd'),
            startTime: checklistReminderData.startTime ? checklistReminderData.startTime : moment().format("HH:mm")
        };
        const sendReminder = await InsertUpdateDocumentReminder(params, staticTimeZoneMapper[moment().format("Z")]);
        if (sendReminder.status === 200) {
            showNotificationToaster("Reminder added successfully", "success");
        } else {
            showNotificationToaster(sendReminder?.response?.data?.message || "An error occurred while sending the reminder", "error");
        }
        setLoading(false);
        setReminderPopup(false);
        setChecklistReminderData({ id: 0, frequency: "", startDate: "", startDay: "", startTime: "", endType: "EndBy", endDate: "", endAfter: "" });
    }

    const handleComfirmResetReminder = async () => {
        const params = {
            loanId: parseInt(props.loanid),
            createdBy: parseInt(user.user.id),
            ...checklistReminderData,
            startDate: checklistReminderData.startDay ? getUpcomdateDateFromDay(checklistReminderData.startDay) : moment().format("YYYY-MM-DDTHH:mm:ss"),
            startTime: checklistReminderData.startTime ? checklistReminderData.startTime : moment().format("HH:mm"),
            endAfter: checklistReminderData.endAfter === "" ? 0 : checklistReminderData.endAfter,
            isDeleted: 1,
        };
        const sendReminder = await InsertUpdateDocumentReminder(params, staticTimeZoneMapper[moment().format("Z")]);
        if (sendReminder.status === 200) {
            showNotificationToaster("Reminder removed successfully", "success");
            setChecklistReminderData({ id: 0, frequency: "", startDate: "", startDay: "", startTime: "", endType: "EndBy", endDate: "", endAfter: "" });
        } else {
            showNotificationToaster(sendReminder?.response?.data?.message || "An error occurred while sending the reminder", "error");
        }
        resetReminderClose();
        setReminderPopup(false);
    }

    // Checklist options ends here --------------------------------------------------------------

    const showActionButtons = (document, status, role, index) => {
        const canUpload = status === 'To upload' || (status === 'To sign' && document.DocumentType === 'BGD');
        const canDropdown = status !== 'To upload'
        const isAgdDocument = document.DocumentType === 'AGD';

        return (
            <div className="action-col d-flex justify-content-end gap-10 place-items-center">
                {/* Upload/Reupload Link */}
                <Link
                    onClick={() => {
                        setFile(null);
                        setFileURL(null);
                        setUploadProgress(0);
                        setBorrowerNeedsSign(false);
                        setUploadDocumentPopup(true);
                        setUploadDocumentIndex(index);
                    }}
                    className="grid-link"
                >
                    {canUpload ? 'Upload' : 'Reupload'}
                </Link>

                {/* Add note button */}
                <a onClick={() => openNotesModel(document.Id)}>
                    <img className="lm-grid-edit-icon" src={add_note} alt="add-note" />
                </a>

                {/* Dropdown for additional actions */}
                <Dropdown className='mb-3'>
                    <Dropdown.Toggle className={`doc_option_dots ${!canDropdown ? 'mr-3' : ''}`}>
                        {canDropdown && '...'}
                    </Dropdown.Toggle>
                    {canDropdown &&
                        <Dropdown.Menu className="doc_app_options">
                            {/* Review Option */}
                            <div onClick={() => handleVersionReview({ [document.Id]: document })}>
                                <img className="mr-2" src={Review_icon} alt="Review" />
                                Review
                            </div>

                            {/* Download Option */}
                            <div onClick={() => handleDownloadDocument({ [document.Id]: document })}>
                                <img className="mr-2" src={download_icon} alt="Download" />
                                Download
                            </div>

                            {/* Send Email Option */}
                            <div onClick={() => {
                                setSendingEmailDocument([document]);
                                setSendEmailPopup(true);
                            }}>
                                <img className="mr-2" src={send_email_icon} alt="Send email" />
                                Send email
                            </div>

                            {/* Version History Option */}
                            <div onClick={() => handleViewVerisonHistory(document)}>
                                <img className="mr-2" src={version_history_icon} alt="Version history" />
                                Version history
                            </div>

                            {/* Actions for 'Uploaded' Status and AGD Document */}
                            {status === 'Uploaded' && isAgdDocument && role !== "Borrower" && (
                                <>
                                    <div onClick={() => handleESignPositions({ [document.Id]: document })}>
                                        <img className="mr-2" src={set_esign_icon} alt=" Set e-signature" />
                                        Set e-signature
                                    </div>
                                    <div onClick={() => {
                                        setSelectedDocumentForCompelete(document);
                                        setMarkCompletePopup(true);
                                    }}>
                                        <img className="mr-2" src={mark_com_icon} alt="Mark as completed" />
                                        Mark as completed
                                    </div>
                                </>
                            )}

                            {/* Actions for 'To Sign' Status */}
                            {status === 'To sign' && role !== "Borrower" && (
                                <>
                                    <div onClick={() => {
                                        setSendForSignaturePopUp(true);
                                        setSelectedDocumentForSendToSignature([document]);
                                    }}>
                                        <img className="mr-2" src={Send_to_signature} alt="Send for signature" />
                                        Send for signature
                                    </div>
                                    {isAgdDocument && (
                                        <div onClick={() => {
                                            setSelectedDocumentForCompelete(document);
                                            setMarkCompletePopup(true);
                                        }}>
                                            <img className="mr-2" src={mark_com_icon} alt="Mark as completed" />
                                            Mark as completed
                                        </div>
                                    )}
                                </>
                            )}

                            {/* Actions for 'Sent for Signature' Status */}
                            {status === 'Sent for signature' && role !== "Borrower" && (
                                <>
                                    <div onClick={() => {
                                        handleConfirmReminder([document])
                                    }}>
                                        <img className="mr-2" src={Remind_to_sign} alt="Remind to sign" />
                                        Reminder
                                    </div>
                                    <div onClick={() => {
                                        setcancelESignaturePopup(true)
                                        setSelectedDocumentForCancelSendToSing([document])
                                    }
                                    }>
                                        <img className="mr-2" src={Cancel_sending_for_signature} alt="Cancel sending for signature" />
                                        Cancel sending for signature
                                    </div>
                                </>
                            )
                            }
                        </Dropdown.Menu>
                    }
                </Dropdown>
            </div>
        )
    };

    const selectAllRowsHeader = () => {
        return (
            <input
                type="checkbox"
                className="form-check-input ml-1"
                checked={allSelectedRows}
                onChange={(e) => {
                    setAllSelectedRows(e.target.checked);
                    const alreadySelectedRows = Object.keys(selectedRows);
                    if (e.target.checked) {
                        documents.forEach(element => {
                            if (!alreadySelectedRows.includes(element.Id) && element.IsActive === "1" && element.Status !== "To upload") {
                                toggleRowSelection(element.Id, element);
                            }
                        })
                    } else {
                        documents.forEach(element => {
                            if (alreadySelectedRows.includes(element.Id) && element.IsActive === "1" && element.Status !== "To upload") {
                                toggleRowSelection(element.Id, element);
                            }
                        })
                    }
                }}
            />
        )
    }

    useEffect(() => {
        const activeDocuments = documents.filter(doc => doc.IsActive === "1" && doc.Status !== "To upload");
        setAllSelectedRows(activeDocuments.length === Object.values(selectedRows).filter(row => row.IsActive === "1" && row.Status !== "To upload").length);
    }, [selectedRows])

    const dynamicTableDisplay = (document, index) => {
        return (
            <tr key={document?.Id} className={`${selectedRows.hasOwnProperty(document?.Id) ? "selected-row " : ""}${document?.status?.replace(/\s+/g, '_')?.toLowerCase()}`}>
                <td className='text-center'>
                    {
                        document.Status !== "To upload" ? (
                            <input
                                type="checkbox"
                                className="form-check-input ml-1"
                                checked={selectedRows.hasOwnProperty(document?.Id)}
                                onChange={() => toggleRowSelection(document?.Id, document)}
                            />
                        ) : (
                            <input
                                type="checkbox"
                                className="form-check-input ml-1"
                                disabled={true}
                                style={{
                                    // cursor: "not-allowed",
                                    opacity: 0.6,
                                    filter: "grayscale(100%)",
                                    backgroundColor: "#d3d3d3",
                                    border: "2px solid #D1D5DC;"
                                }}
                            />
                        )
                    }
                </td>
                <td className={document.IsActive === "0" ? 'text-secondary' : ""}>{document?.Name}</td>
                <td><span className="text-light doc-date">{document?.UploadedDate}</span></td>
                <td className={document?.Status.replaceAll(" ", "_")}>
                    <div className="doc-status">
                        {document?.Status}
                        <span className="lm-tooltip ml-1" data-tooltip={statusTooltipMessages[document?.Status]} data-tooltip-pos="up" data-tooltip-length="cutsom"> <img src={Info_tooltip} alt="" /> </span>
                    </div>
                </td>
                {userRole !== 'Borrower' && (
                    <>
                        <td>
                            {
                                document.Status !== "To upload" ? (
                                    <div className="lm-toggle-switch">
                                        <input
                                            type="checkbox"
                                            id={`${document.Id}-activate`}
                                            checked={isActive[index] || false}
                                            onChange={() => handleActiveChange(index)}
                                        />
                                        <label htmlFor={`${document.Id}-activate`}>Activate</label>
                                    </div>
                                ) : <span className='ml-3'>-</span>
                            }
                        </td>
                        <td>
                            {
                                document.Status === "Completed" ? (
                                    <div key={document.Id} className="lm-toggle-switch">
                                        <div className="lm-toggle-switch">
                                            <input
                                                type="checkbox"
                                                id={`${document.Id}-verify`}
                                                checked={isVerified[index] || false}
                                                onChange={() => handleVerifyChange(index)}
                                            />
                                            <label htmlFor={`${document.Id}-verify`}>Verify</label>
                                        </div>
                                    </div>
                                ) : <span className='ml-3'>-</span>
                            }
                        </td>
                    </>
                )}
                <td>
                    {showActionButtons(document, document?.Status, userRole, index)}
                </td>
            </tr>
        )
    }

    const ActionFooter = ({ selectedRows, toggleRowSelectionStatuses, handleVersionReview, handleDownloadDocument, setSendingEmailDocument, setSendEmailPopup, handleESignPositions, setSendForSignaturePopUp, setSelectedDocumentForSendToSignature }) => {
        const selectedRowCount = Object.keys(selectedRows).length;
        const uniqueStatuses = [...new Set(Object.values(toggleRowSelectionStatuses))];
        const hasSingleStatus = uniqueStatuses.length === 1;
        const firstStatus = uniqueStatuses[0];
        const isNotToUpload = firstStatus && !uniqueStatuses.includes("To upload");

        return (
            <>
                {selectedRowCount > 0 && (
                    <div className="sticky-btn-footer sticky-slected-rows d-flex">
                        <p><b>{selectedRowCount}</b> items selected</p>

                        {isNotToUpload && (
                            <div className='d-flex flex-wrap gap-10 ml-3'>
                                <button type="button" className="lm-button2" onClick={(e) => handleVersionReview(selectedRows)}>Review</button>
                                <button type="button" className="lm-button2" onClick={(e) => handleDownloadDocument(selectedRows)}>Download</button>
                                <button
                                    type="button"
                                    className="lm-button2"
                                    onClick={() => {
                                        setSendingEmailDocument(Object.values(selectedRows));
                                        setSendEmailPopup(true);
                                    }}
                                >
                                    Send Email
                                </button>

                                {hasSingleStatus && (
                                    <>
                                        {firstStatus === "Uploaded" && userRole !== "Borrower" && (
                                            <button type="button" className="lm-button2" onClick={() => handleESignPositions(selectedRows)}>
                                                Set Signature Fields
                                            </button>
                                        )}
                                        {firstStatus === "To sign" && userRole !== "Borrower" && (
                                            <>
                                                <button
                                                    type="button"
                                                    className="lm-button2"
                                                    onClick={() => {
                                                        setSendForSignaturePopUp(true);
                                                        setSelectedDocumentForSendToSignature(Object.values(selectedRows));
                                                    }}
                                                >
                                                    Send for Signature
                                                </button>
                                                {/* <button type="button" className="lm-button2 ml-3">Mark as completed</button> */}
                                            </>
                                        )}
                                        {firstStatus === "Sent for signature" && userRole !== "Borrower" && (
                                            <>
                                                <button type="button" className="lm-button2 ml-3"
                                                    onClick={() => {
                                                        handleConfirmReminder(Object.values(selectedRows))
                                                    }}
                                                >
                                                    Reminder
                                                </button>

                                                <button type="button" className="lm-button2 ml-3"
                                                    onClick={() => {
                                                        setcancelESignaturePopup(true)
                                                        setSelectedDocumentForCancelSendToSing(Object.values(selectedRows))
                                                    }}
                                                >
                                                    Cancel Signature Request
                                                </button>
                                            </>
                                        )}
                                    </>
                                )}
                            </div>
                        )}
                    </div>
                )}
            </>
        );
    };



    return (
        <>
            <SidePanel />

            <ProfileHeader />

            {loading ? (
                <Preloader />
            ) : (
                <div>
                    <div className="pb-5 documet-wrap overflow-hidden">
                        <div className="grid-head">
                            <div className="d-flex justify-content-between place-items-center">
                                <h3>Documents ({documents.filter(doc => doc.IsActive === "1" && doc.Status !== "To upload").length})
                                    <OverlayTrigger
                                        overlay={<Tooltip>Number of documents available to download</Tooltip>}>
                                        <span className="lm-tooltip ml-1">
                                            <img src={Info_tooltip} alt="" />
                                        </span>
                                    </OverlayTrigger>
                                </h3>
                                <div className='d-flex gap-15 place-items-center'>
                                    {/* <button className='lm-button2'>Generate Report</button> */}
                                    <Dropdown>
                                        <Dropdown.Toggle className="lm-button1">
                                            Checklist
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="doc_checklist_dropdown">
                                            <div onClick={downloadOutstandingDocumentList}>
                                                <img className="mr-2" src={download_icon} alt="Download" />
                                                Download outstanding document list
                                            </div>
                                            <div onClick={() => setShowOutstandingEmailPopup(true)}>
                                                <img className="mr-2" src={send_email_icon} alt="email" />
                                                Email outstanding document list
                                            </div>
                                            <div onClick={getChecklistReminderData}>
                                                <img className="mr-2" src={Remind_to_sign} alt="email" />
                                                Reminder
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    <div style={{ borderRight: "1px solid #EAEDF0", width: "1px", height: "28px" }}></div>
                                    {userRole !== 'Borrower' && (
                                        <a onClick={() => setNewDocumentPopup(true)} className="lm-button3 ml-auto w-max-content">
                                            <span>+</span> Add document
                                        </a>
                                    )}
                                </div>

                            </div>
                        </div>
                        <div className="grid-head" style={{ backgroundColor: "#F7F7F7" }}>
                            <div className="filter-search-group d-flex gap-10 justify-content-between">
                                <div className='d-flex gap-10'>
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        id="businessOptions"
                                        name="businessOptions"
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        }}
                                        options={entityList}
                                        defaultValue={entityList.find(entity => entity.value === selectedEntity)}
                                        onChange={(selectedOption) => {
                                            setPageIndex(1);
                                            setSelectedEntity(selectedOption.value)
                                        }
                                        }
                                    />
                                    <Select
                                        className="basic-single"
                                        classNamePrefix="select"
                                        id="status"
                                        name="status"
                                        menuPortalTarget={document.body}
                                        styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                        }}
                                        options={statusOptions}
                                        defaultValue={statusOptions.find(obj => obj.value === status)}
                                        onChange={(selectedOption) => setStatus(selectedOption.value)}
                                    />
                                </div>
                                <div className="lm-search-bar mt-0 mt-sm-0 mb-0">
                                    <input type="text" id="searchInput" placeholder="Search by name" value={searchText}
                                        onChange={(e) => {
                                            setPageIndex(1)
                                            setSearchText(e.target.value)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>

                        {
                            documents.length !== 0 ? (
                                <DynamicTable
                                    data={documents}
                                    dynamicTableDisplay={dynamicTableDisplay}
                                    columns={DocumentsListHeader}
                                    changePageSize={setPageSize}
                                    pageSize={pageSize}
                                    total={totalData}
                                    setList={setDocumentList}
                                    setPageIndex={setPageIndex}
                                    pageIndex={pageIndex}
                                    selectAllRowsHeader={selectAllRowsHeader}
                                    hidePagination={true}
                                />
                            ) : (
                                <div className="flex flex-col items-center justify-end h-full mt-5 text-center">
                                    <img src={dummy_avtar_removebg} alt="" className="mb-4" width="100px" />
                                    <h4 className="font-bold mb-2 ml-2">You're all caught up!</h4>
                                </div>
                            )
                        }
                    </div>

                    {/* this div will visible if any row selected from the the above table */}
                    <ActionFooter
                        selectedRows={selectedRows}
                        toggleRowSelectionStatuses={toggleRowSelectionStatuses}
                        handleVersionReview={handleVersionReview}
                        handleDownloadDocument={handleDownloadDocument}
                        setSendingEmailDocument={setSendingEmailDocument}
                        setSendEmailPopup={setSendEmailPopup}
                        handleESignPositions={handleESignPositions}
                        setSendForSignaturePopUp={setSendForSignaturePopUp}
                        setSelectedDocumentForSendToSignature={setSelectedDocumentForSendToSignature}
                    />


                    {/*Add New document Popup / Modal */}
                    <Modal
                        // size="md"
                        show={newDocumentPopupShow}
                        onHide={() => setNewDocumentPopup(false)}
                        aria-labelledby="newDocumentPopupLabel"
                        backdrop="static"
                        keyboard={false}
                        centered
                    >
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="newDocumentPopupLabel">New document</h5>
                                    <button type="button" className="close"
                                        onClick={handleDocumentModalClose}
                                    >
                                        <span aria-hidden="true"><img src={close_popup} alt="" /></span>
                                    </button>
                                </div>
                                <form>
                                    <Modal.Body>
                                        <div className="form-group">
                                            <label htmlFor="Business_entity">Business entity</label>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                menuPortalTarget={document.body}
                                                styles={{
                                                    menuPortal: base => ({ ...base, zIndex: 9999 })
                                                }}
                                                id="Business_entity"
                                                name="Business_entity"
                                                options={entityList}
                                                defaultValue={entityList[0]}
                                                value={entityList.find(obj => obj.value === selectedEntityForAddDocument)}
                                                onChange={(e) => {
                                                    setSelectedEntityForAddDocument(e.value);
                                                }}
                                            />
                                        </div>
                                        <div className="form-group mb-0">
                                            <label htmlFor="Required_documents">Required documents</label>
                                            <Select
                                                className="basic-single"
                                                classNamePrefix="select"
                                                menuPortalTarget={document.body}
                                                styles={{
                                                    menuPortal: base => ({ ...base, zIndex: 9999 })
                                                }}
                                                closeMenuOnSelect={false}
                                                components={animatedComponents}
                                                isMulti
                                                options={allDocumentsList}
                                                value={selectedRequiredDoucment}
                                                onChange={(e) => {
                                                    const newValues = e.map((item) => item.value);
                                                    const updatedSelection = selectedRequiredDoucment.filter((doc) => newValues.includes(doc.value));

                                                    // Add new selected documents that are not in the current selection
                                                    e.forEach((item) => {
                                                        if (!selectedRequiredDoucment.find((doc) => doc.value === item.value)) {
                                                            updatedSelection.push(item);
                                                        }
                                                    });
                                                    setSelectedRequiredDoucment(updatedSelection);
                                                }}
                                            />
                                        </div>
                                    </Modal.Body>
                                    <div className="modal-footer la-app-modal-footer">
                                        <button type="button" className="lm-button1" onClick={handleAddNewDocument}>Save</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Modal>
                    {/* Add New document Popup / Modal */}


                    {/* <!--Generate report drawer--> */}
                    <Modal
                        className="fade-right drawer-modal generate-report-modal"
                        show={generateReportPopupShow}
                        onHide={() => setGenerateReportPopup(false)}
                        aria-labelledby="generateReportPopupLabel"
                    >
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="drawer-head d-flex justify-content-between">
                                    <div>
                                        <h3>Generate report</h3>
                                    </div>
                                    <button type="button" className="close" onClick={generateReportPopupClose}>
                                        <span aria-hidden="true"><img src={close_popup} alt="" /></span>
                                    </button>
                                </div>
                                <div className="drawer-body">
                                    {/* search bar */}
                                    <div className="lm-search-bar mt-2 mb-4">
                                        <input type="text" id="searchInput" placeholder="Search a report" />
                                    </div>
                                    {/* single box */}
                                    <div className="report-doc-box d-flex justify-content-between">
                                        <div className="report-doc-name d-flex">
                                            <img src={Report_Icon} alt="" />
                                            <p>
                                                Paynet Report
                                            </p>
                                        </div>
                                        <button type="button" className="lm-button1">Generate</button>
                                    </div>

                                    {/* single report generated box */}
                                    <div className="report-doc-box d-flex justify-content-between">
                                        <div className="report-doc-name d-flex">
                                            <img src={Report_Icon} alt="" />
                                            <div>
                                                <p>Paynet Report</p>
                                                <div className="text-light">Generated on Nov 10, 2023</div>
                                            </div>

                                        </div>
                                        <button type="button" className="lm-button1 disabled-btn">Generate</button>
                                    </div>

                                    {/* bottom overlay effect dont remove */}
                                    <div className="generate-report-bottom-overlay"></div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    {/* <!--Generate report drawer--> */}


                    {/* <!--version History drawer--> */}
                    <Modal
                        className="fade-right drawer-modal version-history-modal"
                        show={versionHistoryPopupShow}
                        onHide={() => setVersionHistoryPopup(false)}
                        aria-labelledby="versionHistoryPopupLabel"
                    >
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="drawer-head d-flex justify-content-between">
                                    <div className="drawer-title">
                                        <h3>{versionHistoryFileName}</h3>
                                        <span className="text-light">Version history</span>
                                    </div>
                                    <button type="button" className="close" onClick={versionHistoryPopupClose}>
                                        <span aria-hidden="true"><img src={close_popup} alt="" /></span>
                                    </button>
                                </div>
                                <div className="drawer-body mt-3">
                                    {
                                        versionHistoryList.length ? versionHistoryList.map(list => {
                                            return (
                                                <div className="version-history-table-row">
                                                    <div className="version-histry-date text-light">
                                                        {convertToLocalTime(list?.CreatedOn)}
                                                    </div>
                                                    <div className="version-history-name">
                                                        <b>{list?.Status}</b>
                                                        <br></br>
                                                        {list?.UploadedBy}
                                                    </div>
                                                    <div>
                                                        <Link className="grid-link mr-4 mt-1" onClick={(e) => handleVersionReview({ [list.DocumentId]: list })}>Review</Link>
                                                    </div>
                                                </div>
                                            )
                                        }) : ""}

                                </div>
                                <div className="drawer-footer d-flex">
                                    <button type="button" className="lm-button1 ml-auto">Save</button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    {/* <!--version History drawer--> */}


                    {/*Upload a document Popup / Modal */}
                    <Modal
                        size="md"
                        show={uploadDocumentPopupShow}
                        onHide={() => setUploadDocumentPopup(false)}
                        aria-labelledby="uploadDocumentPopupLabel"
                        backdrop="static"
                        keyboard={false}
                        centered
                    >
                        <div className="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable upload-doc-popup">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="uploadDocumentPopupLabel">Upload a document
                                        <br></br>
                                        <span className="text-light uploadDocumentPopup-subtitle">
                                            <img className="mr-1" src={document_icon} alt="" />{documents[uploadDocumentIndex]?.Name}</span>
                                    </h5>

                                    <button type="button" className="close" onClick={uploadDocumentPopupClose}>
                                        <span aria-hidden="true"><img src={close_popup} alt="" /></span>
                                    </button>
                                </div>
                                <form >
                                    <Modal.Body>

                                        <div className="mb-3">
                                            <div className="upload-box py-4">
                                                <input type="file" id="upload-file" accept=".pdf" onChange={handleFileChange} />
                                                <div className="mx-auto mb-2">
                                                    <svg className="upload-icon" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M6.42725 4.66665L9.99867 1.11108L13.5701 4.66665" stroke="#4057E3" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M9.99902 1.11108V12.963" stroke="#4057E3" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                        <path d="M13.5713 8.22217H17.1427L18.3332 18.8889H1.6665L2.85698 8.22217H6.42841" stroke="#4057E3" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
                                                    </svg>
                                                </div>
                                                <div className="upload-text">
                                                    Drop PDF file here to upload or <button type="button" id="upload-button">choose file</button>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            file ? (
                                                <>
                                                    <div className="uploaded-file-box d-flex justify-content-between">
                                                        <div className="d-flex">
                                                            <img src={pdf_file_icon} alt="PDF Icon" />
                                                            <div className="ml-2">
                                                                <div className="file-name">{file?.name}</div>
                                                                <span className="text-light file-size">{(file?.size / (1024 * 1024)).toFixed(2)} MB</span>
                                                            </div>
                                                        </div>

                                                        <div className="d-flex place-items-center">
                                                            <span className="doc-upload-percentage">{uploadProgress}%</span>
                                                            <button type='button' className="view-doc-button" onClick={handleViewDocument}>
                                                                <img className="mx-2" src={pass_eye} alt="View Document" />
                                                            </button>
                                                            <button type="button" className="close" onClick={removeDocument}>
                                                                <span><img src={close_popup} alt="Close" /></span>
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div className="upload-progress">
                                                        <div className="upload-progress-bar" style={{ width: `${uploadProgress}%` }}></div>
                                                    </div>
                                                    {
                                                        documents[uploadDocumentIndex]?.DocumentType === "AGD" && userRole !== "Borrower" ? (
                                                            <div className="form-group mb-0 mt-3">
                                                                <div className="label pb-1">Does the borrower need to sign this document?</div>
                                                                <div className="form-check form-check-inline">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="borrower_need_sign"
                                                                        id="borrower_need_sign_Yes"
                                                                        value={true}
                                                                        checked={borrowerNeedsSign === true}
                                                                        onChange={(e) => setBorrowerNeedsSign(e.target.value === 'true')}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="borrower_need_sign_Yes">Yes</label>
                                                                </div>
                                                                <div className="form-check form-check-inline">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        name="borrower_need_sign"
                                                                        id="borrower_need_sign_No"
                                                                        value={false}
                                                                        checked={borrowerNeedsSign === false}
                                                                        onChange={(e) => setBorrowerNeedsSign(e.target.value === 'true')}
                                                                    />
                                                                    <label className="form-check-label" htmlFor="borrower_need_sign_No">No</label>
                                                                </div>
                                                            </div>
                                                        ) : ""
                                                    }

                                                </>
                                            ) : ""
                                        }

                                    </Modal.Body>
                                    <div className="modal-footer la-app-modal-footer">
                                        <button type="button" className="lm-button2" onClick={uploadDocumentPopupClose}>Cancel</button>
                                        <button type="submit" className="lm-button1" onClick={handleUploadDocument}>Save</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Modal>
                    {/* Upload a document Popup / Modal */}


                    {/* Send Email to Sign Position Popup / Modal */}
                    <Modal
                        size="md"
                        show={sendForSignaturePopUp}
                        onHide={() => setSendForSignaturePopUp(false)}
                        aria-labelledby="sendEmailPopupLabel"
                        backdrop="static"
                        keyboard={false}
                        centered
                    >
                        <div className="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="sendEmailPopupLabel">Send for Signature</h5>
                                    <button type="button" className="close" onClick={sendForSignatureClose}>
                                        <span aria-hidden="true"><img src={close_popup} alt="" /></span>
                                    </button>
                                </div>
                                <form>
                                    <Modal.Body>
                                        <div className="form-group">
                                            <label htmlFor="Recipient">Recipient</label>
                                            <input
                                                type="text"
                                                id="Recipient"
                                                className='form-control'
                                                value={emailRecipientsForSign}
                                                onChange={(e) => setEmailRecipientsForSign(e.target.value)}
                                                placeholder='Enter email address'
                                                maxLength={50}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="Notes">Comment</label>
                                            <textarea
                                                className="form-control"
                                                id="Message"
                                                value={emailNotesForSign}
                                                onChange={(e) => setEmailNotesForSign(e.target.value)}
                                                placeholder="Enter message"
                                                maxLength={250}
                                            />

                                        </div>
                                    </Modal.Body>
                                    <div className="modal-footer la-app-modal-footer">
                                        <button type="button" className="lm-button2" onClick={sendForSignatureClose}>Cancel</button>
                                        <button type="button" className="lm-button1" onClick={handleSendToSignature}>Send</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Modal>
                    {/* Send Email to Sign Position Popup / Modal */}


                    {/*Send Email Popup / Modal */}
                    <Modal
                        size="md"
                        show={sendEmailPopupShow}
                        onHide={() => setSendEmailPopup(false)}
                        aria-labelledby="sendEmailPopupLabel"
                        backdrop="static"
                        keyboard={false}
                        centered
                    >
                        <div className="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="sendEmailPopupLabel">Send email</h5>
                                    <button type="button" className="close" onClick={sendEmailPopupClose}>
                                        <span aria-hidden="true"><img src={close_popup} alt="" /></span>
                                    </button>
                                </div>
                                <form>
                                    <Modal.Body>
                                        <div className="form-group">
                                            <label htmlFor="Recipient">Recipient(s)</label>
                                            <CreatableSelect
                                                className="basic-single emailbox-input"
                                                classNamePrefix="select"
                                                placeholder={<div className="email-list-placeholder"><span className="placeholder-add-sign">+</span> Add</div>}
                                                isMulti
                                                onChange={(selectedOption) => setEmailRecipients(selectedOption)}
                                                isValidNewOption={isValidEmailOption}
                                            />
                                        </div>

                                        <div className="form-group">
                                            <label htmlFor="Notes">Notes</label>
                                            <textarea
                                                className="form-control"
                                                id="Notes" value={emailNotes}
                                                onChange={(e) => setEmailNotes(e.target.value)}
                                            />

                                        </div>
                                    </Modal.Body>
                                    <div className="modal-footer la-app-modal-footer">
                                        <button type="button" className="lm-button2" onClick={sendEmailPopupClose}>Cancel</button>
                                        <button type="button" className="lm-button1" onClick={sendDocumentToEmail}>Send</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Modal>
                    {/* Send Email Popup / Modal */}


                    {/*Reminder Popup / Modal */}
                    {/* This is customized modal used for datepicker isuue purpose don't use this anywhere else */}
                    <div className={`custom-modal cust-modal-md modal-open modal-backdrop ${ReminderPopupShow ? "cust-modal-show" : "cust-modal-hide"}`}>
                        <div className="modal-box">
                            <div className="modal-header">
                                <div className='d-flex align-items-center'>
                                    <h5 className="modal-title" id="ReminderPopupLabel">Reminder</h5>
                                    <span className="lm-tooltip ml-1"
                                        data-tooltip="Reminders will be sent to primary borrower's email"
                                        data-tooltip-pos="up" data-tooltip-length="fit"> <img src={Info_tooltip} alt="" />
                                    </span>
                                </div>
                                <div className='d-flex gap-15'>
                                    <u onClick={() => setResetReminder(true)} style={{ fontSize: "14px", marginTop: "-3px", cursor: "pointer" }} className='text-red '>Reset reminder</u>
                                    <button type="button" className="close" onClick={ReminderPopupClose}>
                                        <span aria-hidden="true"><img src={close_popup} alt="" /></span>
                                    </button>
                                </div>
                            </div>
                            <form>
                                <Modal.Body>
                                    <div className='row'>
                                        <div className='col-12'>
                                            <div className="form-group">
                                                <div className="label pb-1">Frequency</div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="frequency"
                                                        id="Daily"
                                                        checked={checklistReminderData.frequency === "Daily"}
                                                        onChange={(e) => setChecklistReminderData({ ...checklistReminderData, frequency: "Daily", startDay: "", startTime: "" })}
                                                    />
                                                    <label className="form-check-label" htmlFor="Daily">Daily</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name="frequency"
                                                        id="Weekly"
                                                        checked={checklistReminderData.frequency === "Weekly"}
                                                        onChange={(e) => setChecklistReminderData({ ...checklistReminderData, frequency: "Weekly" })}
                                                    />
                                                    <label className="form-check-label" htmlFor="Weekly">Weekly</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-group">
                                                <label>Day</label>
                                                <Select
                                                    className="basic-single"
                                                    classNamePrefix="select"
                                                    menuPortalTarget={document.body}
                                                    styles={{
                                                        menuPortal: base => ({ ...base, zIndex: 9999 })
                                                    }}
                                                    options={staticWeekdayList}
                                                    value={staticWeekdayList.find(obj => obj.value === checklistReminderData.startDay)}
                                                    onChange={(e) => setChecklistReminderData({ ...checklistReminderData, startDay: e.value })}
                                                    isDisabled={checklistReminderData.frequency !== "Weekly"}
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label htmlFor="Time">Time</label>
                                                <input
                                                    type="time"
                                                    disableClock={true}
                                                    clearIcon={null}
                                                    value={checklistReminderData.startTime}
                                                    onChange={(e) => setChecklistReminderData({ ...checklistReminderData, startTime: e.target.value })}
                                                    disabled={checklistReminderData.frequency !== "Weekly"}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-check form-check-inline mt-2">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="SelectEnd"
                                                    id="EndBy"
                                                    checked={checklistReminderData.endType === "EndBy"}
                                                    onChange={(e) => setChecklistReminderData({ ...checklistReminderData, endType: "EndBy", endAfter: "" })}
                                                />
                                                <label className="form-check-label" htmlFor="EndBy">End by</label>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-group input-date">
                                                <label htmlFor="Date"></label>
                                                <DatePicker
                                                    showIcon
                                                    toggleCalendarOnIconClick
                                                    selected={checklistReminderData.endDate ? moment(checklistReminderData.endDate).format('MMM DD, YYYY') : ''}
                                                    value={checklistReminderData.endDate ? moment(checklistReminderData.endDate).format('MMM DD, YYYY') : ''}
                                                    isClearable={checklistReminderData.endType === "EndBy" && checklistReminderData.endDate}
                                                    dateFormat="MMM d, yyyy"
                                                    placeholderText="Month DD, YYYY"
                                                    yearDropdownItemNumber={100}
                                                    minDate={moment().format("MM-DD-YYYY")}
                                                    scrollableYearDropdown
                                                    showMonthDropdown
                                                    showYearDropdown
                                                    onChange={(date) => {
                                                        if (date) {
                                                            setChecklistReminderData({ ...checklistReminderData, endDate: moment(date).format("MM-DD-YYYY") })
                                                        } else {
                                                            setChecklistReminderData({ ...checklistReminderData, endDate: "" })
                                                        }
                                                    }}
                                                    onKeyDown={(e) => {
                                                        e.preventDefault();
                                                    }}
                                                    disabled={checklistReminderData.endType !== "EndBy"}
                                                    popperPlacement="bottom-end" // Adjust placement
                                                    popperProps={{
                                                        modifiers: [
                                                            {
                                                                name: "preventOverflow",
                                                                options: {
                                                                    boundary: "window",
                                                                },
                                                            },
                                                            {
                                                                name: "flip",
                                                                options: {
                                                                    fallbackPlacements: ["top", "bottom"],
                                                                },
                                                            },
                                                        ],
                                                    }}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className="form-check form-check-inline mt-2">
                                                <input
                                                    className="form-check-input"
                                                    type="radio"
                                                    name="SelectEnd"
                                                    id="endAfter"
                                                    checked={checklistReminderData.endType === "endAfter"}
                                                    onChange={(e) => setChecklistReminderData({ ...checklistReminderData, endType: "endAfter", endDate: "" })}
                                                />
                                                <label className="form-check-label" htmlFor="endAfter">End after</label>
                                            </div>
                                        </div>
                                        <div className='col-md-6'>
                                            <div className='form-group place-items-center'>
                                                <input
                                                    style={{ width: "80px", marginRight: "10px" }}
                                                    type="text"
                                                    value={checklistReminderData.endAfter}
                                                    onChange={(e) => {
                                                        let value = e.target.value.replace(/\D/g, ""); // Remove non-numeric characters
                                                        let intValue = value === "0" ? "" : value;
                                                        if (parseInt(intValue) > 100) intValue = "100";
                                                        console.log(intValue);
                                                        setChecklistReminderData({ ...checklistReminderData, endAfter: intValue });
                                                    }}
                                                    disabled={checklistReminderData.endType !== "endAfter"}
                                                />
                                                <label>weeks</label>

                                            </div>

                                        </div>
                                    </div>
                                </Modal.Body>
                                <div className="modal-footer la-app-modal-footer">
                                    <button type="button" className="lm-button2" onClick={ReminderPopupClose}>Cancel</button>
                                    <button type="button" className="lm-button1" onClick={handleConfirmChecklistReminder}>Save</button>
                                </div>
                            </form>
                        </div>
                    </div>
                    {/* Reminder Popup / Modal */}


                    {/*Send Email Popup / Modal */}
                    <Modal
                        size="md"
                        show={showOutstandingEmailPopup}
                        onHide={() => setShowOutstandingEmailPopup(false)}
                        aria-labelledby="sendEmailPopupLabel"
                        backdrop="static"
                        keyboard={false}
                        centered
                    >
                        <div className="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title" id="sendEmailPopupLabel">Send email</h5>
                                    <button type="button" className="close" onClick={() => setShowOutstandingEmailPopup(false)}>
                                        <span aria-hidden="true"><img src={close_popup} alt="" /></span>
                                    </button>
                                </div>
                                <form>
                                    <Modal.Body>
                                        <div className="form-group">
                                            <label htmlFor="Recipient">Recipient(s)</label>
                                            <CreatableSelect
                                                className="basic-single emailbox-input"
                                                classNamePrefix="select"
                                                placeholder={<div className="email-list-placeholder"><span className="placeholder-add-sign">+</span> Add</div>}
                                                isMulti
                                                menuPortalTarget={document.body}
                                                styles={{
                                                    menuPortal: base => ({ ...base, zIndex: 9999 })
                                                }}
                                                onChange={(selectedOption) => setSelectedOutstandingEmails(selectedOption)}
                                                isValidNewOption={isValidEmailOption}
                                            />
                                        </div>
                                    </Modal.Body>
                                    <div className="modal-footer la-app-modal-footer">
                                        <button type="button" className="lm-button2" onClick={() => setShowOutstandingEmailPopup(false)}>Cancel</button>
                                        <button type="button" className="lm-button1" onClick={emailOutstandingDocumentList}>Send</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </Modal>
                    {/* Send Email Popup / Modal */}


                    {/* Reset Reminder popup */}
                    <Modal
                        className='mark-comp-popup-cust-size'
                        show={resetReminderShow}
                        onHide={() => setResetReminder(false)}
                        aria-labelledby="deleteSignPositionLabel"
                        backdrop="static"
                        keyboard={false}
                        centered
                    >
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-body signout-modal">
                                    <h3 className="pt-4">
                                        Are you sure you want to reset this reminder?
                                    </h3>
                                </div>
                                <div className="modal-footer la-app-modal-footer">
                                    <button type="button" className="lm-button2" onClick={resetReminderClose}>Cancel</button>
                                    <button type="button" className="lm-button1" onClick={handleComfirmResetReminder}>Confirm</button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    {/* Reset Reminder popup */}

                    {/* <!--set e-Signature drawer--> */}
                    <Modal
                        className="fade-right drawer-modal e-signature-drawer"
                        show={ESignaturePopupShow}
                        onHide={() => setESignaturePopup(false)}
                        aria-labelledby="ESignaturePopupLabel"
                    >
                        <div className="drawer-head d-flex justify-content-between">
                            <div>
                                <h3>Set e-signature</h3>
                            </div>
                            <button type="button" className="close" onClick={ESignaturePopupClose}>
                                <span aria-hidden="true"><img src={close_popup} alt="" /></span>
                            </button>
                        </div>
                        <div className="drawer-body">
                            {
                                Object.keys(selectedDocumentForEsign).length > 1 && (
                                    <div className="d-flex pb-3 align-items-center justify-content-between">
                                        <div className="lm-nav-tabs">
                                            <ul className="nav nav-tabs" id="laTab" role="tablist">
                                                {
                                                    Object.values(selectedDocumentForEsign).map(element => {
                                                        return (
                                                            <li key={element.Id} className="nav-item">
                                                                <button className={`nav-link ${activeTab === element.Id ? "active" : ""}`} onClick={() => handleTabClick(element.Id)}>
                                                                    {element.Name}
                                                                </button>
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                        <div className="d-flex place-items-center">
                                            <div className="doc-progress mr-3">
                                                <svg>
                                                    <circle cx="10" cy="10" r="8"></circle>
                                                    <circle cx="10" cy="10" r="8"></circle>
                                                </svg>
                                                <span>{totalSignedDocuments} / {Object.keys(selectedDocumentForEsign).length} documents completed </span>
                                            </div>
                                            {/* manage "disabled-btn" class for button */}
                                            <button type="button" className="lm-button1 disabled-btn" onClick={handlePreviousTab}>Previous</button>
                                            <button type="button" className="lm-button2 ml-2" onClick={handleNextTab}>Next</button>
                                        </div>
                                    </div>
                                )
                            }

                            <div className="tab-content" id="laTabContent">
                                {
                                    Object.keys(selectedDocumentForEsign).length > 0 && Object.values(selectedDocumentForEsign).map(document => {
                                        return (
                                            <div className={`tab-pane fade ${activeTab === document.Id ? "show active" : ""}`} id={document.Id}>
                                                <div className="mb-4 d-flex justify-content-between">
                                                    <span className="e-Signature-title">
                                                        <img src={file_icon} alt="" />
                                                        {document.Name}</span>
                                                    <div>
                                                        {
                                                            Object.keys(selectedDocumentForEsign).length === 1 && (
                                                                <div className="doc-progress">
                                                                    <svg>
                                                                        <circle cx="10" cy="10" r="8"></circle>
                                                                        <circle cx="10" cy="10" r="8"></circle>
                                                                    </svg>
                                                                    <span>{totalSignedDocuments} / 1 documents completed </span>
                                                                </div>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                                <div className="esign-category-tab-sec mb-3">
                                                    {/* add / remove class "current-tab" for acvtive tab */}
                                                    <div className={`${selectedTabOption === 1 ? "current-tab" : ""} esign-category-tab`} onClick={(e) => setSelectedTabOption(1)}>
                                                        <i className={selectedTabOptionIcon?.[activeTab]?.['1']}></i>
                                                        Set e-signature block
                                                    </div>
                                                    <div className={`${selectedTabOption === 2 ? "current-tab" : ""} esign-category-tab`} onClick={(e) => setSelectedTabOption(2)}>
                                                        <i className={selectedTabOptionIcon?.[activeTab]?.['2']}></i>
                                                        Set date block
                                                    </div>
                                                    <div className={`${selectedTabOption === 3 ? "current-tab" : ""} esign-category-tab`} onClick={(e) => setSelectedTabOption(3)}>
                                                        <i className={selectedTabOptionIcon?.[activeTab]?.['3']}></i>
                                                        Set text block
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-auto">
                                                        <div className="doc-preview-box mb-2">
                                                            <div className="doc-preview"
                                                                ref={(el) => setRef(document.Id, el)}
                                                                onClick={(e) => handleMouseClick(e, document.Id)}
                                                            >
                                                                <Document
                                                                    file={document.url}
                                                                    onLoadSuccess={(props) => onDocumentLoadSuccess(props, document)}
                                                                    options={options}
                                                                >
                                                                    {Array.from(new Array(pagesPerDocument[document.Id]), (_el, index) => (
                                                                        <div
                                                                            key={`page_${index + 1}`}
                                                                            style={
                                                                                index + 1 !== pagesPerDocument[document.Id] ? {
                                                                                    marginBottom: '20px',
                                                                                    borderBottom: '1px solid #ccc',
                                                                                    paddingBottom: '20px'
                                                                                } : {}
                                                                            }
                                                                        >
                                                                            <Page pageNumber={index + 1} width={pageDimensions[document.Id]?.[index + 1]['width']} scale={1} />
                                                                        </div>
                                                                    ))}

                                                                </Document>
                                                                {showRectangleBlocks()}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col d-flex justify-content-end">
                                                        <div className="lm-card">
                                                            <div className="note-box d-flex">
                                                                <img src={note_icon} alt="" />
                                                                <div>
                                                                    <p>
                                                                        Click on document to set position.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <button onClick={handleSetSignPositions} type="button" className="lm-button1 w-100 mb-3">Set Position</button>
                                                            <button onClick={() => setDeleteAllSignPosition(true)} type="button" className="lm-button2 w-100 mb-2">Clear All Position(s)</button>
                                                            <button onClick={() => setDeleteLastSignPosition(true)} type="button" className="lm-button2 w-100 mb-3">Clear Last Position</button>

                                                            {
                                                                [1, 4].includes(selectedTabOption) && (
                                                                    <>
                                                                        <div className="form-group form-check">
                                                                            <input
                                                                                type="checkbox"
                                                                                className="form-check-input"
                                                                                id="Second_Person_Signature"
                                                                                checked={secondPersionSign}
                                                                                onChange={(e) => {
                                                                                    setSelectedTabOption(4)
                                                                                    setSecondPersionSign(e.target.checked)
                                                                                    if (e.target.checked === false) {
                                                                                        setSelectedTabOption(1)
                                                                                    }
                                                                                }}
                                                                            />
                                                                            <label className="form-check-label" htmlFor="Second_Person_Signature">Second Person Signature</label>
                                                                        </div>
                                                                        <input
                                                                            type="email"
                                                                            className="form-control"
                                                                            placeholder="Second Person Email"
                                                                            disabled={secondPersionSign ? false : true}
                                                                            value={secondPersonEmail}
                                                                            onChange={(e) => setSecondPersonEmail(e.target.value)}
                                                                        />
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </div>
                        <div className="drawer-footer d-flex">
                            <button type="button" className="lm-button1 ml-auto" onClick={handleSubmitSetEsignature}>Save</button>
                        </div>
                    </Modal>
                    {/* <!--set e-Signature drawer--> */}

                    {/* delete the last sign popup */}
                    <Modal
                        className='mark-comp-popup-cust-size'
                        show={deleteLastSignPositionShow}
                        onHide={() => setDeleteLastSignPosition(false)}
                        aria-labelledby="deleteSignPositionLabel"
                        backdrop="static"
                        keyboard={false}
                        centered
                    >
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-body signout-modal">
                                    <h3 className="pt-4">
                                        Are you sure you want to delete the last sign position?
                                    </h3>
                                </div>
                                <div className="modal-footer la-app-modal-footer">
                                    <button type="button" className="lm-button2" onClick={deleteLastSignPositionClose}>Cancel</button>
                                    <button type="button" className="lm-button1" onClick={handleRemoveLastSign}>Confirm</button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    {/* delete the last sign popup */}

                    {/* delete the last sign popup */}
                    <Modal
                        className='mark-comp-popup-cust-size'
                        show={deleteAllSignPositionShow}
                        onHide={() => setDeleteAllSignPosition(false)}
                        aria-labelledby="deleteSignPositionLabel"
                        backdrop="static"
                        keyboard={false}
                        centered
                    >
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-body signout-modal">
                                    <h3 className="pt-4">
                                        Are you sure you want to delete the all sign position?
                                    </h3>
                                </div>
                                <div className="modal-footer la-app-modal-footer">
                                    <button type="button" className="lm-button2" onClick={deleteAllSignPositionClose}>Cancel</button>
                                    <button type="button" className="lm-button1" onClick={handleRemoveAllSign}>Confirm</button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    {/* delete the last sign popup */}


                    {/* <!--cancel e-Signature Popup / Modal --> */}
                    <Modal
                        className='mark-comp-popup-cust-size'
                        show={cancelESignaturePopupShow}
                        onHide={() => setcancelESignaturePopup(false)}
                        aria-labelledby="cancelESignaturePopupLabel"
                        backdrop="static"
                        keyboard={false}
                        centered
                    >
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-body signout-modal">
                                    <h3 className="pt-4">
                                        Do you confirm to cancel e-Signature?
                                    </h3>
                                    <p className="text-light modal-text-info">
                                        If you cancel the e-signature you will cancel it
                                        <span className="text-red"> for the other documents </span>
                                        you set e-signature for
                                    </p>
                                </div>
                                <div className="modal-footer la-app-modal-footer">
                                    <button type="button" className="lm-button2" onClick={cancelESignaturePopupClose}>Cancel</button>
                                    <button type="button" className="lm-button1" onClick={handleConfirmCancelSendToSing}>Confirm</button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    {/* <!--cancel e-Signature Popup / Modal --> */}


                    {/* <!--mark complete Popup / Modal --> */}
                    <Modal
                        className='mark-comp-popup-cust-size'
                        show={markCompletePopupShow}
                        onHide={() => setMarkCompletePopup(false)}
                        aria-labelledby="markCompletePopupLabel"
                        backdrop="static"
                        keyboard={false}
                        centered
                    >
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-body signout-modal">
                                    <h3 className="pt-4">
                                        Are you sure you want to mark "Business History" as completed?
                                    </h3>
                                </div>
                                <div className="modal-footer la-app-modal-footer">
                                    <button type="button" className="lm-button2" onClick={markCompletePopupClose}>Cancel</button>
                                    <button type="button" className="lm-button1" onClick={handleConfirmMarkAsCompelete}>Confirm</button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    {/* <!--mark complete Popup / Modal --> */}


                    {/* <!--Add Note drawer start--> */}
                    <Modal
                        className="fade-right drawer-modal add-notes-drawer"
                        show={addNoteDrawerShow}
                        onHide={() => setNoteDrawer(false)}
                        aria-labelledby="addNoteDrawerLabel"
                    >
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <div className="drawer-head d-flex justify-content-between">
                                    <h3>Notes {notesList.length ? '(' + notesList.length + ')' : ''}</h3>
                                    <button type="button" className="close" onClick={noteDrawerClose}>
                                        <span aria-hidden="true">
                                            <img src={close_popup} alt="" />
                                        </span>
                                    </button>
                                </div>
                                <div className="drawer-body">
                                    <div className="note-list mt-2">
                                        {
                                            notesList.map((note, index) => (
                                                <div className="note-item">
                                                    <div className="d-flex justify-content-between">
                                                        <div className="note-user">
                                                            <img src={note.profileImage ? `data:image/png;base64,${note.profileImage}` : dummy_avtar} alt="" />
                                                            <h4>{note.createdName}</h4>
                                                        </div>
                                                        <div className="d-flex gap-10 place-items-center">
                                                            <small className="text-light">{moment.utc(note.createdDate).local().format("MMM DD, YYYY : hh:mm:A")}</small>
                                                            {
                                                                (userRole === 'Admin' || note.createdBy === id || (userRole === "Advisor" && note.roleName !== "Admin")) &&
                                                                <a onClick={() => {
                                                                    setSelectedNoteToRemove(note.id)
                                                                    setShowConfirmNoteRemove(true)
                                                                }}>
                                                                    <img className='lm-grid-delete-icon' src={delete_icon} alt="" />
                                                                </a>
                                                            }
                                                        </div>
                                                    </div>
                                                    <p className="note-text">
                                                        {note.notes}
                                                    </p>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                                <div className="drawer-footer">
                                    <textarea
                                        id="add_note"
                                        className="form-control"
                                        placeholder="Enter Text"
                                        value={noteComment}
                                        onChange={(e) => setNotesComment(e.target.value)}
                                        maxlength="250"
                                    >
                                    </textarea>
                                    <div className="w-100 mt-3">
                                        <button type="button" className="lm-button1 w-100" onClick={handleAddNewNote}>Add a Note</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    {/* <!--Add Note drawer end--> */}


                    {/* <!-- Remove Documents notes Popup / Modal --> */}
                    <Modal
                        className='mark-comp-popup-cust-size'
                        show={showConfirmNoteRemove}
                        onHide={() => setShowConfirmNoteRemove(false)}
                        aria-labelledby="markCompletePopupLabel"
                        backdrop="static"
                        keyboard={false}
                        centered
                    >
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                            <div className="modal-content">
                                <div className="modal-body signout-modal">
                                    <h3 className="pt-4">
                                        Are you sure you want to remove this note?
                                    </h3>
                                </div>
                                <div className="modal-footer la-app-modal-footer">
                                    <button type="button" className="lm-button2" onClick={() => setShowConfirmNoteRemove(false)}>Cancel</button>
                                    <button type="button" className="lm-button1" onClick={handleDeleteNote}>Confirm</button>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    {/* <!-- Remove Documents notes Popup / Modal --> */}
                </div>
            )}
        </>
    );
}

export default Documents;
