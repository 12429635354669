
export const staticTypeOfBusinessList = [
    { Id: "13", Identifier: "TypeOfBusness", value: "Sole Proprietor", label: "Sole Proprietor" },
    { Id: "14", Identifier: "TypeOfBusness", value: "Limited Liability Company (LLC)", label: "Limited Liability Company (LLC)" },
    { Id: "15", Identifier: "TypeOfBusness", value: "C-Corporation", label: "C-Corporation" },
    { Id: "16", Identifier: "TypeOfBusness", value: "Partnership", label: "Partnership" },
    { Id: "17", Identifier: "TypeOfBusness", value: "Independent Contractor", label: "Independent Contractor" },
    { Id: "59", Identifier: "TypeOfBusness", value: "S-Corporation", label: "S-Corporation" }
]

export const staticBusinessCategoriesList = [
    { Id: "33", Identifier: "BusinessCategories", value: "Contractor", label: "Contractor" },
    { Id: "34", Identifier: "BusinessCategories", value: "Gas Station", label: "Gas Station" },
    { Id: "36", Identifier: "BusinessCategories", value: "Hotel", label: "Hotel" },
    { Id: "37", Identifier: "BusinessCategories", value: "Medical", label: "Medical" },
    { Id: "32", Identifier: "BusinessCategories", value: "Restaurant", label: "Restaurant" },
    { Id: "99", Identifier: "BusinessCategories", value: "Transportation", label: "Transportation" },
    { Id: "35", Identifier: "BusinessCategories", value: "Others", label: "Others" }
]

export const staticLoanReasonList = [
    { Id: "1", Identifier: "LoanReason", value: "Accounts Receivables Financing", label: "Accounts Receivables Financing" },
    { Id: "2", Identifier: "LoanReason", value: "Business Acquisition", label: "Business Acquisition" },
    { Id: "3", Identifier: "LoanReason", value: "Business Expansion", label: "Business Expansion" },
    { Id: "4", Identifier: "LoanReason", value: "Construction or Remodeling", label: "Construction or Remodeling" },
    { Id: "5", Identifier: "LoanReason", value: "Equipment Financing", label: "Equipment Financing" },
    { Id: "6", Identifier: "LoanReason", value: "Franchise Financing", label: "Franchise Financing" },
    { Id: "7", Identifier: "LoanReason", value: "Partner Buyout", label: "Partner Buyout" },
    { Id: "8", Identifier: "LoanReason", value: "Real Estate Financing", label: "Real Estate Financing" },
    { Id: "9", Identifier: "LoanReason", value: "Refinance Existing Debt", label: "Refinance Existing Debt" },
    { Id: "10", Identifier: "LoanReason", value: "Start a Business", label: "Start a Business" },
    { Id: "11", Identifier: "LoanReason", value: "Working Capital", label: "Working Capital" },
    { Id: "12", Identifier: "LoanReason", value: "Other", label: "Other" }
]

export const staticGenderList = [
    { Id: "101", Identifier: "Gender", value: "F", label: "Female" },
    { Id: "100", Identifier: "Gender", value: "M", label: "Male" },
    { Id: "102", Identifier: "Gender", value: "N", label: "Not Disclosed" }
]

export const staticVerteranList = [
    { Id: "18", Identifier: "Veteran", value: "1", label: "Non Veteran" },
    { Id: "20", Identifier: "Veteran", value: "3", label: "Service-Disabled Veteran" },
    { Id: "21", Identifier: "Veteran", value: "5", label: "Spouse of Veteran" },
    { Id: "19", Identifier: "Veteran", value: "2", label: "Veteran" },
    { Id: "22", Identifier: "Veteran", value: "4", label: "Not Disclosed" }
]

export const staticEthnicityList = [
    { Id: "29", Identifier: "Ethnicity", value: "H", label: "Hispanic or Latino" },
    { Id: "30", Identifier: "Ethnicity", value: "N", label: "Not Hispanic or Latino" },
    { Id: "31", Identifier: "Ethnicity", value: "X", label: "Not Disclosed" }
]

export const staticRaceList = [
    { Id: "23", Identifier: "Race", value: "1", label: "American Indian or Alaska Native" },
    { Id: "24", Identifier: "Race", value: "2", label: "Asian" },
    { Id: "25", Identifier: "Race", value: "3", label: "Black or African-American" },
    { Id: "26", Identifier: "Race", value: "4", label: "Native Hawaiian or Pacific Islander" },
    { Id: "27", Identifier: "Race", value: "5", label: "White" },
    { Id: "28", Identifier: "Race", value: "X", label: "Not Disclosed" }
]

export const staticApplicationStatusList = [
    { Id: "57", Identifier: "ApplicationStatus", value: "Closed", label: "Closed" },
    { Id: "56", Identifier: "ApplicationStatus", value: "Completed", label: "Completed" },
    { Id: "53", Identifier: "ApplicationStatus", value: "Documents Required", label: "Documents Required" },
    { Id: "52", Identifier: "ApplicationStatus", value: "In Progress", label: "In Progress" },
    { Id: "51", Identifier: "ApplicationStatus", value: "New", label: "New" },
    { Id: "55", Identifier: "ApplicationStatus", value: "On Hold", label: "On Hold" },
    { Id: "54", Identifier: "ApplicationStatus", value: "Review Required", label: "Review Required" }
]

export const staticBusinessTaxReturnList = [
    { value: "1065", label: "1065" },
    { value: "1120", label: "1120" },
    { value: "1120S", label: "1120S" },
    { value: "1040", label: "Schedule C" },
    { value: "Others", label: "Other" }
]

export const staticPrescreenRequirementList = [
    { label: "2 years business tax returns", value: "2 years business tax returns" },
    { label: "2 years personal tax returns", value: "2 years personal tax returns" },
    { label: "Personal Financial Statements", value: "Personal Financial Statements" },
    { label: "Property Rent Roll (if relevant)", value: "Property Rent Roll (if relevant)" },
    { label: "Property Summary (if relevant)", value: "Property Summary (if relevant)" },
    { label: "YTD financials", value: "YTD financials" },
    { label: "YTD financials", value: "YTD financials" }
]

export const staticPropertyTypeList = [
    { label: "Convenience stores", value: "Convenience stores" },
    { label: "Day Care", value: "Day Care" },
    { label: "Equipment", value: "Equipment" },
    { label: "Franchise", value: "Franchise" },
    { label: "Hotels", value: "Hotels" },
    { label: "Industrial", value: "Industrial" },
    { label: "Medical / Dental", value: "Medical / Dental" },
    { label: "Mixed Use", value: "Mixed Use" },
    { label: "Multi Family", value: "Multi Family" },
    { label: "Office", value: "Office" },
    { label: "Office Condo", value: "Office Condo" },
    { label: "Restaurants", value: "Restaurants" },
    { label: "Retail", value: "Retail" },
    { label: "Self Storage", value: "Self Storage" },
    { label: "Start-Ups", value: "Start-Ups" },
    { label: "Warehouse", value: "Warehouse" },
    { label: "Working Capital", value: "Working Capital" },
    { label: "Others", value: "Others" }
]

export const staticStatesList = [
    { value: "Alabama", label: "Alabama" },
    { value: "Alaska", label: "Alaska" },
    { value: "Arizona", label: "Arizona" },
    { value: "Arkansas", label: "Arkansas" },
    { value: "California", label: "California" },
    { value: "Colorado", label: "Colorado" },
    { value: "Connecticut", label: "Connecticut" },
    { value: "Delaware", label: "Delaware" },
    { value: "Florida", label: "Florida" },
    { value: "Georgia", label: "Georgia" },
    { value: "Hawaii", label: "Hawaii" },
    { value: "Idaho", label: "Idaho" },
    { value: "Illinois", label: "Illinois" },
    { value: "Indiana", label: "Indiana" },
    { value: "Iowa", label: "Iowa" },
    { value: "Kansas", label: "Kansas" },
    { value: "Kentucky", label: "Kentucky" },
    { value: "Louisiana", label: "Louisiana" },
    { value: "Maine", label: "Maine" },
    { value: "Maryland", label: "Maryland" },
    { value: "Massachusetts", label: "Massachusetts" },
    { value: "Michigan", label: "Michigan" },
    { value: "Minnesota", label: "Minnesota" },
    { value: "Mississippi", label: "Mississippi" },
    { value: "Missouri", label: "Missouri" },
    { value: "Montana", label: "Montana" },
    { value: "Nebraska", label: "Nebraska" },
    { value: "Nevada", label: "Nevada" },
    { value: "New Hampshire", label: "New Hampshire" },
    { value: "New Jersey", label: "New Jersey" },
    { value: "New Mexico", label: "New Mexico" },
    { value: "New York", label: "New York" },
    { value: "North Carolina", label: "North Carolina" },
    { value: "North Dakota", label: "North Dakota" },
    { value: "Ohio", label: "Ohio" },
    { value: "Oklahoma", label: "Oklahoma" },
    { value: "Oregon", label: "Oregon" },
    { value: "Pennsylvania", label: "Pennsylvania" },
    { value: "Rhode Island", label: "Rhode Island" },
    { value: "South Carolina", label: "South Carolina" },
    { value: "South Dakota", label: "South Dakota" },
    { value: "Tennessee", label: "Tennessee" },
    { value: "Texas", label: "Texas" },
    { value: "Utah", label: "Utah" },
    { value: "Vermont", label: "Vermont" },
    { value: "Virginia", label: "Virginia" },
    { value: "Washington", label: "Washington" },
    { value: "West Virginia", label: "West Virginia" },
    { value: "Wisconsin", label: "Wisconsin" },
    { value: "Wyoming", label: "Wyoming" },
    { value: "District of Columbia", label: "District of Columbia" },
];



export const staticCreditMemoLoanType = [
    { Id: "", Identifier: "CreditMemoLoanType", value: 0, label: "Select..." },
    { Id: "105", Identifier: "CreditMemoLoanType", value: 1, label: "Conventional" },
    { Id: "106", Identifier: "CreditMemoLoanType", value: 2, label: "SBA Express" },
    { Id: "107", Identifier: "CreditMemoLoanType", value: 3, label: "SBA 7(a)" },
    { Id: "108", Identifier: "CreditMemoLoanType", value: 4, label: "SBA 504" },
    { Id: "109", Identifier: "CreditMemoLoanType", value: 5, label: "SBA B&I" }
]

export const staticCreditMemoPaymentType = [
    { Id: "", Identifier: "CreditMemoPaymentType", value: 0, label: "Select..." },
    { Id: "115", Identifier: "CreditMemoPaymentType", value: 1, label: "Principal & interest" },
    { Id: "116", Identifier: "CreditMemoPaymentType", value: 2, label: "Interest only" },
    { Id: "117", Identifier: "CreditMemoPaymentType", value: 3, label: "Interest only, then principal & interest " },
]

export const staticCreditMemoStrengthWeak = [
    { Id: "", Identifier: "CreditMemoStrengthWeak", value: 0, label: "Select..." },
    { Id: "118", Identifier: "CreditMemoStrengthWeak", value: 1, label: "Collateral" },
    { Id: "119", Identifier: "CreditMemoStrengthWeak", value: 2, label: "Industry" },
    { Id: "120", Identifier: "CreditMemoStrengthWeak", value: 3, label: "Experience" },
    { Id: "121", Identifier: "CreditMemoStrengthWeak", value: 4, label: "Repayment source" },
    { Id: "122", Identifier: "CreditMemoStrengthWeak", value: 5, label: "Cash flow" },
    { Id: "123", Identifier: "CreditMemoStrengthWeak", value: 6, label: "Others" }
]

export const staticCreditMemoCollateralType = [
    { Id: "", Identifier: "CreditMemoCollateralType", value: 0, label: "Select..." },
    { Id: "124", Identifier: "CreditMemoCollateralType", value: 1, label: "Real estate - commercial" },
    { Id: "125", Identifier: "CreditMemoCollateralType", value: 2, label: "Real estate - residential" },
    { Id: "126", Identifier: "CreditMemoCollateralType", value: 3, label: "Equipment" },
    { Id: "127", Identifier: "CreditMemoCollateralType", value: 4, label: "Financial instruments" },
    { Id: "128", Identifier: "CreditMemoCollateralType", value: 5, label: "Others" }
]

export const staticCreditMemoBenchmarkindex = [
    { Id: "", Identifier: "CreditMemoBenchmarkindex", value: 0, label: "Select..." },
    { Id: "129", Identifier: "CreditMemoBenchmarkindex", value: 1, label: "3 Year Swap" },
    { Id: "130", Identifier: "CreditMemoBenchmarkindex", value: 2, label: "5 Year Swap" },
    { Id: "131", Identifier: "CreditMemoBenchmarkindex", value: 3, label: "7 Year Swap" },
    { Id: "132", Identifier: "CreditMemoBenchmarkindex", value: 4, label: "10 Year Swap" },
    { Id: "133", Identifier: "CreditMemoBenchmarkindex", value: 5, label: "WSJ Prime" },
    { Id: "134", Identifier: "CreditMemoBenchmarkindex", value: 6, label: "SOFR" },
    { Id: "135", Identifier: "CreditMemoBenchmarkindex", value: 7, label: "Other" },
]

export const staticLongMonthOptions = [
    { Identifier: "Month", value: "", label: "Select..." },
    { Identifier: "Month", value: "January", label: "January" },
    { Identifier: "Month", value: "February", label: "February" },
    { Identifier: "Month", value: "March", label: "March" },
    { Identifier: "Month", value: "April", label: "April" },
    { Identifier: "Month", value: "May", label: "May" },
    { Identifier: "Month", value: "June", label: "June" },
    { Identifier: "Month", value: "July", label: "July" },
    { Identifier: "Month", value: "August", label: "August" },
    { Identifier: "Month", value: "September", label: "September" },
    { Identifier: "Month", value: "October", label: "October" },
    { Identifier: "Month", value: "November", label: "November" },
    { Identifier: "Month", value: "December", label: "December" }
]

export const staticCreditMemoAgent = [
    { Id: "", Identifier: "CreditMemoAgent", value: "0", label: "Select..." },
    { Id: "136", Identifier: "CreditMemoAgent", value: "1", label: "Equifax" },
    { Id: "137", Identifier: "CreditMemoAgent", value: "2", label: "Experian" },
    { Id: "138", Identifier: "CreditMemoAgent", value: "3", label: "Transunion" }
]

export const staticCountryList = [
    { label: "United States", value: "United States" },
    { label: "Afghanistan", value: "Afghanistan" },
    { label: "Albania", value: "Albania" },
    { label: "Algeria", value: "Algeria" },
    { label: "Andorra", value: "Andorra" },
    { label: "Angola", value: "Angola" },
    { label: "Anguilla", value: "Anguilla" },
    { label: "Antigua and Barbuda", value: "Antigua and Barbuda" },
    { label: "Argentina", value: "Argentina" },
    { label: "Armenia", value: "Armenia" },
    { label: "Aruba", value: "Aruba" },
    { label: "Ascension", value: "Ascension" },
    { label: "Australia", value: "Australia" },
    { label: "Austria", value: "Austria" },
    { label: "Azerbaijan", value: "Azerbaijan" },
    { label: "Bahamas", value: "Bahamas" },
    { label: "Bahrain", value: "Bahrain" },
    { label: "Bangladesh", value: "Bangladesh" },
    { label: "Barbados", value: "Barbados" },
    { label: "Belarus", value: "Belarus" },
    { label: "Belgium", value: "Belgium" },
    { label: "Belize", value: "Belize" },
    { label: "Benin", value: "Benin" },
    { label: "Bermuda", value: "Bermuda" },
    { label: "Bhutan", value: "Bhutan" },
    { label: "Bolivia", value: "Bolivia" },
    { label: "Bosnia-Herzegovina", value: "Bosnia-Herzegovina" },
    { label: "Botswana", value: "Botswana" },
    { label: "Brazil", value: "Brazil" },
    { label: "British Virgin Islands", value: "British Virgin Islands" },
    { label: "Brunei Darussalam", value: "Brunei Darussalam" },
    { label: "Bulgaria", value: "Bulgaria" },
    { label: "Burkina Faso", value: "Burkina Faso" },
    { label: "Burma", value: "Burma" },
    { label: "Burundi", value: "Burundi" },
    { label: "Cambodia", value: "Cambodia" },
    { label: "Cameroon", value: "Cameroon" },
    { label: "Canada", value: "Canada" },
    { label: "Cape Verde", value: "Cape Verde" },
    { label: "Cayman Islands", value: "Cayman Islands" },
    { label: "Central African Republic", value: "Central African Republic" },
    { label: "Chad", value: "Chad" },
    { label: "Chile", value: "Chile" },
    { label: "China", value: "China" },
    { label: "Colombia", value: "Colombia" },
    { label: "Comoros", value: "Comoros" },
    { label: "Costa Rica", value: "Costa Rica" },
    { label: "Cote d'Ivoire", value: "Cote d'Ivoire" },
    { label: "Croatia", value: "Croatia" },
    { label: "Cuba", value: "Cuba" },
    { label: "Curacao", value: "Curacao" },
    { label: "Cyprus", value: "Cyprus" },
    { label: "Czech Republic", value: "Czech Republic" },
    { label: "Democratic Republic of the Congo", value: "Democratic Republic of the Congo" },
    { label: "Denmark", value: "Denmark" },
    { label: "Djibouti", value: "Djibouti" },
    { label: "Dominica", value: "Dominica" },
    { label: "Dominican Republic", value: "Dominican Republic" },
    { label: "Ecuador", value: "Ecuador" },
    { label: "Egypt", value: "Egypt" },
    { label: "El Salvador", value: "El Salvador" },
    { label: "Equatorial Guinea", value: "Equatorial Guinea" },
    { label: "Eritrea", value: "Eritrea" },
    { label: "Estonia", value: "Estonia" },
    { label: "Eswatini", value: "Eswatini" },
    { label: "Ethiopia", value: "Ethiopia" },
    { label: "Falkland Islands", value: "Falkland Islands" },
    { label: "Faroe Islands", value: "Faroe Islands" },
    { label: "Fiji", value: "Fiji" },
    { label: "Finland", value: "Finland" },
    { label: "France", value: "France" },
    { label: "French Guiana", value: "French Guiana" },
    { label: "French Polynesia", value: "French Polynesia" },
    { label: "Gabon", value: "Gabon" },
    { label: "Gambia", value: "Gambia" },
    { label: "Georgia", value: "Georgia" },
    { label: "Germany", value: "Germany" },
    { label: "Ghana", value: "Ghana" },
    { label: "Gibraltar", value: "Gibraltar" },
    { label: "Greece", value: "Greece" },
    { label: "Greenland", value: "Greenland" },
    { label: "Grenada", value: "Grenada" },
    { label: "Guadeloupe", value: "Guadeloupe" },
    { label: "Guatemala", value: "Guatemala" },
    { label: "Guinea", value: "Guinea" },
    { label: "Guinea -Bissau", value: "Guinea -Bissau" },
    { label: "Guyana", value: "Guyana" },
    { label: "Haiti", value: "Haiti" },
    { label: "Honduras", value: "Honduras" },
    { label: "Hong Kong", value: "Hong Kong" },
    { label: "Hungary", value: "Hungary" },
    { label: "Iceland", value: "Iceland" },
    { label: "India", value: "India" },
    { label: "Indonesia", value: "Indonesia" },
    { label: "Iran", value: "Iran" },
    { label: "Iraq", value: "Iraq" },
    { label: "Ireland", value: "Ireland" },
    { label: "Israel", value: "Israel" },
    { label: "Italy", value: "Italy" },
    { label: "Jamaica", value: "Jamaica" },
    { label: "Japan", value: "Japan" },
    { label: "Jordan", value: "Jordan" },
    { label: "Kazakhstan", value: "Kazakhstan" },
    { label: "Kenya", value: "Kenya" },
    { label: "Kiribati", value: "Kiribati" },
    { label: "Kosovo", value: "Kosovo" },
    { label: "Kuwait", value: "Kuwait" },
    { label: "Kyrgyzstan", value: "Kyrgyzstan" },
    { label: "Laos", value: "Laos" },
    { label: "Latvia", value: "Latvia" },
    { label: "Lebanon", value: "Lebanon" },
    { label: "Lesotho", value: "Lesotho" },
    { label: "Liberia", value: "Liberia" },
    { label: "Libya", value: "Libya" },
    { label: "Liechtenstein", value: "Liechtenstein" },
    { label: "Lithuania", value: "Lithuania" },
    { label: "Luxembourg", value: "Luxembourg" },
    { label: "Macao", value: "Macao" },
    { label: "Madagascar", value: "Madagascar" },
    { label: "Malawi", value: "Malawi" },
    { label: "Malaysia", value: "Malaysia" },
    { label: "Maldives", value: "Maldives" },
    { label: "Mali", value: "Mali" },
    { label: "Malta", value: "Malta" },
    { label: "Martinique", value: "Martinique" },
    { label: "Mauritania", value: "Mauritania" },
    { label: "Mauritius", value: "Mauritius" },
    { label: "Mexico", value: "Mexico" },
    { label: "Moldova", value: "Moldova" },
    { label: "Mongolia", value: "Mongolia" },
    { label: "Montenegro", value: "Montenegro" },
    { label: "Montserrat", value: "Montserrat" },
    { label: "Morocco", value: "Morocco" },
    { label: "Mozambique", value: "Mozambique" },
    { label: "Namibia", value: "Namibia" },
    { label: "Nauru", value: "Nauru" },
    { label: "Nepal", value: "Nepal" },
    { label: "Netherlands", value: "Netherlands" },
    { label: "New Caledonia", value: "New Caledonia" },
    { label: "New Zealand", value: "New Zealand" },
    { label: "Nicaragua", value: "Nicaragua" },
    { label: "Niger", value: "Niger" },
    { label: "Nigeria", value: "Nigeria" },
    { label: "North Korea", value: "North Korea" },
    { label: "North Macedonia", value: "North Macedonia" },
    { label: "Norway", value: "Norway" },
    { label: "Oman", value: "Oman" },
    { label: "Pakistan", value: "Pakistan" },
    { label: "Panama", value: "Panama" },
    { label: "Papua New Guinea", value: "Papua New Guinea" },
    { label: "Paraguay", value: "Paraguay" },
    { label: "Peru", value: "Peru" },
    { label: "Philippines", value: "Philippines" },
    { label: "Pitcairn Island", value: "Pitcairn Island" },
    { label: "Poland", value: "Poland" },
    { label: "Portugal", value: "Portugal" },
    { label: "Qatar", value: "Qatar" },
    { label: "Republic of   the Congo", value: "Republic of   the Congo" },
    { label: "Reunion", value: "Reunion" },
    { label: "Romania", value: "Romania" },
    { label: "Russia", value: "Russia" },
    { label: "Rwanda", value: "Rwanda" },
    { label: "Saint Helena", value: "Saint Helena" },
    { label: "Saint Kitts  and Nevis", value: "Saint Kitts  and Nevis" },
    { label: "Saint Lucia", value: "Saint Lucia" },
    { label: "Saint Pierre", value: "Saint Pierre" },
    { label: "Saint Vincent and Miquelon", value: "Saint Vincent and Miquelon" },
    { label: "Samoa and the Grenadines", value: "Samoa and the Grenadines" },
    { label: "San Marino", value: "San Marino" },
    { label: "Sao Tome and Principe", value: "Sao Tome and Principe" },
    { label: "Saudi Arabia", value: "Saudi Arabia" },
    { label: "Senegal", value: "Senegal" },
    { label: "Serbia", value: "Serbia" },
    { label: "Seychelles", value: "Seychelles" },
    { label: "Sierra Leone", value: "Sierra Leone" },
    { label: "Singapore", value: "Singapore" },
    { label: "Eustatius, and Saba Bonaire", value: "Eustatius, and Saba Bonaire" },
    { label: "Sint Maarten", value: "Sint Maarten" },
    { label: "Slovakia", value: "Slovakia" },
    { label: "Slovenia", value: "Slovenia" },
    { label: "Solomon Islands", value: "Solomon Islands" },
    { label: "Somalia", value: "Somalia" },
    { label: "South Africa", value: "South Africa" },
    { label: "South Korea", value: "South Korea" },
    { label: "South Sudan", value: "South Sudan" },
    { label: "Spain", value: "Spain" },
    { label: "Sri Lanka", value: "Sri Lanka" },
    { label: "Sudan", value: "Sudan" },
    { label: "Suriname", value: "Suriname" },
    { label: "Sweden", value: "Sweden" },
    { label: "Switzerland", value: "Switzerland" },
    { label: "Syria", value: "Syria" },
    { label: "Taiwan", value: "Taiwan" },
    { label: "Tajikistan", value: "Tajikistan" },
    { label: "Tanzania", value: "Tanzania" },
    { label: "Thailand", value: "Thailand" },
    { label: "Timor -Leste", value: "Timor -Leste" },
    { label: "Togo", value: "Togo" },
    { label: "Tonga", value: "Tonga" },
    { label: "Trinidad and Tobago", value: "Trinidad and Tobago" },
    { label: "Tristan da Cunha", value: "Tristan da Cunha" },
    { label: "Tunisia", value: "Tunisia" },
    { label: "Turkey", value: "Turkey" },
    { label: "Turkmenistan", value: "Turkmenistan" },
    { label: "Turks and Caicos Islands", value: "Turks and Caicos Islands" },
    { label: "Tuvalu", value: "Tuvalu" },
    { label: "Uganda", value: "Uganda" },
    { label: "Ukraine", value: "Ukraine" },
    { label: "United Arab Emirates", value: "United Arab Emirates" },
    { label: "United Kingdom", value: "United Kingdom" },
    { label: "Uruguay", value: "Uruguay" },
    { label: "Uzbekistan", value: "Uzbekistan" },
    { label: "Vanuatu", value: "Vanuatu" },
    { label: "Vatican City", value: "Vatican City" },
    { label: "Venezuela", value: "Venezuela" },
    { label: "Vietnam", value: "Vietnam" },
    { label: "Wallis and Futuna Islands", value: "Wallis and Futuna Islands" },
    { label: "Yemen", value: "Yemen" },
    { label: "Zambia", value: "Zambia" },
    { label: "Zimbabwe", value: "Zimbabwe" }
]

export const staticMembersDropdownOptions = [
    { label: "Select...", value: "" },
    { label: "Managing Member", value: "Managing Member" },
    { label: "Managing Member with authority to sign", value: "Managing Member with authority to sign" },
    { label: "Member", value: "Member" },
    { label: "Member with authority to sign", value: "Member with authority to sign" }
];

export const staticTimeZoneMapper = {
    "UTC": "Coordinated Universal Time",
    "+00:00": "Greenwich Mean Time",
    "+01:00": "Central European Time",
    "+02:00": "Eastern European Time",
    "+03:00": "Moscow Standard Time",
    "+03:30": "Iran Standard Time",
    "+04:00": "Gulf Standard Time",
    "+04:30": "Afghanistan Time",
    "+05:00": "Pakistan Standard Time",
    "+05:30": "India Standard Time",
    "+05:45": "Nepal Time",
    "+06:00": "Bangladesh Standard Time",
    "+06:30": "Cocos Islands Time",
    "+07:00": "Indochina Time",
    "+08:00": "China Standard Time",
    "+09:00": "Japan Standard Time",
    "+09:30": "Australian Central Standard Time",
    "+10:00": "Australian Eastern Standard Time",
    "+11:00": "Solomon Islands Time",
    "+12:00": "New Zealand Standard Time",
    "-01:00": "Cape Verde Time",
    "-02:00": "Fernando de Noronha Time",
    "-03:00": "Argentina Standard Time",
    "-03:30": "Newfoundland Standard Time",
    "-04:00": "Atlantic Standard Time",
    "-05:00": "Eastern Standard Time",
    "-06:00": "Central Standard Time",
    "-07:00": "Mountain Standard Time",
    "-08:00": "Pacific Standard Time",
    "-09:00": "Alaska Standard Time",
    "-10:00": "Hawaii-Aleutian Standard Time",
    "-11:00": "Samoa Standard Time",
    "-12:00": "Baker Island Time"
};
