/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal'
import { Link, useLocation, useParams } from "react-router-dom";
import { SidePanel, ProfileHeader } from "@components";
import Preloader from '../../plugins/Preloader';
import edit_pen from '../../assets/icons/edit-pen.svg';
import email_icon from '../../assets/icons/email_icon.svg';
import axiosInstance from '../../helpers/axiosConfig';
import { decryptText, encryptText } from '../../helpers/DynamicFunctions';
import Constants from '../../helpers/Constants';
import reducersAndActions from '../../_redux/slices';
import { useDispatch, useSelector } from 'react-redux';
import DynamicTable from '../../plugins/DynamicTable';
import { personalProfileHeaderList } from '../../helpers/DropdownsAndMappers';
import dummy_avtar_removebg from "../../assets/img/dummy_avtar-removebg.png";

function PersonalProfile() {
  const dispatch = useDispatch()
  const userRole = useSelector((state) => state.auth.user.roleName);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    dispatch(reducersAndActions.actions.sessionActions.setLoading(loading));
  }, [loading]);

  const [personalProfileData, setPersonalProfileData] = useState([])
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    document.title = "Personal Profile - Loan Application";
    getInquiries();
  }, []);

  const [sentEmailPopupShow, setSentEmailPopup] = useState(false);
  const [loanIddata, setLoanIddata] = useState()
  const [emailSenderData, setEmailSenderData] = useState({})
  const sentEmailPopupClose = () => setSentEmailPopup(false);

  const Emailsender = async () => {
    try {
      setLoading(true);
      let oid = decryptText(params.loanId);
      loanId = userdetails ? userdetails.ApplicationNumber.slice(-4) : oid

      const storedSessionId = localStorage.getItem('sessionId');
      let WebURL = `/personal-profile/${encryptText(`${loanIddata}&${emailSenderData.ID}&${emailSenderData?.ApplicationNumber}&Guest`)}`

      const apiUrl = '/api/LoanApplication/SendOwnerEmailonPersonalResume';
      const queryParams = `?LoanId=${loanIddata}&SessionId=${storedSessionId}&OwnerId=${emailSenderData.ID}&Email=${emailSenderData.Email}&WebURL=${WebURL}`;

      let response = await axiosInstance.get(`${apiUrl}${queryParams}`)

      setLoading(false);
      if (response.status === 200) {
        setEmailSenderData({})
        dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.data.message, type: "success" }));
        setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
      } else {
        dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.message, type: 'error' }));
        setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
      }
    } catch (err) {
      console.log(err);
      dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: err.message, type: 'error' }));
      setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
    }
  }
  const sentEmailPopupConfirm = () => {
    Emailsender()
    setSentEmailPopup(false)
  }

  const location = useLocation()
  const params = useParams()
  var userdetails = location.state?.data

  var loanId;
  const getInquiries = async () => {
    try {
      let oid = decryptText(params.loanId);
      if (userdetails) {
        loanId = userdetails.data ? userdetails.data.loanId : userdetails.ApplicationNumber.slice(-4)
      } else {
        loanId = oid;
      }

      setLoanIddata(loanId)
      const storedSessionId = localStorage.getItem('sessionId');
      setLoading(true);

      const apiUrl = '/api/LoanApplication/GetOwnersListofPersonalResumeById';
      const queryParams = `?LoanId=${loanId}&SessionId=${storedSessionId}`;

      let response = await axiosInstance.get(`${apiUrl}${queryParams}`)
      let data = response.data.resultObject ? JSON.parse(response.data.resultObject) : [];

      setLoading(false)
      setPersonalProfileData(data)
    } catch (err) {
      setLoading(false)
      console.log(err);
      dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: err.message, type: 'error' }));
      setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
    }
  }

  const dynamicTableDisplay = (data) => {
    return (
      <tr key={data.Email}>
        <td>{data.Name} {data.LastName}</td>
        <td>{data.Title}</td>
        <td>{data.Email}</td>
        <td>{data.Ownership}</td>
        <td className="pr-4">
          {
            data.IsProfilePrivacy === "False" || (userRole !== "Borrower" && userRole !== "BusinessPartnerOwner") ? (
              <>
                <Link title="Send Email" href="#" onClick={() => {
                  setSentEmailPopup(true);
                  setEmailSenderData(data);
                }}><img className="lm-grid-edit-icon mr-2" src={email_icon} alt="" /></Link>
                <Link to={`/personal-profile/${encryptText(`${loanIddata}&${data.ID}&${data.ApplicationNumber}&User`)}`} title="Edit"><img className="lm-grid-edit-icon" src={edit_pen} alt="" /></Link>
              </>
            ) : (
              <span className='ml-3'> - </span>
            )
          }
        </td>
      </tr>
    );
  }

  return (
    <>
      {/* SidePanel */}
      <SidePanel />

      {/* ProfileHeader */}
      <ProfileHeader />

      {loading ? (<Preloader />) : (<div className='pb-5'>

        {/* <!-- owner form card --> */}
        <div className="lm-card p-0">
          <div className="d-flex justify-content-between p-3">
            <h3 className="lm-card-heading mb-0">Owners</h3>
          </div>
        </div>
        {/* <!-- owner form card --> */}

        {
          personalProfileData.length > 0 ? (
            <DynamicTable
              data={personalProfileData}
              dynamicTableDisplay={dynamicTableDisplay}
              columns={personalProfileHeaderList}
              changePageSize={setPageSize}
              pageSize={pageSize}
              total={personalProfileData.length}
              setPageIndex={setPageIndex}
              pageIndex={pageIndex}
              setList={setPersonalProfileData}
              hideRowsPerPage={true}
              hidePagination={true}
            />
          ) : (
            <div className="flex flex-col items-center justify-end h-full mt-5 text-center">
              <img src={dummy_avtar_removebg} alt="" className="mb-4" width="100px" />
              <h4 className="font-bold mb-2 ml-2">You're all caught up!</h4>
            </div>
          )
        }

        {/* <!--sent an email Popup / Modal --> */}
        <Modal
          className='mark-comp-popup-cust-size'
          show={sentEmailPopupShow}
          onHide={() => setSentEmailPopup(false)}
          aria-labelledby="sentEmailPopupLabel"
          backdrop="static"
          keyboard={false}
          centered
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-body signout-modal">
                <h3 className="pt-4">
                  Are you sure you want to sent an email?
                </h3>
              </div>
              <div className="modal-footer la-app-modal-footer">
                <button type="button" className="lm-button2" onClick={sentEmailPopupClose}>Cancel</button>
                <button type="button" className="lm-button1" onClick={sentEmailPopupConfirm}>Confirm</button>
              </div>
            </div>
          </div>
        </Modal>
        {/* <!--sent an email Popup / Modal --> */}

      </div>)}

    </>
  );
}

export default PersonalProfile;