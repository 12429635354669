/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import Delete from '../../../assets/img/delete.svg';
import { useDispatch, useSelector } from 'react-redux';
import reducersAndActions from '../../../_redux/slices';
import axiosInstance from '../../../helpers/axiosConfig';
import Constants from '../../../helpers/Constants';
import Preloader from '../../../plugins/Preloader';
import EditIcon from '../../../assets/icons/edit-pen.svg';
import { contactNumberValidate, emailValidator, FormatPhoneNumber, validStringValidator } from '../../../helpers/DynamicFunctions';
import { FieldGroup, FormBuilder, Validators } from 'react-reactive-form';
import FormField from '../../../components/FormField/FormField';
import ContactNumberField from '../../../components/ContactField/ContactField';
import imageCompression from 'browser-image-compression';

let EditProfileFormGroup = FormBuilder.group({
    firstName: ["", [Validators.required, validStringValidator]],
    lastName: ["", [Validators.required, validStringValidator]],
    company: ["", [Validators.required, validStringValidator]],
    email: ["", [Validators.required, emailValidator]],
    officeNumber: ["", [Validators.required, contactNumberValidate]],
    mobileNumber: ["", [Validators.required, contactNumberValidate]],
    isProfilePrivacy: [""],
    isTFAChecked: [""],
    ispasswordExpired: [""],
    docuSignIs: [""],
    agdemailDoc: [""],
    isTransactionAssigned: [""]
});

let profileImage = ""

const EditProfile = ({ activeTab }) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);

    // State for managing profile data and preloader
    const [preloader, setPreloader] = useState(true)
    const [formData, setFormData] = useState({ avatarImage: "" });

    useEffect(() => {
        profileImage = formData.avatarImage;
    }, [formData])

    useEffect(() => {
        const apiUrl = '/api/User/MyProfile';
        const queryParams = `?userId=${user.id}&SessionId=${localStorage.getItem('sessionId')}`;

        axiosInstance.get(`${apiUrl}${queryParams}`)
            .then(response => {
                setPreloader(false);
                const data = response?.data.resultObject;

                // Store only essential data in localStorage
                const essentialData = {
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email,
                };

                localStorage.setItem("profileData", JSON.stringify(essentialData));
                setFormData((prev) => ({ ...prev, avatarImage: data.imagePath === null ? "" : `data:image/png;base64,${data.imagePath}` }));

                EditProfileFormGroup.patchValue({
                    firstName: data.firstName || '',
                    lastName: data.lastName || '',
                    company: data.companyEntity || '',
                    email: data.email || '',
                    officeNumber: FormatPhoneNumber(data.officeNumber.replace(/\D/g, '')) || '',
                    mobileNumber: FormatPhoneNumber(data.mobileNumber.replace(/\D/g, '')) || '',

                    isProfilePrivacy: data.isProfilePrivacy || false,
                    isTFAChecked: data.isTFAChecked || false,
                    IspasswordExpired: data.IspasswordExpired || false,
                    docuSignIs: data.docuSignIs || false,
                    agdemailDoc: data.agdemailDoc || false,
                    isTransactionAssigned: data.isTransactionAssigned || false
                })
            })
            .catch(error => {
                setPreloader(false);
                console.error('Error fetching profile data:', error);
            });
    }, []);

    // Handle image change
    const handleImageChange = async (event) => {
        try {
            const file = event.target?.files?.[0];
            const maxSize = 5 * 1024 * 1024;
            const allowedTypes = ['image/jpeg', 'image/png'];

            if (!file) return console.log('No file selected');
            if (!allowedTypes.includes(file.type)) return alert('Please select a valid image file (JPEG or PNG).');
            if (file.size > maxSize) return alert('File size exceeds 5MB. Please choose a smaller file.');

            const options = {
                maxSizeMB: 0.5,         // Reduce max size to 0.5MB
                maxWidthOrHeight: 600,  // Reduce max dimensions to 600px
                useWebWorker: true,     // Keep WebWorker for performance
                initialQuality: 0.6     // Adjust image quality (lower = smaller size)
            };

            const compressedFile = await imageCompression(file, options);

            const reader = new FileReader();
            reader.readAsDataURL(compressedFile);
            reader.onloadend = () => {
                const newFormData = reader.result;
                setFormData((prev) => ({ ...prev, avatarImage: newFormData }));
            };

            event.target.value = null;
        } catch (error) {
            console.error("Image compression failed:", error);
        }

    };


    // Handle image removal
    const handleRemoveImage = async () => {
        setPreloader(true)
        setFormData((prev) => ({ ...prev, avatarImage: "" }));
        const fileInput = document.getElementById('fileInput');
        if (fileInput) {
            fileInput.value = null;
        }
        localStorage.removeItem('avatarImage');
        setPreloader(false)
    };

    function markAllAsTouched(formGroup) {
        // Focus on specific refs if they exist
        Object.keys(formGroup.controls).forEach((controlName) => {
            const control = formGroup.get(controlName);
            if (control && control.status === "INVALID") {
                control.active = true;
                control.touched = true;

                if (document.getElementById(controlName)) {
                    document.getElementById(controlName).focus();
                }
            }
        });
    }



    // Handle form submission
    const handleSubmit = async () => {
        try {
            if (EditProfileFormGroup.status !== "INVALID") {
                setPreloader(true)
                const apiUrl = '/api/User/MyProfile';
                const formDataToSend = new FormData();

                formDataToSend.append('Id', user.id);
                formDataToSend.append('SessionId', localStorage.getItem('sessionId'));

                formDataToSend.append('Email', EditProfileFormGroup.value.email);
                formDataToSend.append('FirstName', EditProfileFormGroup.value.firstName);
                formDataToSend.append('LastName', EditProfileFormGroup.value.lastName);
                formDataToSend.append('CompanyEntity', EditProfileFormGroup.value.company);
                formDataToSend.append('OfficeNumber', EditProfileFormGroup.value.officeNumber);
                formDataToSend.append('MobileNumber', EditProfileFormGroup.value.mobileNumber);

                formDataToSend.append('IsProfilePrivacy', EditProfileFormGroup.value.isProfilePrivacy || false);
                formDataToSend.append('IsTFAChecked', EditProfileFormGroup.value.isTFAChecked || false);
                formDataToSend.append('IspasswordExpired', EditProfileFormGroup.value.ispasswordExpired || false);

                formDataToSend.append('DocuSignIs', EditProfileFormGroup.value.docuSignIs || false);
                formDataToSend.append('AgdemailDoc', EditProfileFormGroup.value.agdemailDoc || false);
                formDataToSend.append('IsTransactionAssigned', EditProfileFormGroup.value.isTransactionAssigned || false);

                if (profileImage) {
                    const byteString = atob(profileImage.split(',')[1]);
                    const mimeString = profileImage.split(',')[0].split(':')[1].split(';')[0];
                    const ab = new ArrayBuffer(byteString.length);
                    const ia = new Uint8Array(ab);
                    for (let i = 0; i < byteString.length; i++) {
                        ia[i] = byteString.charCodeAt(i);
                    }
                    const blob = new Blob([ab], { type: mimeString });
                    formDataToSend.append('postedFile', blob, 'avatar.png');
                } else {
                    formDataToSend.append('postedFile', '');
                }

                const queryParams = `?userId=${user.id}&SessionId=${localStorage.getItem('sessionId')}`;
                const response = await axiosInstance.post(`${apiUrl}${queryParams}`, formDataToSend, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                setPreloader(false)
                if (response.data.status === 200) {
                    dispatch(reducersAndActions.actions.profileAction.refreshData({ ...EditProfileFormGroup.value, imagePath: profileImage }));
                    dispatch(reducersAndActions.actions.profileAction.isProfileUpdated({}));
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.data.message }));
                    setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                } else {
                    setPreloader(false)
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.data.message, type: "error" }));
                    setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                }
            } else {
                markAllAsTouched(EditProfileFormGroup)
            }
        } catch (error) {
            setPreloader(false)
            console.error('Error updating profile data:', error);
        }
    };

    const firstName = EditProfileFormGroup.value?.firstName ?? "";
    const firstNameFirstword = firstName.charAt(0).toUpperCase();
    const lastName = EditProfileFormGroup.value?.lastName ?? "";
    const lastNameFirstword = lastName.charAt(0).toUpperCase();

    const handleIconClick = () => {
        const isInsideWebView = window.ReactNativeWebView !== undefined;
        if (isInsideWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({ action: "openImageIcon" }));
        }
        document.getElementById('profileImageInput').click();
    };

    return (
        <div className="tab-content" id="laTabContent">
            {
                preloader ?
                    <Preloader />
                    :
                    <div className={`tab-pane fade ${activeTab === "Edit_Profile" ? "show active" : ""}`} id="Edit_Profile">
                        <div className="lm-card edit-profile-box">
                            <h3 className="lm-card-heading">Edit Profile</h3>
                            <div className="setting-profile-sec">
                                <div className="d-flex">
                                    <div className="profile-img">
                                        {
                                            formData.avatarImage !== "" ? (
                                                <img src={formData.avatarImage} alt="Profile" />
                                            ) : (
                                                <div className="profile-text-img">
                                                    <h2>{firstNameFirstword} {lastNameFirstword}</h2>
                                                </div>
                                            )
                                        }
                                        <input
                                            type="file"
                                            id="profileImageInput"
                                            style={{ display: 'none' }}
                                            onChange={handleImageChange}
                                            accept="image/*"
                                        />
                                        {
                                            formData.avatarImage && (
                                                <button className="delete-btn" onClick={handleRemoveImage}>
                                                    <img className="delete" src={Delete} alt="Delete" />
                                                </button>
                                            )
                                        }
                                        <button className="edit-btn" onClick={handleIconClick}>
                                            <img className="edit" src={EditIcon} alt="" />
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <FieldGroup
                                control={EditProfileFormGroup}
                                render={({ get, invalid }) => (
                                    <form>
                                        <div className="row mt-4">
                                            <div className="col-md-4 col-lg-3">
                                                <div className="form-group">
                                                    <FormField
                                                        label="First Name"
                                                        name="firstName"
                                                        placeholder="First Name"
                                                        id="firstName"
                                                        required={true}
                                                        onlyCharacters={true}
                                                        errorMessage="First name is required"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-lg-3">
                                                <div className="form-group">
                                                    <FormField
                                                        label="Last Name"
                                                        name="lastName"
                                                        placeholder="Last Name"
                                                        id="lastName"
                                                        required={true}
                                                        onlyCharacters={true}
                                                        errorMessage="Last name is required"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-lg-3">
                                                <div className="form-group">
                                                    <FormField
                                                        label="Company / Entity"
                                                        name="company"
                                                        placeholder="Company / Entity"
                                                        id="company"
                                                        required={true}
                                                        errorMessage="Company name is required"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-lg-3">
                                                <div className="form-group">
                                                    <FormField
                                                        label="Email"
                                                        name="email"
                                                        placeholder="Email"
                                                        id="email"
                                                        disabled={true}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4 col-lg-3">
                                                <ContactNumberField
                                                    label="Office Number"
                                                    name="officeNumber"
                                                    placeholder="(877) 847-1134"
                                                    id="officeNumber"
                                                    required={true}
                                                    errorMessage="Office number is required"
                                                />
                                            </div>
                                            <div className="col-md-4 col-lg-3">
                                                <ContactNumberField
                                                    label="Mobile Number"
                                                    name="mobileNumber"
                                                    placeholder="(877) 847-1134"
                                                    id="mobileNumber"
                                                    required={true}
                                                    errorMessage="Mobile number is required"
                                                />
                                            </div>
                                            <div className="col-12">
                                                <button type="button" className="lm-button1" onClick={handleSubmit}>Save</button>
                                            </div>
                                        </div>
                                    </form>
                                )}
                            />
                        </div>
                    </div>
            }
        </div>
    );
};

export default EditProfile;
