/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback } from 'react';
import Preloader from '../plugins/Preloader';
import dummy_avtar from '../assets/img/dummy_avtar.png';
import './styles/Users.css';
import { Link } from "react-router-dom";
import phone_icon_grid from '../assets/icons/phone_icon_grid.svg';
import fax_icon_grid from '../assets/icons/fax_icon_grid.svg';
import copy_code from '../assets/icons/copy_code.svg';
import see_transactions from '../assets/icons/see_transactions.svg';
import { useDispatch } from 'react-redux';
import reducersAndActions from '../_redux/slices';
import Constants from '../helpers/Constants';
import DynamicTable from '../plugins/DynamicTable';
import { PartnersHeader } from '../helpers/DropdownsAndMappers';
import { getAdminAdviserList } from '../services/inquiryServices';
import { SidePanel, ProfileHeader } from "@components";
import CopyLinkComponent from './user/commonFunctions/CopyLinkComponent';
import TransactionsModal from './user/commonFunctions/TransactionsModal';
import convertToIST from './user/commonFunctions/User';
import { debounce } from 'lodash';

function UserPartners() {
    // Meta Title
    useEffect(() => {
        document.title = "Partners";
    }, []);

    // Preloader Start
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(reducersAndActions.actions.sessionActions.setLoading(loading));
    }, [loading]);

    const [totalData, setTotalData] = useState(0);
    const [inquiries, setInquiries] = useState([]);
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchText, setSearchText] = useState("");
    const [modalData, setModalData] = useState()

    const getInquiries = async () => {
        try {
            setLoading(true);
            let queryObj = {
                PageIndex: pageIndex,
                PageSize: pageSize,
                RoleName: "Partner",
                SessionId: localStorage.getItem('sessionId')
            };

            if (searchText) {
                queryObj["SearchText"] = searchText;
            }
            let response = await getAdminAdviserList(queryObj);
            if (response.status === 200 && response.resultObject) {
                setInquiries(response.resultObject);
                let total = response.message.split(" ");
                setTotalData(Number(total[1]));
                setLoading(false);
            } else {
                dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.message, type: "error" }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                setLoading(false);
                setTotalData(0);
                setInquiries([]);
            }
        } catch (err) {
            console.log(err);
            setLoading(false);
            setTotalData(0);
        }
    }

    // Fetch inquiries whenever page size or page index changes
    const debouncedGetLoanApplications = useCallback(debounce(() => {
        setLoading(true);
        getInquiries()
    }, 1000), [pageSize, pageIndex, searchText]);

    useEffect(() => {
        debouncedGetLoanApplications();
        return () => {
            debouncedGetLoanApplications.cancel();
        };
    }, [debouncedGetLoanApplications]);


    // Popup function
    const [transactionsPopupShow, setTransactionsPopup] = useState(false);

    const dynamicTableDisplay = (data) => {
        return (
            <tr className={data.statusDesc === "Active" ? "active" : "inactive"}>
                <td>
                    <Link
                        to={`/users/partners-detail?id=${data.id}`}
                        className="user_name"
                    >{data.firstName || "-"} {data.lastName}</Link>
                    <br></br>
                    <span className="user_email text-light">{data.email}</span>

                </td>
                <td style={{ maxWidth: "300px", overflowX: "auto" }}>{data.companyName}</td>
                <td>
                    <div className="tel_number" style={{ whiteSpace: "nowrap" }}><img src={phone_icon_grid} alt="" /> {data.mobile}</div>
                    <div className="tel_number" style={{ whiteSpace: "nowrap" }}><img src={fax_icon_grid} alt="" />   {data.telephone}</div>
                </td>
                <td>{data.roleName}</td>
                <td>{data.code}</td>
                <td><div className="status">{data.statusDesc}</div></td>
                <td className="text-light">
                    <span>
                        {(data?.lastLoginDateTime || data?.loginedUserCity || data?.loginedUserState || data?.loginedUserCountry)
                            ? (
                                <>
                                    {data?.lastLoginDateTime ? convertToIST(data.lastLoginDateTime) : null}
                                    {data?.lastLoginDateTime && (data?.loginedUserCity || data?.loginedUserState || data?.loginedUserCountry) ? <br /> : null}
                                    {[
                                        data?.loginedUserCity,
                                        data?.loginedUserState,
                                        data?.loginedUserCountry
                                    ]
                                        .filter(Boolean)
                                        .join(', ')}
                                </>
                            )
                            : 'NA'}
                    </span>
                </td>
                <td className="action-col">
                    <div className="options_btn"><span className="option_dots">...</span>
                        <div id="popoverContent" className="app_options">

                            <CopyLinkComponent
                                url={`${Constants.FRONTEND_URL}/signup`}
                                text="Copy Code"
                                imgSrc={copy_code}
                                data={data.code}
                            />
                            <div onClick={() => {
                                setTransactionsPopup(true)
                                if (data) {
                                    setModalData(data)
                                }
                            }}><img className="mr-1" src={see_transactions} alt="" /> See transactions</div>

                        </div>
                    </div>
                </td>

            </tr>
        )
    }

    const handleChangeInput = (e) => {
        setPageIndex(1);
        setSearchText(e.target.value);
    }

    return (
        <>
            {/* SidePanel */}
            <SidePanel />

            {/* ProfileHeader */}
            <ProfileHeader />

            {loading ? (<Preloader />) : (<div>
                <div className="dashboard-wrap users-screen">

                    <div className="d-sm-flex d-block justify-content-between">

                        <h3 className="user-page-heading mt-sm-2 mt-0">Partners</h3>

                        <div className="d-flex justify-content-between search-button-group">
                            <div className="lm-search-bar mr-4">
                                <input
                                    type="text"
                                    id="searchInput"
                                    autoComplete="off"
                                    placeholder="Search by number, name, or other"
                                    value={searchText}
                                    onChange={handleChangeInput}
                                />
                            </div>
                            <Link to="/users/partners-detail" className="lm-button1">Add New Partners</Link>
                        </div>
                    </div>
                    {
                        inquiries.length > 0 ?
                            <DynamicTable data={inquiries} dynamicTableDisplay={dynamicTableDisplay} columns={PartnersHeader} changePageSize={setPageSize} pageSize={pageSize} total={totalData} setList={setInquiries} setPageIndex={setPageIndex} pageIndex={pageIndex} />
                            :
                            (
                                <div className='no-data-card text-center place-content-center'>
                                    <img src={dummy_avtar} alt="" className='mb-4' />
                                    <h3 className='f-700 mb-2 ml-2'>You're all caught up!</h3>
                                </div>
                            )
                    }
                    {/* <!-- adjust colSpan as per columns --> */}

                </div>


                {/* <!--see transitions drawer--> */}
                <TransactionsModal
                    transactionsPopupShow={transactionsPopupShow}
                    setTransactionsPopup={setTransactionsPopup}
                    modalData={modalData}
                />
                {/* <!--see transitions drawer--> */}

            </div>)}

        </>
    );
}

export default UserPartners;