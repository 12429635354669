/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useLocation, Link, useNavigate } from 'react-router-dom'; // Import necessary react-router-dom components
import { useDispatch, useSelector } from 'react-redux';
import reducersAndActions from '../../_redux/slices';
import { toggleMenu } from '../SidePanel/SidePanel'; // Assuming this is imported correctly
import Modal from 'react-bootstrap/Modal';
import './ProfileHeader.css';
import profile_setting_icon from '../../assets/icons/profile-setting-icon.svg';
import signout_icon from '../../assets/icons/signout-icon.svg';
import { logout } from '../../services/commonService';
import { flushLocalStorageData, getLocalStorageData } from '../../services/storageData';


const ProfileHeader = ({ pathname }) => {
  // Popup function
  const user = useSelector((state) => state.auth.user);
  const profileData = useSelector((state) => state.editProfile.data.message);
  const refreshData = useSelector((state) => state.editProfile.profileDetailsrefresh)
  const profileDetails = useSelector((state) => state.editProfile.data);


  const [imageData, setImageData] = useState("");

  const navigate = useNavigate("/");
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (profileDetails.imagePath !== null && profileDetails.imagePath !== "" && profileDetails.imagePath !== "data:image/png;base64,null") {
      setImageData(profileDetails.imagePath)
    } else {
      setImageData("")
    }
    if (refreshData) {
      dispatch(reducersAndActions.actions.profileAction.isProfileUpdated({}));
    }
  }, [refreshData]);

  const [signOutPopupShow, setSignOutPopup] = useState(false);
  const signOutPopupClose = () => setSignOutPopup(false);
  const confirmLogout = () => {
    let email = getLocalStorageData("email");

    email = JSON.parse(email);
    logout(email).then(
      (success) => {
        dispatch(reducersAndActions.actions.authActions.removeUser());
        setSignOutPopup(false);
        flushLocalStorageData()
        dispatch(reducersAndActions.actions.sessionActions.clearSessionTimeout());
        navigate("/");
      },
      (err) => {
        console.log(err);
      }
    ).catch(err => {
      console.log(err);
      throw err;
    });
  }

  const sectionNamesMap = {
    '/dashboard': 'Dashboard',
    '/loan-application': 'Loan Applications',
    '/application-detail': 'Loan Application',
    '/personal-profile': 'Loan Application',
    '/pfs-detail': 'Loan Application',
    '/cash-flow-business': 'Loan Application',
    '/cash-flow-personal': 'Loan Application',
    '/users/advisor-admin': 'Users',
    '/users/borrowers': 'Users',
    '/users/lenders': 'Users',
    '/users/partners': 'Users',
    '/users/user-form': 'Users',
    '/inquiries': 'Inquiries',
    '/logs': 'Logs',
    '/api-logs': 'API Logs',
    '/templates': 'Templates',
    '/settings': 'Settings',
    // Add more mappings as needed
  };

  const sectionName = sectionNamesMap[location.pathname] || 'Loan Mantra'; // Get the section name

  const ProfileComponent = ({ user, imageData }) => {
    const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);
    const renderProfileDisplay = () => {
      if (!user.name) {
        return <div className="profile-text-img mr-2"><h2>LM</h2></div>;
      }

      const nameParts = user.name.split(" ");
      const capitalizedParts = nameParts.map(part => capitalizeFirstLetter(part));

      const firstNameInitial = capitalizedParts[0]?.charAt(0);
      const lastNameInitial = capitalizedParts[1]?.charAt(0);

      const initials = `${firstNameInitial !== undefined ? firstNameInitial : ""}${lastNameInitial !== undefined ? lastNameInitial : ""}`;
      if (imageData) {
        return (
          <img src={imageData} alt={`${initials}`} />
        );
      } else {
        return (
          <div className="profile-text-img mr-2">
            <h2>{initials}</h2>
          </div>
        );
      }

    };

    return (
      <>
        {renderProfileDisplay()}
      </>
    );
  };

  const formateUserRole = (userName) => userName === "BusinessPartnerOwner" ? "Business Partner Owner" : userName;

  return (
    <div>
      <div className="profile-header">
        <div className="hamburger" onClick={toggleMenu}>
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
        </div>
        <div className="section-name">
          <h6>{sectionName}</h6>
        </div>
        <div className="user-dtl">
          <div className="user-pro-pic">
            <ProfileComponent
              user={user}
              profileData={profileData}
              imageData={imageData}
            />
            <div className="dtl-sec">
              <div className="user-name">{user.name}</div>
              <p className="user-designation mb-0">{formateUserRole(user.roleName)}</p>
              <div className="logout-dropdown">
                <Link to="/settings">
                  <img src={profile_setting_icon} alt="Profile Settings" />
                  Profile Settings
                </Link>
                <Link
                  to="#"
                  onClick={() => setSignOutPopup(true)}
                >
                  <img src={signout_icon} alt="Sign Out" />
                  Sign out
                </Link>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div onClick={toggleMenu} className="menu-overlay"></div>

      <Modal
        className="signout-modal"
        show={signOutPopupShow}
        onHide={() => setSignOutPopup(false)}
        aria-labelledby="signOutPopupLabel"
        backdrop="static"
        keyboard={false}
        centered
      >
        <div className="modal-body">
          <h3 className="pt-4">Ready to sign out?</h3>
        </div>
        <div className="modal-footer">
          <button type="button" className="lm-button2" onClick={signOutPopupClose}>Cancel</button>
          <button type="button" className="lm-button1" onClick={confirmLogout}>Confirm</button>
        </div>
      </Modal>
    </div>
  );
};

export default ProfileHeader;
