/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef } from 'react';
import Modal from 'react-bootstrap/Modal'
import { useNavigate } from "react-router-dom";
import Select from 'react-select';
import DatePicker from "react-datepicker";
import { SidePanel, ProfileHeader } from "@components";
import Preloader from '../../plugins/Preloader';
import back_arrow from '../../assets/icons/back_arrow.svg';
import brdcrm_nav from '../../assets/icons/brdcrm_nav.svg';
import close_popup from '../../assets/icons/close_popup.svg';
import info_tooltip from '../../assets/icons/Info-tooltip.svg';
import feild_minus_icon from '../../assets/icons/feild_minus_icon.svg';
import form_note_icon from '../../assets/icons/form-note-icon.svg';
import download_icon_outline from '../../assets/icons/download-icon-outline.svg';
import lm_edit_icon from '../../assets/icons/lm-edit-icon.svg';
import lm_trash_icon from '../../assets/icons/lm-trash-icon.svg';
import { decryptText, encryptText, formatNumberWithCommas } from '../../helpers/DynamicFunctions';
import { GetIncomeStatmentOfBusinessCashflowDetails, GetBalanceSheetOfBusinessCashflowDetails, InsertAndUpdateBusinessCashflow, GetDebtScheduleReport } from '../../services/cashflowServices';
import { getLocalStorageData, getSessionId } from '../../services/storageData';
import { FieldControl, FieldGroup, FormBuilder, Validators } from 'react-reactive-form';
import FormField from '../../components/FormField/FormField';
import moment from 'moment';
import reducersAndActions from '../../_redux/slices';
import Constants from '../../helpers/Constants';
import { useDispatch, useSelector } from 'react-redux';
import { removeAllCheckings } from '../../helpers/PfsScreenFunctions';
import { GetBussinessProfileByLoanId } from '../../services/businessProfileServices';
import AmountContainer from '../../components/Amount/AmountContainer';
import FormRadio from '../../reusablecomponets/FormRadio';
import { handleFileUpload } from "./fileUploadHandler";
import uploadIcon from '../../assets/icons/upload-icon.svg';

const FormArrayComponent = ({ control, render }) => {
    return (
        <div>
            {
                control.controls.map((ctrl, index) => (
                    <div key={index}>{render(ctrl, index)}</div>
                ))
            }
        </div>
    );
};

const mustBeUnique = (control) => {
    const value = control.value;
    const label = bussinessFormGroup.value.lstCashFlow.map(item => item.label);
    if ((cashflowLabelsList.includes(value) && control.active) || value === "Depreciation" || value === "Interest") {
        control.patchValue("");
        alert("This add-back already exists");
        return { shouldNotEmpty: true };
    }
    if (value === "") {
        return { shouldNotEmpty: true };
    }
    if (label.includes(value)) {
        return { mustBeUnique: true };
    }
    return null;
}

// Cashflow Business Details list start
let cashflowList = {
    label: "",
    val1: "",
    val2: "",
    val3: "",
    val4: "",
    val5: "",
}

export const createCashFlowDetailsList = (cashflowDetails = cashflowList) => {
    return FormBuilder.group({
        label: [cashflowDetails.label, [Validators.required, mustBeUnique]],
        val1: [cashflowDetails.val1],
        val2: [cashflowDetails.val2],
        val3: [cashflowDetails.val3],
        val4: [cashflowDetails.val4],
        val5: [cashflowDetails.val5],
    });
}

export const returnCashFlowFormGroupInArray = (cashflowData, formGroup, keyName) => {
    let groupControl = formGroup.get(keyName);
    while (groupControl.length) {
        groupControl.removeAt(0);
    }
    cashflowData.forEach((key) => {
        const newPfs = createCashFlowDetailsList(key);
        groupControl.push(newPfs);
    });
}
// Cashflow Business Details list end

let tempBusinessId
// Cashflow Business Owner lstDebtScheduleInfo start
let cashflowDebtScheduleInfoTemp = {
    id: "",
    custId: "",
    creditorName: "",
    businessName: "",
    creditorAddress: "",
    originalDate: "",
    originalAmount: "",
    presentBalance: "",
    interestRate: "",
    monthlyPayment: "",
    maturityDate: "",
    collateralSecurity: "",
    refi: "",
    isPayByLoan: ""
}

export const createCashFlowDebtScheduleInfo = (cashflowDebtScheduleInfo = cashflowDebtScheduleInfoTemp) => {
    return FormBuilder.group({
        id: [cashflowDebtScheduleInfo.id || 0],
        custId: [cashflowDebtScheduleInfo.custId || tempBusinessId],
        creditorName: [cashflowDebtScheduleInfo.creditorName],
        businessName: [cashflowDebtScheduleInfo.businessName],
        creditorAddress: [cashflowDebtScheduleInfo.creditorAddress],
        originalDate: [cashflowDebtScheduleInfo.originalDate],
        originalAmount: [cashflowDebtScheduleInfo.originalAmount?.toString() || 0],
        presentBalance: [cashflowDebtScheduleInfo.presentBalance?.toString() || 0],
        interestRate: [cashflowDebtScheduleInfo.interestRate || 0],
        monthlyPayment: [cashflowDebtScheduleInfo.monthlyPayment?.toString() || 0],
        maturityDate: [cashflowDebtScheduleInfo.maturityDate],
        collateralSecurity: [cashflowDebtScheduleInfo.collateralSecurity],
        refi: [cashflowDebtScheduleInfo.refi],
        isPayByLoan: [cashflowDebtScheduleInfo.isPayByLoan]
    });
}

export const returnCashFlowDebtScheduleInfoInArray = (cashflowData, formGroup, keyName) => {
    let groupControl = formGroup.get(keyName);
    while (groupControl.length) {
        groupControl.removeAt(0);
    }
    cashflowData.forEach((key) => {
        const newPfs = createCashFlowDebtScheduleInfo(key);
        groupControl.push(newPfs);
    });
}

// Cashflow Business Owner lstDebtScheduleInfo end

// Cashflow Business Owner lstTF start

let cashflowTFTemp = {
    tf1: "",
    tf2: "",
    tf3: "",
    tf4: "",
    tf5: "",
    tf6: ""
}

export const createCashFlowTF = (cashflowTF = cashflowTFTemp) => {
    return FormBuilder.group({
        tf1: [cashflowTF.tf1 || "Tax Returns"],
        tf2: [cashflowTF.tf2 || "Tax Returns"],
        tf3: [cashflowTF.tf3 || "Tax Returns"],
        tf4: [cashflowTF.tf4 || "Tax Returns"],
        tf5: [cashflowTF.tf5 || "Financials"],
        tf6: [cashflowTF.tf6 || "Financials"],
    });
}

export const returnCashFlowTFInArray = (cashflowData, formGroup, keyName) => {
    let groupControl = formGroup.get(keyName);
    while (groupControl.length) {
        groupControl.removeAt(0);
    }
    cashflowData.forEach((key) => {
        const newPfs = createCashFlowTF(key);
        groupControl.push(newPfs);
    });
}
// Cashflow Business Owner lstTF end

// Cashflow Business Owner lstOwner start

let cashflowOwnerTemp = {
    id: "",
    name: "",
    lastName: "",
    email: "",
    title: "",
    ownershipePercentage: "",
    veteran: "",
    gender: "",
    race: "",
    ethnicity: ""
}

export const createCashFlowOwner = (cashflowOwner = cashflowOwnerTemp) => {
    return FormBuilder.group({
        id: [cashflowOwner.id],
        name: [cashflowOwner.name],
        lastName: [cashflowOwner.lastName],
        email: [cashflowOwner.email],
        title: [cashflowOwner.title],
        ownershipePercentage: [cashflowOwner.ownershipePercentage],
        veteran: [cashflowOwner.veteran],
        gender: [cashflowOwner.gender],
        race: [cashflowOwner.race],
        ethnicity: [cashflowOwner.ethnicity]
    });
}

export const returnCashFlowOwnerInArray = (cashflowData, formGroup, keyName) => {
    let groupControl = formGroup.get(keyName);
    while (groupControl.length) {
        groupControl.removeAt(0);
    }
    cashflowData.forEach((key) => {
        const newPfs = createCashFlowOwner(key);
        groupControl.push(newPfs);
    });
}

// Cashflow Business Owner lstOwner end


// Function to create a FormControl for a string
export const createStringControl = (value = '') => {
    return FormBuilder.control(value);
}

// Function to populate a FormArray with strings
export const returnCashFlowYearInArray = (stringArray, formGroup, keyName) => {
    let groupControl = formGroup.get(keyName);
    while (groupControl.length) {
        groupControl.removeAt(0);
    }
    stringArray.forEach((value) => {
        const newControl = createStringControl(value);
        groupControl.push(newControl);
    });
}


const getValue = (value) => {
    if (typeof value === "string") {
        value = value.replace(/,/g, '');
    }
    value = parseFloat(value);
    return isNaN(value) ? 0 : value;
};

let bussinessFormGroup = FormBuilder.group({
    businessAddress: [""],
    businessName: [""],
    businessStatus: [""],
    debt: [""],
    loanId: [""],
    month: [""],
    ddlcashflowyear: [""],
    lstYears: FormBuilder.array([]),
    lstTF: FormBuilder.array([]),
    lstCashFlow: FormBuilder.array([]),
    lstOwner: FormBuilder.array([]),
    lstDebtScheduleInfo: FormBuilder.array([]),
    type: [""],
    lstCommentHistory: [""],
    pi: [""],
    NoDebt: [false]
})

let balanceSheetFormGroup = FormBuilder.group({
    loanId: [""],
    cash0: [""],
    cash1: [""],
    cash2: [""],
    cash3: [""],
    cash4: [""],
    tradeNotesAndAR0: [""],
    tradeNotesAndAR1: [""],
    tradeNotesAndAR2: [""],
    tradeNotesAndAR3: [""],
    tradeNotesAndAR4: [""],
    lessAllowanceForBadDebt0: [""],
    lessAllowanceForBadDebt1: [""],
    lessAllowanceForBadDebt2: [""],
    lessAllowanceForBadDebt3: [""],
    lessAllowanceForBadDebt4: [""],
    inventories0: [""],
    inventories1: [""],
    inventories2: [""],
    inventories3: [""],
    inventories4: [""],
    usGovernmentObligations0: [""],
    usGovernmentObligations1: [""],
    usGovernmentObligations2: [""],
    usGovernmentObligations3: [""],
    usGovernmentObligations4: [""],
    taxExemptSecurities0: [""],
    taxExemptSecurities1: [""],
    taxExemptSecurities2: [""],
    taxExemptSecurities3: [""],
    taxExemptSecurities4: [""],
    otherCurrentAssets0: [""],
    otherCurrentAssets1: [""],
    otherCurrentAssets2: [""],
    otherCurrentAssets3: [""],
    otherCurrentAssets4: [""],
    loansToShareholders0: [""],
    loansToShareholders1: [""],
    loansToShareholders2: [""],
    loansToShareholders3: [""],
    loansToShareholders4: [""],
    mortgageAndRrealEstateLoans0: [""],
    mortgageAndRrealEstateLoans1: [""],
    mortgageAndRrealEstateLoans2: [""],
    mortgageAndRrealEstateLoans3: [""],
    mortgageAndRrealEstateLoans4: [""],
    otherInvestments0: [""],
    otherInvestments1: [""],
    otherInvestments2: [""],
    otherInvestments3: [""],
    otherInvestments4: [""],
    buildingsAndOtherDepreciableAssets0: [""],
    buildingsAndOtherDepreciableAssets1: [""],
    buildingsAndOtherDepreciableAssets2: [""],
    buildingsAndOtherDepreciableAssets3: [""],
    buildingsAndOtherDepreciableAssets4: [""],
    lessAccumulatedDepreciation0: [""],
    lessAccumulatedDepreciation1: [""],
    lessAccumulatedDepreciation2: [""],
    lessAccumulatedDepreciation3: [""],
    lessAccumulatedDepreciation4: [""],
    depletableAssets0: [""],
    depletableAssets1: [""],
    depletableAssets2: [""],
    depletableAssets3: [""],
    depletableAssets4: [""],
    lessAccumulatedDepletion0: [""],
    lessAccumulatedDepletion1: [""],
    lessAccumulatedDepletion2: [""],
    lessAccumulatedDepletion3: [""],
    lessAccumulatedDepletion4: [""],
    land0: [""],
    land1: [""],
    land2: [""],
    land3: [""],
    land4: [""],
    intangibleAssets0: [""],
    intangibleAssets1: [""],
    intangibleAssets2: [""],
    intangibleAssets3: [""],
    intangibleAssets4: [""],
    lessAccumulatedAamortization0: [""],
    lessAccumulatedAamortization1: [""],
    lessAccumulatedAamortization2: [""],
    lessAccumulatedAamortization3: [""],
    lessAccumulatedAamortization4: [""],
    otherAssets0: [""],
    otherAssets1: [""],
    otherAssets2: [""],
    otherAssets3: [""],
    otherAssets4: [""],
    totalAssets0: [""],
    totalAssets1: [""],
    totalAssets2: [""],
    totalAssets3: [""],
    totalAssets4: [""],
    accountsPayable0: [""],
    accountsPayable1: [""],
    accountsPayable2: [""],
    accountsPayable3: [""],
    accountsPayable4: [""],
    mortgagesNotesBondsPayableLessThanYear0: [""],
    mortgagesNotesBondsPayableLessThanYear1: [""],
    mortgagesNotesBondsPayableLessThanYear2: [""],
    mortgagesNotesBondsPayableLessThanYear3: [""],
    mortgagesNotesBondsPayableLessThanYear4: [""],
    otherCurrentLiabilities0: [""],
    otherCurrentLiabilities1: [""],
    otherCurrentLiabilities2: [""],
    otherCurrentLiabilities3: [""],
    otherCurrentLiabilities4: [""],
    allNonrecourseLoans0: [""],
    allNonrecourseLoans1: [""],
    allNonrecourseLoans2: [""],
    allNonrecourseLoans3: [""],
    allNonrecourseLoans4: [""],
    loanFromShareholders0: [""],
    loanFromShareholders1: [""],
    loanFromShareholders2: [""],
    loanFromShareholders3: [""],
    loanFromShareholders4: [""],
    mortgagesNotesBondsPayableMoreThanYear0: [""],
    mortgagesNotesBondsPayableMoreThanYear1: [""],
    mortgagesNotesBondsPayableMoreThanYear2: [""],
    mortgagesNotesBondsPayableMoreThanYear3: [""],
    mortgagesNotesBondsPayableMoreThanYear4: [""],
    otherLiabilities0: [""],
    otherLiabilities1: [""],
    otherLiabilities2: [""],
    otherLiabilities3: [""],
    otherLiabilities4: [""],
    capitalStock0: [""],
    capitalStock1: [""],
    capitalStock2: [""],
    capitalStock3: [""],
    capitalStock4: [""],
    additionalPaidInCapital0: [""],
    additionalPaidInCapital1: [""],
    additionalPaidInCapital2: [""],
    additionalPaidInCapital3: [""],
    additionalPaidInCapital4: [""],
    retainedEarnings0: [""],
    retainedEarnings1: [""],
    retainedEarnings2: [""],
    retainedEarnings3: [""],
    retainedEarnings4: [""],
    adjustmentsToShareholdersEquity0: [""],
    adjustmentsToShareholdersEquity1: [""],
    adjustmentsToShareholdersEquity2: [""],
    adjustmentsToShareholdersEquity3: [""],
    adjustmentsToShareholdersEquity4: [""],
    lessCostOfTreasuryStock0: [""],
    lessCostOfTreasuryStock1: [""],
    lessCostOfTreasuryStock2: [""],
    lessCostOfTreasuryStock3: [""],
    lessCostOfTreasuryStock4: [""],
    totalLiabilitiesShareholdersEquity0: [""],
    totalLiabilitiesShareholdersEquity1: [""],
    totalLiabilitiesShareholdersEquity2: [""],
    totalLiabilitiesShareholdersEquity3: [""],
    totalLiabilitiesShareholdersEquity4: [""],
    loanFromPartners0: [""],
    loanFromPartners1: [""],
    loanFromPartners2: [""],
    loanFromPartners3: [""],
    loanFromPartners4: [""],
    partnersCapitalAccount0: [""],
    partnersCapitalAccount1: [""],
    partnersCapitalAccount2: [""],
    partnersCapitalAccount3: [""],
    partnersCapitalAccount4: [""],
    totalLiabilitiesCapital0: [""],
    totalLiabilitiesCapital1: [""],
    totalLiabilitiesCapital2: [""],
    totalLiabilitiesCapital3: [""],
    totalLiabilitiesCapital4: [""],
    month: [""],
    updatedAt: [""]
})

const debtScheduleInfoFormGroup = FormBuilder.group({
    index: [""],
    id: [""],
    custId: [""],
    creditorName: ["", [Validators.required]],
    businessName: [""],
    creditorAddress: [""],
    originalDate: [""],
    originalAmount: [""],
    presentBalance: [""],
    interestRate: [""],
    monthlyPayment: [""],
    maturityDate: [""],
    collateralSecurity: [""],
    refi: [""],
    isPayByLoan: [""]
})

const cashflowLabelsList = ["Revenue", "Gross Profit", "Net Income", "Depriciation", "Amortization", "Intreset"]

function CashFlowBusiness() {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const targetDivRef = useRef(null);

    const user = useSelector((state) => state.auth);

    const urlSearchString = window.location.search;
    const params = new URLSearchParams(urlSearchString);
    const decryptedValue = params.get('data') ? decryptText(params.get('data')) : ""

    // Split the decrypted value into individual parameters
    const paramsArray = decryptedValue ? decryptedValue.split("&") : [];

    const appLoanId = paramsArray.length > 0 ? paramsArray[0].split("=")[1] : null;
    const businessID = paramsArray.length > 1 ? paramsArray[1].split("=")[1] : null;
    const appNumber = paramsArray.length > 2 ? paramsArray[2].split("=")[1] : null;
    tempBusinessId = businessID

    const handleChange = async (event, year) => {
        try {
            setLoading(true)
            const response = await handleFileUpload(event, year, taxReturnForm);
            console.log("AI Response:", response);

            if (response) {
                const yearIndex = bussinessFormGroup.value.lstYears.indexOf(year);

                const updatedCashFlow = bussinessFormGroup.value['lstCashFlow'].map(item => {
                    if (response.hasOwnProperty(item.label)) {
                        item[`val${yearIndex + 1}`] = response[item.label];
                    }
                    return item;
                });
                returnCashFlowFormGroupInArray(updatedCashFlow, bussinessFormGroup, 'lstCashFlow');

                Object.keys(response).forEach(key => {
                    if (response[key] && balanceSheetFormGroup.value.hasOwnProperty(key + yearIndex)) {
                        balanceSheetFormGroup.patchValue({ [key + yearIndex]: response[key] });
                    }
                });

            } else {
                dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "No AI response found", type: "error" }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
            }
            setLoading(false)
        } catch (err) {
            console.log(err)
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: err.message, type: "error" }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
            setLoading(false);
        }
    };

    // Meta Title
    useEffect(() => {
        document.title = "Cash Flow Business - Loan Application";
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                await Promise.all([
                    getInconeStatementOfBusinessCashflow(),
                    getBalanceSheetOfBusinessCashflow(),
                    getBusinessProfileByLoanId()
                ]);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    // Preloader Start
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(reducersAndActions.actions.sessionActions.setLoading(loading));
    }, [loading]);

    const [selectedMonth, setSelectedMonth] = useState(1);
    const [selectedBalanceMonth, setSelectedBalanceMonth] = useState(1);

    const [taxReturnForm, setTaxReturnForm] = useState(null);
    const [isBusinessTaxesPriorYear, setIsBusinessTaxesPriorYear] = useState(null);
    const [confirmNoDebtPopShow, setConfirmNoDebtPopShow] = useState(false);

    const handleConfirmNoDebt = () => {
        bussinessFormGroup.patchValue({ NoDebt: true });
        const formArray = bussinessFormGroup.get('lstDebtScheduleInfo');
        while (formArray.length) {
            formArray.removeAt(0);
        }
        bussinessFormGroup.markAsDirty();
        setConfirmNoDebtPopShow(false);
    }

    const getBusinessProfileByLoanId = async () => {
        const params = {
            SessionId: getSessionId(),
            LoanId: businessID,
        };
        const data = await GetBussinessProfileByLoanId(params);
        if (data.resultObject) {
            setTaxReturnForm(data.resultObject.taxReturnForm || "1065");
            setIsBusinessTaxesPriorYear(data.resultObject.isBusinessTaxesPriorYear);
        }
    }

    const getInconeStatementOfBusinessCashflow = async () => {
        bussinessFormGroup.reset();
        const params = {
            SessionId: getSessionId(),
            LoanId: businessID,
        };

        removeAllCheckings(bussinessFormGroup, 'lstDebtScheduleInfo');

        const data = await GetIncomeStatmentOfBusinessCashflowDetails(params);
        if (data.resultObject) {
            // Filter and update lstCashFlow
            data.resultObject.lstCashFlow = data.resultObject.lstCashFlow
                .concat(
                    cashflowLabelsList
                        .filter(label => !data.resultObject.lstCashFlow.some(item => item.label === label))
                        .map(label => ({ label, val1: "0", val2: "0", val3: "0", val4: "0", val5: "0" }))
                ).sort((a, b) => cashflowLabelsList.indexOf(a.label) - cashflowLabelsList.indexOf(b.label));

            data.resultObject.lstYears = data.resultObject.lstYears.sort((a, b) => a - b);

            // List of arrays in data.resultObject
            const arrayList = ['lstYears', 'lstTF', 'lstCashFlow', 'lstOwner', 'lstDebtScheduleInfo', 'lstCommentHistory'];

            // Create a temporary data object excluding arrayList keys
            const tempData = Object.keys(data.resultObject).reduce((acc, key) => {
                if (!arrayList.includes(key)) {
                    acc[key] = data.resultObject[key];
                }
                return acc;
            }, {});

            // Patch non-array values
            bussinessFormGroup.patchValue(tempData);
            bussinessFormGroup.patchValue({ NoDebt: data.resultObject.noDebt || false });
            setSelectedMonth(data.resultObject.month || 1);

            // Helper function to handle arrays
            const patchArrayValues = (key, patchFunction) => {
                if (data.resultObject[key]?.length > 0) {
                    patchFunction(data.resultObject[key], bussinessFormGroup, key);
                }
            };

            // Patch array values using helper function
            patchArrayValues('lstCashFlow', returnCashFlowFormGroupInArray);
            patchArrayValues('lstDebtScheduleInfo', returnCashFlowDebtScheduleInfoInArray);
            patchArrayValues('lstTF', returnCashFlowTFInArray);
            patchArrayValues('lstOwner', returnCashFlowOwnerInArray);
            patchArrayValues('lstYears', returnCashFlowYearInArray);

        } else {
            bussinessFormGroup.reset();
        }
        return data?.resultObject;
    }


    const getBalanceSheetOfBusinessCashflow = async () => {
        balanceSheetFormGroup.reset();
        const params = {
            SessionId: getSessionId(),
            LoanId: businessID,
        };
        const data = await GetBalanceSheetOfBusinessCashflowDetails(params);
        if (data.resultObject) {
            Object.keys(data.resultObject).forEach((key) => {
                data.resultObject[key] = data.resultObject[key] === null ? "" : data.resultObject[key];
            });
            balanceSheetFormGroup.patchValue(data.resultObject);
            setSelectedBalanceMonth(parseInt(data.resultObject.month) || 1);
        }
    }


    const handleRemoveFormModal = (e, list, index, closePopup) => {
        e.preventDefault();
        const formArray = bussinessFormGroup.get(list);
        let newData = formArray.controls.filter((ctrl, i) => i !== index);
        while (formArray.length) {
            formArray.removeAt(0);
        }
        newData.forEach(control => formArray.push(control));
        bussinessFormGroup.markAsDirty();
        closePopup()
    }

    const getFormattedDateOrBlank = (date) => {
        return date ? moment(date).format("MM/DD/YYYY") : "";
    }

    const handleAddFormModal = (e, list, data, closePopup, extra = "") => {
        e.preventDefault();
        if (data.status !== "INVALID") {
            const { ...otherData } = data.value;
            let groupControl = bussinessFormGroup.get("lstDebtScheduleInfo");
            const finalData = {
                ...otherData,
                maturityDate: getFormattedDateOrBlank(otherData.maturityDate),
                originalDate: getFormattedDateOrBlank(otherData.originalDate),
                interestRate: otherData.interestRate || 0,
            };
            const newPfs = createCashFlowDebtScheduleInfo(finalData);
            groupControl.push(newPfs);
            bussinessFormGroup.markAsDirty();
            closePopup()
        } else {
            markAllAsTouched(data, 'Income_Statement');
        }
    };

    const handleEditFormModal = (e, list, data, closePopup) => {
        e.preventDefault();
        const { index, ...otherData } = data.value;
        const finalData = {
            ...otherData,
            maturityDate: getFormattedDateOrBlank(otherData.maturityDate),
            originalDate: getFormattedDateOrBlank(otherData.originalDate),
            interestRate: otherData.interestRate || 0,
        };
        console.log(finalData)
        bussinessFormGroup.value[list][index] = finalData
        bussinessFormGroup.get("lstDebtScheduleInfo").setValue(bussinessFormGroup.value[list])
        bussinessFormGroup.markAsDirty();
        closePopup()
    }

    // Marking all input fields as touched if the form is not valid
    const markAllAsTouched = (formGroup, tabName) => {
        Object.keys(formGroup.controls).forEach((controlName) => {
            const control = formGroup.get(controlName);
            if (control && control.status === "INVALID") {
                handleTabClick(tabName);
                control.active = true;
                control.touched = true;
                if (document.getElementById(controlName)) {
                    document.getElementById(controlName).focus();
                } else if (controlName === "lstCashFlow") {
                    for (let i = 0; i < control.controls.length; i++) {
                        if (control.controls[i].status === "INVALID") {
                            console.log("control.controls[i]", control.controls[i])
                            console.log("control.controls[i].status", control.controls[i].status)
                            document.getElementById(`lstCashFlow.${i}.label`).focus();
                            break;
                        }
                    }
                }
            }
        });
    };

    // Popup function
    const [DebtSchedulePopupShow, setDebtSchedulePopup] = useState(false);
    const [DebtScheduleType, setDebtScheduleType] = useState("Add");
    const DebtSchedulePopupClose = () => setDebtSchedulePopup(false);

    const [PreviousTabPopupShow, setPreviousTabPopup] = useState(false);
    const PreviousTabPopupClose = () => setPreviousTabPopup(false);

    const backPreviousTab = () => {
        navigate(`/application-detail/${encryptText(appLoanId)}`, { state: { tabName: 'cash_flow' } });
    }

    const checkForBackButton = (formGroup) => {
        if (formGroup.dirty) {
            setPreviousTabPopup(true);
        } else {
            backPreviousTab();
        }
    }

    // CashFlow Business tabs
    const [activeTab, setActiveTab] = useState("Income_Statement");
    const handleTabClick = (tabId) => {
        setActiveTab(tabId);
    };

    const handleRadioChange = (e, tar) => {
        const newValue = e.target.value;
        let lstTF = bussinessFormGroup.value.lstTF;

        if (lstTF.length === 0) {
            lstTF = [{ [tar]: newValue }];
        } else {
            lstTF[0] = { ...lstTF[0], [tar]: newValue };
        }

        returnCashFlowTFInArray(lstTF, bussinessFormGroup, "lstTF");
    };


    // select Dropdown options
    const commonDropdownOptions = [
        { value: 1, label: 'Jan' },
        { value: 2, label: 'Feb' },
        { value: 3, label: 'Mar' },
        { value: 4, label: 'Apr' },
        { value: 5, label: 'May' },
        { value: 6, label: 'Jun' },
        { value: 7, label: 'Jul' },
        { value: 8, label: 'Aug' },
        { value: 9, label: 'Sep' },
        { value: 10, label: 'Oct' },
        { value: 11, label: 'Nov' },
        { value: 12, label: 'Dec' },
    ]


    const calculateNewTotalValue = (value, type) => {
        let total = 0;
        const grossCalculationLabels = ["Net Income", "Depriciation", "Intreset", "Amortization"];
        const excludedFromNetLabels = ["Revenue", "Gross Profit"];

        bussinessFormGroup.value['lstCashFlow'].forEach((item) => {
            if (type === "gross" && grossCalculationLabels.includes(item.label)) {
                total += getValue(item[`val${value}`]);
            }
            if (type === "net" && !excludedFromNetLabels.includes(item.label)) {
                total += getValue(item[`val${value}`]);
            }
        })
        return toFixedOrZero(total);
    };

    const correctLabelJson = {
        "Revenue": "Revenue", "Gross Profit": "Gross Profit", "Net Income": "Net Income", "Depriciation": "Depreciation", "Amortization": "Amortization", "Intreset": "Interest"
    }

    const FixedTable = ({ bussinessFormGroup, calculateCurrentYearA }) => {
        const [fixedRenderRow, setFixedRenderedRows] = useState([]);

        useEffect(() => {
            const timers = bussinessFormGroup.value.lstCashFlow.map((item, index) => (
                setTimeout(() => {
                    setFixedRenderedRows(prev => [...prev, index]);
                }, 100 * index)
            ));

            // Cleanup timers on unmount
            return () => timers.forEach(timer => clearTimeout(timer));
        }, [bussinessFormGroup.value.lstCashFlow]);

        return (
            <>
                {bussinessFormGroup.value.lstCashFlow.map((item, index) => (
                    cashflowLabelsList.includes(item.label) && fixedRenderRow.includes(index) && (
                        <tr key={index}>
                            <td><b>{correctLabelJson[item.label]}</b></td>
                            {Array.from({ length: 5 }, (_, i) => (
                                <td key={`${index}-${i}`}>
                                    <div className="input-dollar">
                                        <AmountContainer
                                            name={`lstCashFlow.${index}.val${i + 1}`}
                                            label=""
                                            placeholder="0.00"
                                            disabled={i === 0 && !isBusinessTaxesPriorYear}
                                        />
                                    </div>
                                </td>
                            ))}
                            <td>
                                <div className="input-dollar cf-cust-input-dollar">
                                    <input
                                        type='text'
                                        placeholder='0.00'
                                        value={calculateCurrentYearA(item.label)}
                                        disabled={true}
                                    />
                                </div>
                            </td>
                        </tr>
                    )
                ))}
            </>
        );
    };



    const DynamicTable = ({ bussinessFormGroup, calculateCurrentYearA }) => {
        const [dynamicRenderedRows, setDynamicRenderedRows] = useState([]);
        const finalData = bussinessFormGroup.value.lstCashFlow

        useEffect(() => {
            const timers = finalData.map((item, index) =>
                setTimeout(() => {
                    setDynamicRenderedRows(prev => [...prev, index]);
                }, 100 * index)
            );

            return () => timers.forEach(timer => clearTimeout(timer));
        }, [bussinessFormGroup.value.lstCashFlow]);

        return (
            <>
                {finalData.map((item, index) => (
                    !cashflowLabelsList.includes(item.label) && dynamicRenderedRows.includes(index) && (
                        <tr key={index}>
                            <td>
                                <FormField
                                    name={`lstCashFlow.${index}.label`}
                                    id={`lstCashFlow.${index}.label`}
                                    label=""
                                    placeholder="Add back"
                                    patternRequired={true}
                                    errorMessage={"This field is required"}
                                    addMarginOnError={true}
                                />
                            </td>
                            {Array.from({ length: 5 }, (_, i) => (
                                <td key={`${index}-${i}`}>
                                    <div className="input-dollar">
                                        <AmountContainer
                                            name={`lstCashFlow.${index}.val${i + 1}`}
                                            label=""
                                            placeholder="0.00"
                                            disabled={i === 0 && !isBusinessTaxesPriorYear}
                                        />
                                    </div>
                                </td>
                            ))}
                            <td>
                                <span className="d-flex align-items-center">
                                    <div className="input-dollar cf-cust-input-dollar">
                                        <input
                                            type="text"
                                            placeholder="0.00"
                                            value={calculateCurrentYearA(item.label)}
                                            disabled={true}
                                        />
                                    </div>
                                    <div className="ml-2" onClick={(e) => removeAddBack(e, index)}>
                                        <img className="cursor-pointer" src={feild_minus_icon} alt="minus" />
                                    </div>
                                </span>
                            </td>
                        </tr>
                    )
                ))}
            </>
        );
    };

    const ShowErrorToaster = () => {
        return (
            <div className="error d-flex justify-content-center align-items-center flex-grow-1">
                <div className="lm-toaster-body cashflow-error text-center">
                    There is an asset and liabilities mismatch. Please fix the error.
                </div>
            </div>
        )
    }

    const calculateCurrentYearA = (label) => {
        const currentCashFlow = bussinessFormGroup.value?.['lstCashFlow'].find(item => item.label === label);
        if (!currentCashFlow) return 0.00

        const val5 = getValue(currentCashFlow.val5 || 0);
        const total = formatNumberWithCommas(toFixedOrZero((val5 / selectedMonth) * 12));

        return total;
    };

    const assetsList = ["cash", "tradeNotesAndAR", "inventories", "usGovernmentObligations", "taxExemptSecurities", "otherCurrentAssets", "loansToShareholders", "mortgageAndRrealEstateLoans", "otherInvestments", "buildingsAndOtherDepreciableAssets", "depletableAssets", "land", "intangibleAssets", "otherAssets"]

    const liabilitiesList_1065 = ["accountsPayable", "mortgagesNotesBondsPayableLessThanYear", "otherCurrentLiabilities", "allNonrecourseLoans", "mortgagesNotesBondsPayableMoreThanYear", "otherLiabilities", "loanFromPartners", "partnersCapitalAccount"]
    const liabilitiesList_1120s = ["accountsPayable", "mortgagesNotesBondsPayableLessThanYear", "otherCurrentLiabilities", "allNonrecourseLoans", "loanFromShareholders", "mortgagesNotesBondsPayableMoreThanYear", "otherLiabilities", "capitalStock", "additionalPaidInCapital", "retainedEarnings", "adjustmentsToShareholdersEquity", "lessCostOfTreasuryStock"]

    const calculateTotalAssets = (value) => {
        let total = 0;
        assetsList.forEach((item) => {
            total += getValue(balanceSheetFormGroup.value[`${item}${value}`]);
        })
        return formatNumberWithCommas(toFixedOrZero(total));
    }

    const calculateTotalLiabilities = (value) => {
        let total = 0;
        const liabilitiesList = taxReturnForm === "1065" ? liabilitiesList_1065 : liabilitiesList_1120s;
        liabilitiesList.forEach((item) => {
            total += getValue(balanceSheetFormGroup.value[`${item}${value}`]);
        })
        return formatNumberWithCommas(toFixedOrZero(total));
    }

    const handleBothIncomeAndBalanceSheet = async () => {
        try {
            if (bussinessFormGroup.status !== "INVALID" && balanceSheetFormGroup.status !== "INVALID") {

                if (calculateTotalAssets(0) !== calculateTotalLiabilities(0) || calculateTotalAssets(1) !== calculateTotalLiabilities(1) || calculateTotalAssets(2) !== calculateTotalLiabilities(2) || calculateTotalAssets(3) !== calculateTotalLiabilities(3) || calculateTotalAssets(4) !== calculateTotalLiabilities(4)) {
                    setActiveTab("Balance_Sheet");
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "There is an asset and liabilities mismatch. Please fix the error.", type: "error" }));
                    setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                    return;
                }
                setLoading(true);

                const cashFlowSummaryList = []
                const DIList = { "Gross Profit": 1, "Revenue": 2, "Net Income": 3, "Depriciation": 4, "Amortization": 5, "Intreset": 6 }

                let tempLabels = bussinessFormGroup.value.lstCashFlow.map((item) => item.label);
                const uniqueLabels = [...new Set(tempLabels)];

                if (uniqueLabels.length !== tempLabels.length) {
                    handleTabClick("Income_Statement");
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "Duplicate add-backs are not allowed", type: "error" }));
                    setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                    setLoading(false);
                    return;
                }

                bussinessFormGroup.value.lstCashFlow.forEach((item) => {
                    Object.keys(item).forEach((key, ind) => {
                        if (key !== "label" && ind < 6) {
                            cashFlowSummaryList.push({
                                "s_CustomerID": businessID,
                                "s_Year": bussinessFormGroup.value.lstYears[parseInt(key.slice(-1)) - 1],
                                "s_Month": selectedMonth,
                                "s_FieldName": item.label,
                                "s_FieldValue": getValue(item[key]).toString(),
                                "s_DI": DIList[item.label],
                                "s_taxfin": bussinessFormGroup.value.lstTF?.[0]?.[`tf${parseInt(key.slice(-1))}`] || ""
                            })
                        }
                    })
                })

                const checkForComma = ["refi", "originalAmount", "presentBalance", "monthlyPayment"]
                bussinessFormGroup.value.lstDebtScheduleInfo.forEach((item) => {
                    item['isPayByLoan'] = item['isPayByLoan'] === 'true' ? true : false;
                    Object.keys(item).forEach((key, ind) => {
                        if (checkForComma.includes(key)) {
                            item[key] = getValue(item[key]).toString();
                        }
                    })
                })

                const data = {
                    "loanId": businessID,
                    "modifiedBy": user.user.id,
                    "debt": getValue(bussinessFormGroup.value.debt) || 0,
                    "pi": getValue(bussinessFormGroup.value.pi) || 0,
                    "cashFlowSummaryList": cashFlowSummaryList,
                    "debtScheduleInfoList": bussinessFormGroup.value.lstDebtScheduleInfo,
                    "month": selectedMonth,
                    "NoDebt": bussinessFormGroup.value.NoDebt,
                }


                // ------------------------------------------------------------------------------------

                let sheetData = { ...balanceSheetFormGroup.value, "loanId": businessID };

                sheetData['totalAssets0'] = calculateTotalAssets(0);
                sheetData['totalAssets1'] = calculateTotalAssets(1);
                sheetData['totalAssets2'] = calculateTotalAssets(2);
                sheetData['totalAssets3'] = calculateTotalAssets(3);
                sheetData['totalAssets4'] = calculateTotalAssets(4);

                sheetData['totalLiabilitiesShareholdersEquity0'] = calculateTotalLiabilities(0);
                sheetData['totalLiabilitiesShareholdersEquity1'] = calculateTotalLiabilities(1);
                sheetData['totalLiabilitiesShareholdersEquity2'] = calculateTotalLiabilities(2);
                sheetData['totalLiabilitiesShareholdersEquity3'] = calculateTotalLiabilities(3);
                sheetData['totalLiabilitiesShareholdersEquity4'] = calculateTotalLiabilities(4);

                sheetData["modifiedBy"] = user.user.id

                Object.keys(sheetData).forEach((key) => {
                    if (key !== "month" && key !== "updatedAt") {
                        sheetData[key] = getValue(sheetData[key])
                    } else {
                        sheetData[key] = ""
                    }
                })
                sheetData["month"] = selectedBalanceMonth.toString();
                const updateSheet = await InsertAndUpdateBusinessCashflow(sheetData, "InsertUpdateBalanceSheetOfBusinessCashflow");


                // ------------------------------------------------------------------------------------
                const update = await InsertAndUpdateBusinessCashflow(data, "InsertUpdateIncomeStatmentOfBusinessCashflow");
                if (update.status === 200 && updateSheet.status === 200) {
                    dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "Data has been saved successfully", type: "success" }));
                    setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                    backPreviousTab();
                }
                setLoading(false);
            } else {
                dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "Please enter valid data", type: "error" }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);

                if (bussinessFormGroup.status === "INVALID") markAllAsTouched(bussinessFormGroup, 'Income_Statement');
                if (balanceSheetFormGroup.status === "INVALID") markAllAsTouched(balanceSheetFormGroup, 'Balance_Sheet');

            }
        } catch (error) {
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: error.response.message, type: "error" }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
            setLoading(false);
        }
    }

    const handleExportDebtSchedule = async () => {
        setLoading(true);
        const params = {
            SessionId: getSessionId(),
            LoanId: businessID,
        };
        // Handle mobile download funcationality
        const isInsideWebView = window.ReactNativeWebView !== undefined;
        if (isInsideWebView) {
            params['url'] = "/GetDebtScheduleReport";
            window.ReactNativeWebView.postMessage(JSON.stringify({ action: "downloadXlsx", params, sessionId: getSessionId(), authToken: getLocalStorageData("token") }));
        } else {
            const data = await GetDebtScheduleReport(params);
            if (data.status === 200) {
                dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "File downloaded successfully", type: "success" }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
            } else {
                dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "Something went wrong!", type: "error" }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
            }
        }
        setLoading(false);
    }


    const toFixedOrZero = (value) => {
        const number = parseFloat(value);
        return isNaN(number) ? '0.00' : number.toFixed(2);
    };

    // Utility function to calculate and format the value
    const calculateDSCRAndFormatValue = (value) => {
        if (value === '0.00') return "-";
        const divisor = getValue(bussinessFormGroup.value.pi) * 12
        const calculatedValue = value / divisor;
        const result = getValue(calculatedValue);
        return (result === Infinity || result === -Infinity) ? "-" : toFixedOrZero(result);
    };

    const calculateFinalTotalValue = (type) => {
        if (type === "gross") {
            const total = getValue(calculateCurrentYearA("Net Income")) + getValue(calculateCurrentYearA("Depriciation")) + getValue(calculateCurrentYearA("Amortization")) + getValue(calculateCurrentYearA("Intreset"));
            return toFixedOrZero(total);
        } else {
            const total = bussinessFormGroup.value.lstCashFlow
                .filter(item => !["Revenue", "Gross Profit"].includes(item.label))
                .reduce((acc, item) => acc + getValue(calculateCurrentYearA(item.label)), 0);
            return toFixedOrZero(total);
        }
    }

    const addNewAddBack = () => {
        const formArray = bussinessFormGroup.get('lstCashFlow');
        const checkForEmptyLabel = formArray.value.find((item) => item.label === "");
        if (checkForEmptyLabel) {
            document.getElementById("lstCashFlow." + formArray.value.findIndex((item) => item.label === "") + ".label").focus();
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "Please fill the empty add back", type: "error" }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
            return;
        }
        const emptyData = FormBuilder.group({
            label: ["", [Validators.required, mustBeUnique]],
            val1: [""], val2: [""], val3: [""], val4: [""], val5: [""],
        })
        formArray.push(emptyData);
        // formArray.controls[formArray.length - 1].controls.label.touched = true;
    }

    const scrollToDiv = () => {
        if (targetDivRef.current) {
            const elementPosition = targetDivRef.current.getBoundingClientRect().top;
            const offsetPosition = window.pageYOffset + elementPosition - 100; // Subtract 100px for the offset

            // Scroll to the calculated position
            window.scrollTo({
                top: offsetPosition,
                behavior: "instant", // Use "instant" if smooth scrolling is not desired
            });
        }
    };


    const removeAddBack = (event, index) => {
        const formArray = bussinessFormGroup.get('lstCashFlow');
        bussinessFormGroup.markAsDirty();
        let newData = formArray.controls.filter((ctrl, i) => i !== index);

        while (formArray.length) formArray.removeAt(0)

        setTimeout(() => {
            newData.forEach((key) => formArray.push(key));
        }, 10);

        setTimeout(() => {
            scrollToDiv();
        }, 50);
    }

    return (
        <>
            {/* SidePanel */}
            <SidePanel />

            {/* ProfileHeader */}
            <ProfileHeader />

            {loading ? (<Preloader />) : (<div>

                <div className="dashboard-wrap application-detail bg-white pb-5">
                    {/* <!-- Cash Flow Business Detail header sec start --> */}
                    <div className="padding-reg">
                        {/* <!-- Cash Flow Business Detail breadcrumb start --> */}
                        <div className="lm-breadcrumb d-flex">
                            <a onClick={() => navigate('/loan-application')} className="back-btn mr-2 cursor-pointer"><img src={back_arrow} alt="" /></a>
                            <span onClick={() => navigate('/loan-application')} className="text-light mr-1 cursor-pointer">Loan Applications</span>
                            <img src={brdcrm_nav} alt="" />
                            <span className="text-dark ml-1">{appNumber}</span>
                        </div>
                        <h1 className="appliaction-no my-3">{bussinessFormGroup.value.businessName}</h1>

                        {/* <!-- Cash Flow Business Detail breadcrumb end --> */}
                        {/* <!-- Cash Flow Business Detail header sec end --> */}


                        <div className="lm-nav-tabs">
                            <ul className="nav nav-tabs" id="laTab" role="tablist">
                                <li className="nav-item">
                                    <button className={`nav-link ${activeTab === "Income_Statement" ? "active" : ""}`} onClick={() => handleTabClick("Income_Statement")}>
                                        Income Statement
                                    </button>
                                </li>
                                <li className="nav-item">
                                    <button className={`nav-link ${activeTab === "Balance_Sheet" ? "active" : ""}`} onClick={() => handleTabClick("Balance_Sheet")}>
                                        Balance Sheet
                                    </button>
                                </li>
                            </ul>
                        </div>

                        <div className="tab-content" id="laTabContent">
                            <div className={`tab-pane fade ${activeTab === "Income_Statement" ? "show active" : ""}`} id="Income_Statement">
                                <FieldGroup
                                    control={bussinessFormGroup}
                                    render={({ get, invalid }) => (
                                        <form>
                                            <div className="lm-card p-0 mt-4">
                                                <div className="d-flex justify-content-between p-3">
                                                    <h3 className="lm-card-heading mb-0">Global Cash Flow</h3>
                                                </div>
                                                <table className="lm-inner-form-grid table-sheet fst-col-15">
                                                    <thead className="bg-grey-thead">
                                                        <tr>
                                                            <th>
                                                                Name
                                                            </th>
                                                            {bussinessFormGroup.value.lstYears.map((year, index) => (
                                                                <React.Fragment key={index}>
                                                                    {index === 4 ? (
                                                                        <>
                                                                            <th className="text-center grid-dropdown">
                                                                                {year}
                                                                                <FieldControl
                                                                                    name="month"
                                                                                    render={({ handler, touched, hasError, meta }) => (
                                                                                        <Select
                                                                                            className="basic-single table-month-picker"
                                                                                            classNamePrefix="select"
                                                                                            id="month"
                                                                                            name="month"
                                                                                            options={commonDropdownOptions}
                                                                                            value={commonDropdownOptions.find(option => option.value === selectedMonth)}
                                                                                            onChange={(e) => {
                                                                                                setSelectedMonth(e.value)
                                                                                                handler().onChange(e);
                                                                                            }}
                                                                                        />
                                                                                    )}
                                                                                />
                                                                                <label htmlFor={`upload-${year}`} style={{ cursor: "pointer", marginLeft: "10px" }}>
                                                                                    <img src={uploadIcon} alt="upload-icon" />
                                                                                    <input
                                                                                        type="file"
                                                                                        id={`upload-${year}`}
                                                                                        style={{ display: "none" }}
                                                                                        onChange={(e) => handleChange(e, year)}
                                                                                        accept=".pdf" // Ensures only PDF files can be selected
                                                                                    />
                                                                                </label>
                                                                            </th>
                                                                            <th className="text-center">
                                                                                <span className="current-year">
                                                                                    {year} (A)
                                                                                    <span className="lm-tooltip ml-1" data-tooltip="Tooltip" data-tooltip-pos="up" data-tooltip-length="fit">
                                                                                        <img src={info_tooltip} alt="info tooltip" />
                                                                                    </span>
                                                                                </span>
                                                                            </th>
                                                                        </>
                                                                    ) : (
                                                                        <th key={index} className="text-center">
                                                                            {year}
                                                                            <label htmlFor={`upload-${year}`} style={{ cursor: "pointer", marginLeft: "10px" }}>
                                                                                {
                                                                                    (index === 0 && isBusinessTaxesPriorYear) || index !== 0 ? (
                                                                                        <img src={uploadIcon} alt="upload-icon" />
                                                                                    ) : null
                                                                                }
                                                                                <input
                                                                                    type="file"
                                                                                    id={`upload-${year}`}
                                                                                    style={{ display: "none" }}
                                                                                    onChange={(e) => handleChange(e, year)}
                                                                                    accept=".pdf" // Ensures only PDF files can be selected
                                                                                />
                                                                            </label>
                                                                        </th>
                                                                    )}
                                                                </React.Fragment>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <FixedTable
                                                            bussinessFormGroup={bussinessFormGroup}
                                                            calculateCurrentYearA={calculateCurrentYearA}
                                                        />

                                                        <tr>
                                                            <td></td>
                                                            <td><b className="ml-3">$ {formatNumberWithCommas(calculateNewTotalValue(1, 'gross')) || "0.00"}</b></td>
                                                            <td><b className="ml-3">$ {formatNumberWithCommas(calculateNewTotalValue(2, 'gross')) || "0.00"}</b></td>
                                                            <td><b className="ml-3">$ {formatNumberWithCommas(calculateNewTotalValue(3, 'gross')) || "0.00"}</b></td>
                                                            <td><b className="ml-3">$ {formatNumberWithCommas(calculateNewTotalValue(4, 'gross')) || "0.00"}</b></td>
                                                            <td><b className="ml-3">$ {formatNumberWithCommas(calculateNewTotalValue(5, 'gross')) || "0.00"}</b></td>
                                                            <td className='d-flex text-nowrap'><b className="ml-3">$ {formatNumberWithCommas(calculateFinalTotalValue("gross")) || "0.00"}</b></td>
                                                        </tr>

                                                        <tr>
                                                            <td colSpan={7} ref={targetDivRef}>
                                                                <div className="d-flex justify-content-between pt-3">
                                                                    <h3 className="lm-card-heading mb-0">Insert add-back
                                                                        <span className="lm-tooltip ml-1"
                                                                            data-tooltip="Add backs are expenses that are not included in future P&Ls for the company. They provide all parties a true understanding of cash flow and total value."
                                                                            data-tooltip-pos="up" data-tooltip-length="cutsom"> <img src={info_tooltip} alt="" />
                                                                        </span>
                                                                    </h3>
                                                                    <a onClick={addNewAddBack} className="lm-button3"><span>+</span> Add-back</a>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                        <DynamicTable
                                                            bussinessFormGroup={bussinessFormGroup}
                                                            calculateCurrentYearA={calculateCurrentYearA}
                                                        />

                                                        <tr>
                                                            {/* change the id, name, value of the below radio buttons dynamically */}
                                                            <td className='d-flex text-nowrap'>
                                                                <span className="text-light">Available Cash</span>
                                                            </td>
                                                            <td>
                                                                <b className="ml-3">$ {formatNumberWithCommas(calculateNewTotalValue(1, "net")) || "0.00"}</b>
                                                                <div className="d-grid mt-2">
                                                                    <div className="form-check form-check-inline ml-3 mb-2">
                                                                        <input className="form-check-input" type="radio" name="2021" id="Financials2021" value="Financials" onChange={(e) => handleRadioChange(e, "tf1")} checked={bussinessFormGroup.value.lstTF.length && bussinessFormGroup.value.lstTF?.[0].hasOwnProperty("tf1") && bussinessFormGroup.value.lstTF?.[0].tf1 === "Financials"} />
                                                                        <label className="form-check-label" htmlFor="Financials2021">Financials</label>
                                                                    </div>
                                                                    <div className="form-check form-check-inline ml-3 mb-2">
                                                                        <input className="form-check-input" type="radio" name="2021" id="Tax_Returns2021" value="Tax Returns" onChange={(e) => handleRadioChange(e, "tf1")} checked={bussinessFormGroup.value.lstTF.length && bussinessFormGroup.value.lstTF?.[0].hasOwnProperty("tf1") && bussinessFormGroup.value.lstTF?.[0].tf1 === "Tax Returns"} />
                                                                        <label className="form-check-label" htmlFor="Tax_Returns2021">Tax Return</label>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <b className="ml-3">$ {formatNumberWithCommas(calculateNewTotalValue(2, "net")) || "0.00"}</b>
                                                                <div className="d-grid mt-2">
                                                                    <div className="form-check form-check-inline ml-3 mb-2">
                                                                        <input className="form-check-input" type="radio" name="2022" id="Financials2022" value="Financials" onChange={(e) => handleRadioChange(e, "tf2")} checked={bussinessFormGroup.value.lstTF.length && bussinessFormGroup.value.lstTF?.[0].hasOwnProperty("tf2") && bussinessFormGroup.value.lstTF?.[0].tf2 === "Financials"} />
                                                                        <label className="form-check-label" htmlFor="Financials2022">Financials</label>
                                                                    </div>
                                                                    <div className="form-check form-check-inline ml-3 mb-2">
                                                                        <input className="form-check-input" type="radio" name="2022" id="Tax_Returns2022" value="Tax Returns" onChange={(e) => handleRadioChange(e, "tf2")} checked={bussinessFormGroup.value.lstTF.length && bussinessFormGroup.value.lstTF?.[0].hasOwnProperty("tf2") && bussinessFormGroup.value.lstTF?.[0].tf2 === "Tax Returns"} />
                                                                        <label className="form-check-label" htmlFor="Tax_Returns2022">Tax Return</label>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <b className="ml-3">$ {formatNumberWithCommas(calculateNewTotalValue(3, "net")) || "0.00"}</b>
                                                                <div className="d-grid mt-2">
                                                                    <div className="form-check form-check-inline ml-3 mb-2">
                                                                        <input className="form-check-input" type="radio" name="2023" id="Financials2023" value="Financials" onChange={(e) => handleRadioChange(e, "tf3")} checked={bussinessFormGroup.value.lstTF.length && bussinessFormGroup.value.lstTF?.[0].hasOwnProperty("tf3") && bussinessFormGroup.value.lstTF?.[0].tf3 === "Financials"} />
                                                                        <label className="form-check-label" htmlFor="Financials2023">Financials</label>
                                                                    </div>
                                                                    <div className="form-check form-check-inline ml-3 mb-2">
                                                                        <input className="form-check-input" type="radio" name="2023" id="Tax_Returns2023" value="Tax Returns" onChange={(e) => handleRadioChange(e, "tf3")} checked={bussinessFormGroup.value.lstTF.length && bussinessFormGroup.value.lstTF?.[0].hasOwnProperty("tf3") && bussinessFormGroup.value.lstTF?.[0].tf3 === "Tax Returns"} />
                                                                        <label className="form-check-label" htmlFor="Tax_Returns2023">Tax Return</label>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <b className="ml-3">$ {formatNumberWithCommas(calculateNewTotalValue(4, "net")) || "0.00"}</b>
                                                                <div className="d-grid mt-2">
                                                                    <div className="form-check form-check-inline ml-3 mb-2">
                                                                        <input className="form-check-input" type="radio" name="2024" id="Financials2024" value="Financials" onChange={(e) => handleRadioChange(e, "tf4")} checked={bussinessFormGroup.value.lstTF.length && bussinessFormGroup.value.lstTF?.[0].hasOwnProperty("tf4") && bussinessFormGroup.value.lstTF?.[0].tf4 === "Financials"} />
                                                                        <label className="form-check-label" htmlFor="Financials2024">Financials</label>
                                                                    </div>
                                                                    <div className="form-check form-check-inline ml-3 mb-2">
                                                                        <input className="form-check-input" type="radio" name="2024" id="Tax_Returns2024" value="Tax Returns" onChange={(e) => handleRadioChange(e, "tf4")} checked={bussinessFormGroup.value.lstTF.length && bussinessFormGroup.value.lstTF?.[0].hasOwnProperty("tf4") && bussinessFormGroup.value.lstTF?.[0].tf4 === "Tax Returns"} />
                                                                        <label className="form-check-label" htmlFor="Tax_Returns2024">Tax Return</label>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <b className="ml-3">$ {formatNumberWithCommas(calculateNewTotalValue(5, "net")) || "0.00"}</b>
                                                                <div className="d-grid mt-2">
                                                                    <div className="form-check form-check-inline ml-3 mb-2">
                                                                        <input className="form-check-input" type="radio" name="2024A" id="Financials2024A" value="Financials" onChange={(e) => handleRadioChange(e, "tf5")} checked={bussinessFormGroup.value.lstTF.length && bussinessFormGroup.value.lstTF?.[0].hasOwnProperty("tf5") && bussinessFormGroup.value.lstTF?.[0].tf5 === "Financials"} />
                                                                        <label className="form-check-label" htmlFor="Financials2024A">Financials</label>
                                                                    </div>
                                                                    <div className="form-check form-check-inline ml-3 mb-2">
                                                                        <input className="form-check-input" type="radio" name="2024A" id="Tax_Returns2024A" value="Tax Returns" onChange={(e) => handleRadioChange(e, "tf5")} checked={bussinessFormGroup.value.lstTF.length && bussinessFormGroup.value.lstTF?.[0].hasOwnProperty("tf5") && bussinessFormGroup.value.lstTF?.[0].tf5 === "Tax Returns"} />
                                                                        <label className="form-check-label" htmlFor="Tax_Returns2024A">Tax Return</label>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className='d-flex text-nowrap'>
                                                                <b className="ml-3">$ {formatNumberWithCommas(calculateFinalTotalValue("net")) || "0.00"}</b>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                <div className="row px-3 ">
                                                    <div className="col-12">
                                                        <div className="form-note-box d-flex">
                                                            <img src={form_note_icon} alt="" />
                                                            <div>
                                                                <p>
                                                                    To edit {moment().year()} figures, please save your work. From the pull-down menu, select {moment().year()} and update the information.
                                                                    <br></br>
                                                                    Insufficient rows to calculate available cash.
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <!-- lm common divider to break the section  --> */}
                                                <div className="lm-divider my-4"></div>
                                                {/* <!-- lm common divider to break the section  --> */}
                                                <div className="row px-3">
                                                    <div className="col-md-6 col-lg-3">
                                                        <div className="form-group input-dollar">
                                                            <AmountContainer
                                                                name="debt"
                                                                placeholder="0.00"
                                                                label="Debt"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 col-lg-3">
                                                        <div className="form-group input-dollar">
                                                            <AmountContainer
                                                                name="pi"
                                                                label="Monthly P&I"
                                                                placeholder="Enter a Monthly P&I"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <table className="lm-inner-form-grid table-sheet fst-col-25 border-none">
                                                    <tbody>
                                                        <tr>
                                                            <td><span className="text-light">DSCR</span></td>
                                                            <td><b className="ml-3">{calculateDSCRAndFormatValue(calculateNewTotalValue(1, "net"))}</b></td>
                                                            <td><b className="ml-3">{calculateDSCRAndFormatValue(calculateNewTotalValue(2, "net"))}</b></td>
                                                            <td><b className="ml-3">{calculateDSCRAndFormatValue(calculateNewTotalValue(3, "net"))}</b></td>
                                                            <td><b className="ml-3">{calculateDSCRAndFormatValue(calculateNewTotalValue(4, "net"))}</b></td>
                                                            <td><b className="ml-3">{calculateDSCRAndFormatValue(calculateNewTotalValue(5, "net"))}</b></td>
                                                            <td><b className="ml-3">{calculateDSCRAndFormatValue(calculateFinalTotalValue("net"))}</b></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className="lm-card p-0 mt-4 mb-sm-0 mb-4">
                                                <div className="d-sm-flex d-block justify-content-between py-sm-3 py-2 px-sm-4 px-2">
                                                    <h3 className="lm-card-heading mt-2 mb-sm-0 mb-3">Debt Schedule</h3>
                                                    <div className="d-flex">
                                                        <div className="form-group form-check mt-1">
                                                            <FieldControl
                                                                name="NoDebt"
                                                                render={({ handler }) => (
                                                                    <>
                                                                        <input type="checkbox" className="form-check-input" id="NoDebt" checked={handler().value}
                                                                            onChange={(e) => {
                                                                                if (e.target.checked) {
                                                                                    setConfirmNoDebtPopShow(true)
                                                                                } else {
                                                                                    handler().onChange(false)
                                                                                }
                                                                            }}
                                                                        />
                                                                        <label className="form-check-label" htmlFor="NoDebt">No debt</label>
                                                                    </>
                                                                )}
                                                            />
                                                        </div>
                                                        {
                                                            bussinessFormGroup.value.NoDebt ? (
                                                                <a onClick={(e) => { e.preventDefault() }} className="lm-button3 lm-button3-disabled mt-2 mx-3">
                                                                    <span className='text-disabled'>+</span> Add Debt Schedule
                                                                </a>
                                                            ) : (
                                                                <a onClick={() => {
                                                                    debtScheduleInfoFormGroup.reset()
                                                                    setDebtSchedulePopup(true)
                                                                    setDebtScheduleType("Add")
                                                                }} className="lm-button3 mt-2 mx-3">
                                                                    <span>+</span> Add Debt Schedule
                                                                </a>
                                                            )
                                                        }
                                                        <button type="button" className="lm-button2" onClick={handleExportDebtSchedule}>
                                                            <img src={download_icon_outline} alt="" /> Export Debt Schedule
                                                        </button>
                                                    </div>
                                                </div>
                                                {/* <!-- lm common divider to break the section  --> */}
                                                <div className="lm-divider mb-0"></div>

                                                <FormArrayComponent
                                                    control={bussinessFormGroup.get("lstDebtScheduleInfo")}
                                                    render={(cfValue, index) => (
                                                        <div className="row p-4 pfs-card">
                                                            <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                                <div className="pfs-card-label">Creditor name</div>
                                                                <div className="pfs-card-value">{cfValue.value.creditorName}</div>
                                                            </div>
                                                            <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                                <div className="pfs-card-label">Original Date</div>
                                                                <div className="pfs-card-value">{cfValue.value.originalDate ? moment(cfValue.value.originalDate).format('ll') : ""}</div>
                                                            </div>
                                                            <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                                <div className="pfs-card-label">Original amount</div>
                                                                <div className="pfs-card-value">{"$" + formatNumberWithCommas(toFixedOrZero(getValue(cfValue.value.originalAmount)))}</div>
                                                            </div>
                                                            <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                                <div className="pfs-card-label">Present Balance</div>
                                                                <div className="pfs-card-value">{"$" + formatNumberWithCommas(toFixedOrZero(getValue(cfValue.value.presentBalance)))}</div>
                                                            </div>
                                                            <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                                <div className="pfs-card-label">Interest Rate</div>
                                                                <div className="pfs-card-value">{toFixedOrZero(cfValue.value.interestRate) + "%"}</div>
                                                            </div>
                                                            <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                                <div className="pfs-card-label">Monthly Payment</div>
                                                                <div className="pfs-card-value">{"$" + formatNumberWithCommas(toFixedOrZero(getValue(cfValue.value.monthlyPayment)))}</div>
                                                            </div>
                                                            <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                                <div className="pfs-card-label">Maturity Date</div>
                                                                <div className="pfs-card-value">{cfValue.value.maturityDate ? moment(cfValue.value.maturityDate).format('ll') : ""}</div>
                                                            </div>
                                                            <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                                <div className="pfs-card-label">Paid By Loan Proceeds</div>
                                                                <div className="pfs-card-value">{(cfValue.value.isPayByLoan === "true" || cfValue.value.isPayByLoan === true) ? "Yes" : "No"}</div>
                                                            </div>
                                                            <div className="col-lg-fifth col-md-4 col-6 mb-3">
                                                                <div className="pfs-card-label">Collateral Security</div>
                                                                <div className="pfs-card-value">{cfValue.value.collateralSecurity}</div>
                                                            </div>
                                                            <div className="col-12 d-flex">
                                                                <button type="button" className="mr-2 lm-button2 w-100 text-red" onClick={(e) => handleRemoveFormModal(e, "lstDebtScheduleInfo", index, DebtSchedulePopupClose)}><img src={lm_trash_icon} alt="" /> Remove</button>
                                                                <button type="button" className="ml-2 lm-button2 w-100"
                                                                    onClick={() => {
                                                                        debtScheduleInfoFormGroup.patchValue({
                                                                            index: index,
                                                                            id: cfValue.value.id,
                                                                            custId: cfValue.value.custId,
                                                                            creditorName: cfValue.value.creditorName,
                                                                            businessName: cfValue.value.businessName,
                                                                            creditorAddress: cfValue.value.creditorAddress,
                                                                            originalDate: cfValue.value.originalDate,
                                                                            originalAmount: toFixedOrZero(getValue(cfValue.value.originalAmount)),
                                                                            presentBalance: toFixedOrZero(getValue(cfValue.value.presentBalance)),
                                                                            interestRate: toFixedOrZero(getValue(cfValue.value.interestRate)),
                                                                            monthlyPayment: toFixedOrZero(getValue(cfValue.value.monthlyPayment)),
                                                                            maturityDate: cfValue.value.maturityDate,
                                                                            collateralSecurity: cfValue.value.collateralSecurity,
                                                                            refi: cfValue.value.refi,
                                                                            isPayByLoan: (cfValue.value.isPayByLoan === "true" || cfValue.value.isPayByLoan === true) ? "true" : "false",
                                                                        })
                                                                        setDebtScheduleType("Edit")
                                                                        setDebtSchedulePopup(true)
                                                                    }}><img src={lm_edit_icon} alt="" /> Edit</button>
                                                            </div>
                                                        </div>
                                                    )}
                                                />
                                            </div>

                                            <div className="sticky-btn-footer d-flex justify-content-end">
                                                <button onClick={() => checkForBackButton(bussinessFormGroup)} type="button" className="lm-button2">Back</button>
                                                <button type="button" className="lm-button1 ml-3" onClick={handleBothIncomeAndBalanceSheet}>Save</button>
                                            </div>
                                        </form>
                                    )}
                                />
                            </div>
                            <div className={`tab-pane fade ${activeTab === "Balance_Sheet" ? "show active" : ""}`} id="Balance_Sheet">
                                <FieldGroup
                                    control={balanceSheetFormGroup}
                                    render={({ get, invalid }) => (
                                        <form>
                                            <div className="lm-card p-0 mt-4">
                                                <div className="d-flex align-items-center p-3 justify-content-between">
                                                    <h3 className="lm-card-heading mb-0">Assets</h3>
                                                    {
                                                        (
                                                            (getValue(calculateTotalAssets(0)) - getValue(calculateTotalLiabilities(0)) !== 0) ||
                                                            (getValue(calculateTotalAssets(1)) - getValue(calculateTotalLiabilities(1)) !== 0) ||
                                                            (getValue(calculateTotalAssets(2)) - getValue(calculateTotalLiabilities(2)) !== 0) ||
                                                            (getValue(calculateTotalAssets(3)) - getValue(calculateTotalLiabilities(3)) !== 0) ||
                                                            (getValue(calculateTotalAssets(4)) - getValue(calculateTotalLiabilities(4)) !== 0)
                                                        ) && <ShowErrorToaster />
                                                    }
                                                </div>
                                                <table className="lm-inner-form-grid table-sheet fst-col-25">
                                                    <thead className="bg-grey-thead">
                                                        <tr>
                                                            <th></th>
                                                            {bussinessFormGroup.value.lstYears.map((year, index) => (
                                                                <React.Fragment key={index}>
                                                                    {
                                                                        index === 4 ? (
                                                                            <>
                                                                                <th className="text-center grid-dropdown">
                                                                                    <span className={getValue(calculateTotalAssets(4)) - getValue(calculateTotalLiabilities(4)) !== 0 ? 'showErrorBorderYear' : ''}>
                                                                                        {year}
                                                                                    </span>
                                                                                    <FieldControl
                                                                                        name="month"
                                                                                        render={({ handler, touched, hasError, meta }) => (
                                                                                            <Select
                                                                                                className="basic-single table-month-picker"
                                                                                                classNamePrefix="select"
                                                                                                id="month"
                                                                                                name="month"
                                                                                                options={commonDropdownOptions}
                                                                                                value={commonDropdownOptions.find(option => option.value === selectedBalanceMonth)}
                                                                                                onChange={(e) => {
                                                                                                    setSelectedBalanceMonth(e.value)
                                                                                                    handler().onChange(e);
                                                                                                }}
                                                                                            />
                                                                                        )}
                                                                                    />
                                                                                </th>
                                                                            </>
                                                                        ) :
                                                                            <th key={index} className="text-center">
                                                                                <span className={getValue(calculateTotalAssets(index)) - getValue(calculateTotalLiabilities(index)) !== 0 ? 'showErrorBorderYear' : ''}>
                                                                                    {year}
                                                                                </span>
                                                                            </th>
                                                                    }
                                                                </React.Fragment>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td><b>Cash</b></td>
                                                            {bussinessFormGroup.value.lstYears.map((year, index) => (
                                                                <td key={index}>
                                                                    <AmountContainer
                                                                        name={`cash${index}`}
                                                                        label=""
                                                                        placeholder="0.00"
                                                                        disabled={index === 0 && !isBusinessTaxesPriorYear}
                                                                    />
                                                                </td>
                                                            ))}
                                                        </tr>
                                                        <tr className='table-sheet-parent-row'>
                                                            <td>
                                                                <div className='d-flex justify-content-between align-items-center'>
                                                                    <div><b>Trade notes and A/R</b></div>
                                                                    <span className="lm-tooltip ml-1"
                                                                        data-tooltip="Net amount"
                                                                        data-tooltip-pos="up" data-tooltip-length="fit"> <img src={info_tooltip} alt="" />
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            {bussinessFormGroup.value.lstYears.map((year, index) => (
                                                                <td key={index}>
                                                                    <AmountContainer
                                                                        name={`tradeNotesAndAR${index}`}
                                                                        label=""
                                                                        placeholder="0.00"
                                                                        disabled={index === 0 && !isBusinessTaxesPriorYear}
                                                                    />
                                                                </td>
                                                            ))}
                                                        </tr>
                                                        <tr className='table-sheet-child-row'>
                                                            <td><span>Less: allowance for bad debt</span></td>
                                                            {bussinessFormGroup.value.lstYears.map((year, index) => (
                                                                <td key={index}>
                                                                    <AmountContainer
                                                                        name={`lessAllowanceForBadDebt${index}`}
                                                                        label=""
                                                                        placeholder="0.00"
                                                                        disabled={index === 0 && !isBusinessTaxesPriorYear}
                                                                    />
                                                                </td>
                                                            ))}
                                                        </tr>
                                                        <tr>
                                                            <td><b>Inventories</b></td>
                                                            {bussinessFormGroup.value.lstYears.map((year, index) => (
                                                                <td key={index}>
                                                                    <AmountContainer
                                                                        name={`inventories${index}`}
                                                                        label=""
                                                                        placeholder="0.00"
                                                                        disabled={index === 0 && !isBusinessTaxesPriorYear}
                                                                    />
                                                                </td>
                                                            ))}
                                                        </tr>
                                                        <tr>
                                                            <td><b>U.S. government obligations</b></td>
                                                            {bussinessFormGroup.value.lstYears.map((year, index) => (
                                                                <td key={index}>
                                                                    <AmountContainer
                                                                        name={`usGovernmentObligations${index}`}
                                                                        label=""
                                                                        placeholder="0.00"
                                                                        disabled={index === 0 && !isBusinessTaxesPriorYear}
                                                                    />
                                                                </td>
                                                            ))}
                                                        </tr>
                                                        <tr>
                                                            <td><b>Tax-exempt securities</b></td>
                                                            {bussinessFormGroup.value.lstYears.map((year, index) => (
                                                                <td key={index}>
                                                                    <AmountContainer
                                                                        name={`taxExemptSecurities${index}`}
                                                                        label=""
                                                                        placeholder="0.00"
                                                                        disabled={index === 0 && !isBusinessTaxesPriorYear}
                                                                    />
                                                                </td>
                                                            ))}
                                                        </tr>
                                                        <tr>
                                                            <td><b>Other current assets</b></td>
                                                            {bussinessFormGroup.value.lstYears.map((year, index) => (
                                                                <td key={index}>
                                                                    <AmountContainer
                                                                        name={`otherCurrentAssets${index}`}
                                                                        label=""
                                                                        placeholder="0.00"
                                                                        disabled={index === 0 && !isBusinessTaxesPriorYear}
                                                                    />
                                                                </td>
                                                            ))}
                                                        </tr>
                                                        <tr>
                                                            <td><b>{taxReturnForm === "1065" ? "Loans to partners" : "Loans to shareholders"}</b></td>
                                                            {bussinessFormGroup.value.lstYears.map((year, index) => (
                                                                <td key={index}>
                                                                    <AmountContainer
                                                                        name={`loansToShareholders${index}`}
                                                                        label=""
                                                                        placeholder="0.00"
                                                                        disabled={index === 0 && !isBusinessTaxesPriorYear}
                                                                    />
                                                                </td>
                                                            ))}
                                                        </tr>
                                                        <tr>
                                                            <td><b>Mortgage and real estate loans</b></td>
                                                            {bussinessFormGroup.value.lstYears.map((year, index) => (
                                                                <td key={index}>
                                                                    <AmountContainer
                                                                        name={`mortgageAndRrealEstateLoans${index}`}
                                                                        label=""
                                                                        placeholder="0.00"
                                                                        disabled={index === 0 && !isBusinessTaxesPriorYear}
                                                                    />
                                                                </td>
                                                            ))}
                                                        </tr>
                                                        <tr>
                                                            <td><b>Other investments</b></td>
                                                            {bussinessFormGroup.value.lstYears.map((year, index) => (
                                                                <td key={index}>
                                                                    <AmountContainer
                                                                        name={`otherInvestments${index}`}
                                                                        label=""
                                                                        placeholder="0.00"
                                                                        disabled={index === 0 && !isBusinessTaxesPriorYear}
                                                                    />
                                                                </td>
                                                            ))}
                                                        </tr>
                                                        <tr className='table-sheet-parent-row'>
                                                            <td>
                                                                <div className='d-flex justify-content-between align-items-center'>
                                                                    <div><b>Buildings and other depreciable assets</b></div>
                                                                    <span className="lm-tooltip ml-1"
                                                                        data-tooltip="Net amount"
                                                                        data-tooltip-pos="up" data-tooltip-length="fit"> <img src={info_tooltip} alt="" />
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            {bussinessFormGroup.value.lstYears.map((year, index) => (
                                                                <td key={index}>
                                                                    <AmountContainer
                                                                        name={`buildingsAndOtherDepreciableAssets${index}`}
                                                                        label=""
                                                                        placeholder="0.00"
                                                                        disabled={index === 0 && !isBusinessTaxesPriorYear}
                                                                    />
                                                                </td>
                                                            ))}
                                                        </tr>
                                                        <tr className='table-sheet-child-row'>
                                                            <td><span>Less: accumulated depreciation</span></td>
                                                            {bussinessFormGroup.value.lstYears.map((year, index) => (
                                                                <td key={index}>
                                                                    <AmountContainer
                                                                        name={`lessAccumulatedDepreciation${index}`}
                                                                        label=""
                                                                        placeholder="0.00"
                                                                        disabled={index === 0 && !isBusinessTaxesPriorYear}
                                                                    />
                                                                </td>
                                                            ))}
                                                        </tr>
                                                        <tr className='table-sheet-parent-row'>
                                                            <td>
                                                                <div className='d-flex justify-content-between align-items-center'>
                                                                    <div><b>Depletable assets</b></div>
                                                                    <span className="lm-tooltip ml-1"
                                                                        data-tooltip="Net amount"
                                                                        data-tooltip-pos="up" data-tooltip-length="fit"> <img src={info_tooltip} alt="" />
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            {bussinessFormGroup.value.lstYears.map((year, index) => (
                                                                <td key={index}>
                                                                    <AmountContainer
                                                                        name={`depletableAssets${index}`}
                                                                        label=""
                                                                        placeholder="0.00"
                                                                        disabled={index === 0 && !isBusinessTaxesPriorYear}
                                                                    />
                                                                </td>
                                                            ))}
                                                        </tr>
                                                        <tr className='table-sheet-child-row'>
                                                            <td><span>Less: accumulated depletion</span></td>
                                                            {bussinessFormGroup.value.lstYears.map((year, index) => (
                                                                <td key={index}>
                                                                    <AmountContainer
                                                                        name={`lessAccumulatedDepletion${index}`}
                                                                        label=""
                                                                        placeholder="0.00"
                                                                        disabled={index === 0 && !isBusinessTaxesPriorYear}
                                                                    />
                                                                </td>
                                                            ))}
                                                        </tr>
                                                        <tr>
                                                            <td><b>Land</b></td>
                                                            {bussinessFormGroup.value.lstYears.map((year, index) => (
                                                                <td key={index}>
                                                                    <AmountContainer
                                                                        name={`land${index}`}
                                                                        label=""
                                                                        placeholder="0.00"
                                                                        disabled={index === 0 && !isBusinessTaxesPriorYear}
                                                                    />
                                                                </td>
                                                            ))}
                                                        </tr>
                                                        <tr className='table-sheet-parent-row'>
                                                            <td>
                                                                <div className='d-flex justify-content-between align-items-center'>
                                                                    <div><b>Intangible assets</b></div>
                                                                    <span className="lm-tooltip ml-1"
                                                                        data-tooltip="Net amount"
                                                                        data-tooltip-pos="up" data-tooltip-length="fit"> <img src={info_tooltip} alt="" />
                                                                    </span>
                                                                </div>
                                                            </td>
                                                            {bussinessFormGroup.value.lstYears.map((year, index) => (
                                                                <td key={index}>
                                                                    <AmountContainer
                                                                        name={`intangibleAssets${index}`}
                                                                        label=""
                                                                        placeholder="0.00"
                                                                        disabled={index === 0 && !isBusinessTaxesPriorYear}
                                                                    />
                                                                </td>
                                                            ))}
                                                        </tr>
                                                        <tr className='table-sheet-child-row'>
                                                            <td><span>Less: accumulated amortization</span></td>
                                                            {bussinessFormGroup.value.lstYears.map((year, index) => (
                                                                <td key={index}>
                                                                    <AmountContainer
                                                                        name={`lessAccumulatedAamortization${index}`}
                                                                        label=""
                                                                        placeholder="0.00"
                                                                        disabled={index === 0 && !isBusinessTaxesPriorYear}
                                                                    />
                                                                </td>
                                                            ))}
                                                        </tr>
                                                        <tr>
                                                            <td><b>Other assets</b></td>
                                                            {bussinessFormGroup.value.lstYears.map((year, index) => (
                                                                <td key={index}>
                                                                    <AmountContainer
                                                                        name={`otherAssets${index}`}
                                                                        label=""
                                                                        placeholder="0.00"
                                                                        disabled={index === 0 && !isBusinessTaxesPriorYear}
                                                                    />
                                                                </td>
                                                            ))}
                                                        </tr>


                                                        {/* total assests footer */}
                                                        <tr>
                                                            <td>
                                                                <span className="text-light">Total Assets</span>
                                                            </td>
                                                            <td>
                                                                <b className="ml-3">$ {calculateTotalAssets(0)}</b>
                                                            </td>
                                                            <td>
                                                                <b className="ml-3">$ {calculateTotalAssets(1)}</b>
                                                            </td>
                                                            <td>
                                                                <b className="ml-3">$ {calculateTotalAssets(2)}</b>
                                                            </td>
                                                            <td>
                                                                <b className="ml-3">$ {calculateTotalAssets(3)}</b>
                                                            </td>
                                                            <td>
                                                                <b className="ml-3">$ {calculateTotalAssets(4)}</b>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className="lm-card p-0 mt-4">
                                                <div className="d-flex justify-content-between p-3">
                                                    <h3 className="lm-card-heading mb-0">
                                                        {
                                                            taxReturnForm === "1065" ? "Liabilities & Capital" : "Liabilities & Shareholders Equity"
                                                        }
                                                    </h3>
                                                </div>
                                                <table className="lm-inner-form-grid table-sheet fst-col-25">
                                                    <thead className="bg-grey-thead">
                                                        <tr>
                                                            <th></th>
                                                            {bussinessFormGroup.value.lstYears.map((year, index) => (
                                                                <th key={index} className="text-center">{year}</th>
                                                            ))}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td><b>Accounts payable</b></td>
                                                            {bussinessFormGroup.value.lstYears.map((year, index) => (
                                                                <td key={index}>
                                                                    <AmountContainer
                                                                        name={`accountsPayable${index}`}
                                                                        label=""
                                                                        placeholder="0.00"
                                                                        disabled={index === 0 && !isBusinessTaxesPriorYear}
                                                                    />
                                                                </td>
                                                            ))}
                                                        </tr>
                                                        <tr>
                                                            <td><b>Mortgages, notes, bonds payable ( &lt; 1 year)</b></td>
                                                            {bussinessFormGroup.value.lstYears.map((year, index) => (
                                                                <td key={index}>
                                                                    <AmountContainer
                                                                        name={`mortgagesNotesBondsPayableLessThanYear${index}`}
                                                                        label=""
                                                                        placeholder="0.00"
                                                                        disabled={index === 0 && !isBusinessTaxesPriorYear}
                                                                    />
                                                                </td>
                                                            ))}
                                                        </tr>
                                                        <tr>
                                                            <td><b>Other current liabilities</b></td>
                                                            {bussinessFormGroup.value.lstYears.map((year, index) => (
                                                                <td key={index}>
                                                                    <AmountContainer
                                                                        name={`otherCurrentLiabilities${index}`}
                                                                        label=""
                                                                        placeholder="0.00"
                                                                        disabled={index === 0 && !isBusinessTaxesPriorYear}
                                                                    />
                                                                </td>
                                                            ))}
                                                        </tr>
                                                        {
                                                            taxReturnForm === "1065" ? (
                                                                <>
                                                                    <tr>
                                                                        <td><b>All nonrecourse loans</b></td>
                                                                        {bussinessFormGroup.value.lstYears.map((year, index) => (
                                                                            <td key={index}>
                                                                                <AmountContainer
                                                                                    name={`allNonrecourseLoans${index}`}
                                                                                    label=""
                                                                                    placeholder="0.00"
                                                                                    disabled={index === 0 && !isBusinessTaxesPriorYear}
                                                                                />
                                                                            </td>
                                                                        ))}
                                                                    </tr>
                                                                    <tr>
                                                                        <td><b>Loan from partners</b></td>
                                                                        {bussinessFormGroup.value.lstYears.map((year, index) => (
                                                                            <td key={index}>
                                                                                <AmountContainer
                                                                                    name={`loanFromPartners${index}`}
                                                                                    label=""
                                                                                    placeholder="0.00"
                                                                                    disabled={index === 0 && !isBusinessTaxesPriorYear}
                                                                                />
                                                                            </td>
                                                                        ))}
                                                                    </tr>
                                                                </>
                                                            ) : (
                                                                <tr>
                                                                    <td><b>Loan from shareholders</b></td>
                                                                    {bussinessFormGroup.value.lstYears.map((year, index) => (
                                                                        <td key={index}>
                                                                            <AmountContainer
                                                                                name={`loanFromShareholders${index}`}
                                                                                label=""
                                                                                placeholder="0.00"
                                                                                disabled={index === 0 && !isBusinessTaxesPriorYear}
                                                                            />
                                                                        </td>
                                                                    ))}
                                                                </tr>
                                                            )
                                                        }
                                                        <tr>
                                                            <td><b>Mortgages, notes, bonds payable (&gt;= 1 year)</b></td>
                                                            {bussinessFormGroup.value.lstYears.map((year, index) => (
                                                                <td key={index}>
                                                                    <AmountContainer
                                                                        name={`mortgagesNotesBondsPayableMoreThanYear${index}`}
                                                                        label=""
                                                                        placeholder="0.00"
                                                                        disabled={index === 0 && !isBusinessTaxesPriorYear}
                                                                    />
                                                                </td>
                                                            ))}
                                                        </tr>
                                                        <tr>
                                                            <td><b>Other liabilities</b></td>
                                                            {bussinessFormGroup.value.lstYears.map((year, index) => (
                                                                <td key={index}>
                                                                    <AmountContainer
                                                                        name={`otherLiabilities${index}`}
                                                                        label=""
                                                                        placeholder="0.00"
                                                                        disabled={index === 0 && !isBusinessTaxesPriorYear}
                                                                    />
                                                                </td>
                                                            ))}
                                                        </tr>
                                                        {
                                                            taxReturnForm === "1065" ? (
                                                                <>
                                                                    <tr>
                                                                        <td><b>Partner's Capital Account</b></td>
                                                                        {bussinessFormGroup.value.lstYears.map((year, index) => (
                                                                            <td key={index}>
                                                                                <AmountContainer
                                                                                    name={`partnersCapitalAccount${index}`}
                                                                                    label=""
                                                                                    placeholder="0.00"
                                                                                    disabled={index === 0 && !isBusinessTaxesPriorYear}
                                                                                />
                                                                            </td>
                                                                        ))}
                                                                    </tr>
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <tr>
                                                                        <td><b>Capital stock</b></td>
                                                                        {bussinessFormGroup.value.lstYears.map((year, index) => (
                                                                            <td key={index}>
                                                                                <AmountContainer
                                                                                    name={`capitalStock${index}`}
                                                                                    label=""
                                                                                    placeholder="0.00"
                                                                                    disabled={index === 0 && !isBusinessTaxesPriorYear}
                                                                                />
                                                                            </td>
                                                                        ))}
                                                                    </tr>
                                                                    <tr>
                                                                        <td><b>Additional paid-in capital</b></td>
                                                                        {bussinessFormGroup.value.lstYears.map((year, index) => (
                                                                            <td key={index}>
                                                                                <AmountContainer
                                                                                    name={`additionalPaidInCapital${index}`}
                                                                                    label=""
                                                                                    placeholder="0.00"
                                                                                    disabled={index === 0 && !isBusinessTaxesPriorYear}
                                                                                />
                                                                            </td>
                                                                        ))}
                                                                    </tr>
                                                                    <tr>
                                                                        <td><b>Retained earnings</b></td>
                                                                        {bussinessFormGroup.value.lstYears.map((year, index) => (
                                                                            <td key={index}>
                                                                                <AmountContainer
                                                                                    name={`retainedEarnings${index}`}
                                                                                    label=""
                                                                                    placeholder="0.00"
                                                                                    disabled={index === 0 && !isBusinessTaxesPriorYear}
                                                                                />
                                                                            </td>
                                                                        ))}
                                                                    </tr>
                                                                    <tr>
                                                                        <td><b>Adjustments to shareholders' equity</b></td>
                                                                        {bussinessFormGroup.value.lstYears.map((year, index) => (
                                                                            <td key={index}>
                                                                                <AmountContainer
                                                                                    name={`adjustmentsToShareholdersEquity${index}`}
                                                                                    label=""
                                                                                    placeholder="0.00"
                                                                                    disabled={index === 0 && !isBusinessTaxesPriorYear}
                                                                                />
                                                                            </td>
                                                                        ))}
                                                                    </tr>
                                                                    <tr>
                                                                        <td><b>Cost of treasury stock</b></td>
                                                                        {bussinessFormGroup.value.lstYears.map((year, index) => (
                                                                            <td key={index}>
                                                                                <AmountContainer
                                                                                    name={`lessCostOfTreasuryStock${index}`}
                                                                                    label=""
                                                                                    placeholder="0.00"
                                                                                    disabled={index === 0 && !isBusinessTaxesPriorYear}

                                                                                />
                                                                            </td>
                                                                        ))}
                                                                    </tr>
                                                                </>
                                                            )
                                                        }
                                                        {/* total assests footer */}
                                                        <tr>
                                                            <td>
                                                                <span className="text-light">Total Liabilities & Shareholders' Equity</span>
                                                            </td>
                                                            <td>
                                                                <b className="ml-3">$ {calculateTotalLiabilities(0)}</b>
                                                            </td>
                                                            <td>
                                                                <b className="ml-3">$ {calculateTotalLiabilities(1)}</b>
                                                            </td>
                                                            <td>
                                                                <b className="ml-3">$ {calculateTotalLiabilities(2)}</b>
                                                            </td>
                                                            <td>
                                                                <b className="ml-3">$ {calculateTotalLiabilities(3)}</b>
                                                            </td>
                                                            <td>
                                                                <b className="ml-3">$ {calculateTotalLiabilities(4)}</b>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className="sticky-btn-footer d-flex justify-content-end">
                                                <button onClick={() => checkForBackButton(balanceSheetFormGroup)} type="button" className="lm-button2">Back</button>
                                                <button type="button" className="lm-button1 ml-3" onClick={handleBothIncomeAndBalanceSheet}>Save</button>
                                            </div>
                                        </form>
                                    )}
                                />
                            </div>
                        </div>


                        {/*Add a debt Schedule */}
                        <Modal
                            size="md"
                            show={DebtSchedulePopupShow}
                            onHide={() => setDebtSchedulePopup(false)}
                            aria-labelledby="DebtSchedulePopupLabel"
                            backdrop="static"
                            keyboard={false}
                            centered
                        >
                            <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="DebtSchedulePopupLabel">{DebtScheduleType} a Debt Schedule</h5>
                                        <button type="button" className="close" onClick={DebtSchedulePopupClose}>
                                            <span aria-hidden="true"><img src={close_popup} alt="" /></span>
                                        </button>
                                    </div>
                                    <FieldGroup
                                        control={debtScheduleInfoFormGroup}
                                        render={({ get, invalid }) => (
                                            <form>
                                                <Modal.Body>
                                                    <div className="row">
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <FormField
                                                                    name="creditorName"
                                                                    label="Creditor Name"
                                                                    id="creditorName"
                                                                    placeholder="Enter a Creditor Name"
                                                                    required={true}
                                                                    errorMessage={"Creditor Name is required"}
                                                                    maxlength={100}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <FieldControl
                                                                name="originalDate"
                                                                render={({ handler, touched, hasError, meta }) => (
                                                                    <div className="form-group input-date">
                                                                        <label htmlFor="originalDate">Original Date:</label>
                                                                        <DatePicker
                                                                            showIcon
                                                                            toggleCalendarOnIconClick
                                                                            selected={handler().value}
                                                                            isClearable={handler().value ? true : false}
                                                                            dateFormat="MMM d, yyyy"
                                                                            placeholderText="Month DD, YYYY"
                                                                            id="originalDate"
                                                                            yearDropdownItemNumber={100}
                                                                            scrollableYearDropdown
                                                                            showMonthDropdown
                                                                            showYearDropdown
                                                                            name="originalDate"
                                                                            onChange={(date) => {
                                                                                const formattedDate = date ? moment(date).format() : "";
                                                                                handler().onChange(formattedDate);
                                                                            }}
                                                                            onKeyDown={(e) => {
                                                                                e.preventDefault();
                                                                            }}
                                                                        />
                                                                        {/* Error messages below */}
                                                                        <span className="error-message"> {touched && hasError("required") && `Origianl date is required`}</span>
                                                                    </div>
                                                                )}
                                                            />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group input-dollar">
                                                                <AmountContainer
                                                                    name="originalAmount"
                                                                    label="Original Amount"
                                                                    placeholder="0.00"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group input-dollar">
                                                                <AmountContainer
                                                                    name="presentBalance"
                                                                    label="Present Balance"
                                                                    placeholder="0.00"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group input-percentage">
                                                                <AmountContainer
                                                                    name="interestRate"
                                                                    label="Interest Rate"
                                                                    placeholder="0.00"
                                                                    setMaxValidation={true}
                                                                    setMinValidation={true}
                                                                    minValue={0}
                                                                    maxValue={100}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group input-dollar">
                                                                <AmountContainer
                                                                    name="monthlyPayment"
                                                                    label="Monthly Payment"
                                                                    placeholder="0.00"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <FieldControl
                                                                name="maturityDate"
                                                                render={({ handler, touched, hasError, meta }) => (
                                                                    <div className="form-group input-date">
                                                                        <label htmlFor="Unpaid_Tax_Due_Date">Maturity Date</label>
                                                                        <DatePicker
                                                                            showIcon
                                                                            toggleCalendarOnIconClick
                                                                            selected={handler().value}
                                                                            isClearable={handler().value ? true : false}
                                                                            dateFormat="MMM d, yyyy"
                                                                            placeholderText="Month DD, YYYY"
                                                                            id="maturityDate"
                                                                            name="maturityDate"
                                                                            yearDropdownItemNumber={100}
                                                                            scrollableYearDropdown
                                                                            showMonthDropdown
                                                                            showYearDropdown
                                                                            onChange={(date) => {
                                                                                const formattedDate = date ? moment(date).format() : "";
                                                                                handler().onChange(formattedDate);
                                                                            }}
                                                                            onKeyDown={(e) => {
                                                                                e.preventDefault();
                                                                            }}
                                                                        />
                                                                        {/* Error messages below */}
                                                                        <span className="error-message"> {touched && hasError("required") && `Maturity date is required`}</span>
                                                                    </div>
                                                                )}
                                                            />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group">
                                                                <FormField
                                                                    name="collateralSecurity"
                                                                    label="Collateral Security"
                                                                    placeholder="Enter a Collateral Security"
                                                                    maxlength={50}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <FormRadio
                                                                name="isPayByLoan"
                                                                label="Pay by Loan Proceeds"
                                                                options={[
                                                                    { label: 'Yes', value: 'true' },
                                                                    { label: 'No', value: 'false' },
                                                                ]}
                                                            />
                                                        </div>

                                                    </div>
                                                </Modal.Body>
                                                <div className="modal-footer la-app-modal-footer">
                                                    {DebtScheduleType === "Edit" ?
                                                        (
                                                            <>
                                                                <button type="button" className="lm-button2 text-red" onClick={(e) => handleRemoveFormModal(e, "lstDebtScheduleInfo", debtScheduleInfoFormGroup.value.index, DebtSchedulePopupClose)}>Remove</button>
                                                                <button type="button" className="lm-button1" onClick={(e) => handleEditFormModal(e, "lstDebtScheduleInfo", debtScheduleInfoFormGroup, DebtSchedulePopupClose)}>Save</button>
                                                            </>
                                                        ) :
                                                        <button type="button" className="lm-button1" onClick={(e) => handleAddFormModal(e, "lstDebtScheduleInfo", debtScheduleInfoFormGroup, DebtSchedulePopupClose)}>Save</button>
                                                    }

                                                </div>
                                            </form>
                                        )}
                                    />
                                </div>
                            </div>
                        </Modal>
                        {/*Add a debt Schedule */}


                        {/* <!--Show no debt Tab Popup / Modal --> */}
                        <Modal
                            className='mark-comp-popup-cust-size'
                            show={confirmNoDebtPopShow}
                            onHide={() => setConfirmNoDebtPopShow(false)}
                            aria-labelledby="ConfirmNoDebtTabPopupLabel"
                            backdrop="static"
                            keyboard={false}
                            centered
                        >
                            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                <div className="modal-content">
                                    <div className="modal-body signout-modal">
                                        <h3 className="pt-4">
                                            By selecting “No debt”, all information in the debt schedule will be deleted.  Do you want to continue?
                                        </h3>
                                    </div>
                                    <div className="modal-footer la-app-modal-footer">
                                        <button type="button" className="lm-button2" onClick={() => setConfirmNoDebtPopShow(false)}>No</button>
                                        <a className="lm-button1" onClick={handleConfirmNoDebt}>Yes</a>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                        {/* <!--Show no debt Tab Popup / Modal --> */}


                        {/* <!--back to Previous Tab Popup / Modal --> */}
                        <Modal
                            className='mark-comp-popup-cust-size'
                            show={PreviousTabPopupShow}
                            onHide={() => setPreviousTabPopup(false)}
                            aria-labelledby="PreviousTabPopupLabel"
                            backdrop="static"
                            keyboard={false}
                            centered
                        >
                            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                                <div className="modal-content">
                                    <div className="modal-body signout-modal">
                                        <h3 className="pt-4">
                                            Going back will lead to the loss of unsaved data
                                        </h3>
                                    </div>
                                    <div className="modal-footer la-app-modal-footer">
                                        <button type="button" className="lm-button2" onClick={PreviousTabPopupClose}>Cancel</button>
                                        <a className="lm-button1" onClick={backPreviousTab}>Confirm</a>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                        {/* <!--back to Previous Tab Popup / Modal --> */}

                    </div>
                </div>

            </div >)
            }

        </>
    );
}

export default CashFlowBusiness;