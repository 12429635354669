/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import edit_pen from '../../../../assets/icons/edit-pen.svg';
import close_popup from '../../../../assets/icons/close_popup.svg';
import moment from 'moment';
import Constants from '../../../../helpers/Constants';
import { useDispatch } from 'react-redux';
import reducersAndActions from '../../../../_redux/slices';

const MilitaryService = ({ onUpdate, militaryServicesData }) => {
    const dispatch = useDispatch();

    const [militaryPopupShow, setMilitaryPopupShow] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [militaryServices, setMilitaryServices] = useState([]);
    const [type, setType] = useState("Add");

    const [formData, setFormData] = useState({
        branchofService: '',
        startDate: null,
        endDate: null,
        location: '',
        isDeleted: 0
    });
    const [editIndex, setEditIndex] = useState(null);

    useEffect(() => {
        setMilitaryServices(militaryServicesData);
    }, [militaryServicesData]);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setFormData({ ...formData, [id]: value });
    };

    const handleDateChange = (key, dateValue) => {
        const formattedDate = dateValue === null ? null : moment.utc(dateValue).local().format("YYYY-MM-DD");
        if (key === "startDate") {
            setStartDate(formattedDate);
            if (formattedDate > endDate) {
                setEndDate(null);
            }
        } else if (key === "endDate") {
            if (formattedDate < startDate) {
                dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "End date cannot be before start date", type: "error" }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                return; // prevent updating if the end date is invalid
            }
            setEndDate(formattedDate);
        }
        setFormData((prevData) => ({ ...prevData, [key]: formattedDate }));
    };

    const handleSubmit = () => {
        if (formData.branchofService === "") {
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "Branch of service is required", type: "error" }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
            return;
        }
        setMilitaryPopupShow(false);
        const updatedServices = [...militaryServices];
        if (editIndex !== null) {
            updatedServices[editIndex] = formData;
        } else {
            updatedServices.push(formData);
        }
        setMilitaryServices(updatedServices);
        onUpdate(updatedServices);
        setMilitaryPopupShow(false);
        setEditIndex(null);
    };

    const handleEdit = (index) => {
        const service = militaryServices[index];
        setFormData(service);
        setStartDate(service.startDate ? moment(service.startDate).format('MMM DD, YYYY') : null);
        setEndDate(service.endDate ? moment(service.endDate).format('MMM DD, YYYY') : null);
        setEditIndex(index);
        setType("Edit")
        setMilitaryPopupShow(true);
    };

    const handleRemove = () => {
        let indexData = { ...militaryServices[editIndex], isDeleted: 1 };
        const removedData = militaryServices.filter((item, index) => index !== editIndex);
        const updatedServices = [...removedData, indexData];
        setMilitaryServices(updatedServices);
        onUpdate(updatedServices);  // Pass data to parent
        setMilitaryPopupShow(false);
        setFormData({
            branchofService: '',
            startDate: null,
            endDate: null,
            location: '',
            isDeleted: 0
        });
        setEditIndex(null);
    };

    const handleAddNewPopup = () => {
        setType("Add")
        setMilitaryPopupShow(true)
        setFormData({
            branchofService: '',
            startDate: null,
            endDate: null,
            location: '',
            isDeleted: 0
        });
        setStartDate(null);
        setEndDate(null);
        setEditIndex(null);
    };

    const showPeriodDate = (formData) => {
        const [startDate, endDate] = [formData.startDate, formData.endDate];
        if (!startDate) return '';

        const formattedStartDate = moment(startDate).format('MMM YYYY'); // Format: 'Jan 2024'

        if (endDate) {
            const formattedEndDate = moment(endDate).format('MMM YYYY');
            return `${formattedStartDate} - ${formattedEndDate}`;
        } else {
            return `${formattedStartDate} - Present`;
        }
    };

    return (
        <div className="col-12 mt-4 mb-2">
            <div className="lm-card p-0">
                <div className="d-flex justify-content-between p-3">
                    <h3 className="lm-card-heading mb-0">Military Service</h3>
                    <a onClick={handleAddNewPopup} className="lm-button3"><span>+</span> {type} military service</a>
                </div>
                <table className="lm-inner-form-grid">
                    <thead>
                        <tr>
                            <th>Institution Name</th>
                            <th>Period</th>
                            <th>Location</th>
                            <th className='text-center'>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {militaryServices.map((service, index) => {
                            if (service.isDeleted === 1) return null;
                            return (
                                <tr key={index}>
                                    <td><b>{service.branchofService}</b></td>
                                    <td>{showPeriodDate(service)}
                                    </td>
                                    <td>{service.location}</td>
                                    <td className='text-center'>
                                        <img onClick={() => handleEdit(index)} title="Edit" className="lm-grid-edit-icon" src={edit_pen} alt="Edit" />
                                    </td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>

            {/* This is customized modal used for datepicker isuue purpose don't use this anywhere else */}
            <div className={`custom-modal cust-modal-md modal-open modal-backdrop ${militaryPopupShow ? "cust-modal-show" : "cust-modal-hide"}`}>
                <div className="modal-box ">
                    <div className="modal-header">
                        <h5 className="modal-title" id="militaryPopupLabel">{type} Military Service</h5>
                        <button type="button" className="close" onClick={() => setMilitaryPopupShow(false)}>
                            <span aria-hidden="true"><img src={close_popup} alt="Close" /></span>
                        </button>
                    </div>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="branchofService">Institution name<span className="text-danger">*</span></label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="branchofService"
                                        value={formData.branchofService}
                                        onChange={handleInputChange}
                                        placeholder="Enter branch of service"
                                        maxLength={100}
                                    />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group input-date">
                                    <label htmlFor="startingEndDates">Start date</label>
                                    <DatePicker
                                        showIcon
                                        toggleCalendarOnIconClick
                                        selected={startDate ? moment(startDate).format('MMM DD, YYYY') : ''}
                                        isClearable={startDate}
                                        dateFormat="MMM d, yyyy"
                                        placeholderText="Month DD, YYYY"
                                        yearDropdownItemNumber={100}
                                        scrollableYearDropdown
                                        showMonthDropdown
                                        showYearDropdown
                                        onChange={(e) => handleDateChange("startDate", e)}
                                        maxDate={moment().toDate()}
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="col-md-6">
                                <div className="form-group input-date">
                                    <label htmlFor="startingEndDates">End date</label>
                                    <DatePicker
                                        showIcon
                                        toggleCalendarOnIconClick
                                        selected={endDate ? moment(endDate).format('MMM DD, YYYY') : ''}
                                        isClearable={endDate}
                                        dateFormat="MMM d, yyyy"
                                        placeholderText="Month DD, YYYY"
                                        yearDropdownItemNumber={100}
                                        scrollableYearDropdown
                                        showYearDropdown
                                        showMonthDropdown
                                        onChange={(e) => handleDateChange("endDate", e)}
                                        maxDate={moment().toDate()}
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="col-md-12">
                                <div className="form-group">
                                    <label htmlFor="location">Location</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="location" value={formData.location}
                                        onChange={handleInputChange}
                                        placeholder="Enter a Location Name"
                                        maxLength={250}
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                    <div className="modal-footer la-app-modal-footer">
                        {editIndex !== null ? (
                            <button onClick={handleRemove} type="button" className="lm-button2 text-red">Remove</button>
                        ) : <button type="button" className="lm-button2" onClick={() => setMilitaryPopupShow(false)}>Cancel</button>}
                        <button onClick={handleSubmit} type="button" className="lm-button1">Save</button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MilitaryService;
