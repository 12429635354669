import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal'
import axiosInstance from '../../../helpers/axiosConfig';
import close_popup from '../../../assets/icons/close_popup.svg';
import { Link } from 'react-router-dom';
import { encryptText } from '../../../helpers/DynamicFunctions';
const TransactionsModal = ({ transactionsPopupShow, setTransactionsPopup, modalData }) => {

    const [transactionsData, setTransactionsData] = useState([])
    const transactionsPopupClose = () => {
        setTransactionsPopup(false);
    };

    useEffect(() => {
        setTransactionsData([])
        const storedSessionId = localStorage.getItem('sessionId');
        if (modalData) {
            const queryParams = `?PageIndex=1&PageSize=100&userId=${modalData?.id}&RoleName=${modalData?.roleName}&Status=All&SessionId=${storedSessionId}`;
            axiosInstance.get(`/api/LoanApplication/GetLoanApplicationList${queryParams}`).then((res) => {
                const resultObject = JSON.parse(res.data.resultObject)
                setTransactionsData(resultObject)
            }).catch((error) => {
                console.log(error);
            })
        }
    }, [modalData, transactionsPopupShow]);

    let classMapper = {
        "NEW": "new",
        "New": "new",
        "Documents Required": "document-required",
        "On Hold": "on-hold",
        "Review Required": "review-required",
        "Closed": "closed",
        "Completed": "completed",
        "In Progress": "in-progress",
    };

    return (
        <Modal
            className="fade-right drawer-modal transactions-drawer"
            show={transactionsPopupShow}
            onHide={transactionsPopupClose}
            aria-labelledby="transactionsPopupLabel"
        >
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="drawer-head d-flex justify-content-between">
                        <div>
                            <h3>{modalData?.firstName} {modalData?.lastName}</h3>
                            <span className="text-light">Transactions</span>
                        </div>
                        <button type="button" className="close" onClick={transactionsPopupClose}>
                            <span aria-hidden="true"><img src={close_popup} alt="Close" /></span>
                        </button>
                    </div>
                    <div className="drawer-body mt-3">
                        {
                            transactionsData.map(data => (
                                <div className="user-transition">
                                    <Link
                                        to={`/application-detail/${encryptText(data.Id)}`}
                                        state={{ data }}
                                        className="user_name">{data.ApplicationNumber}
                                    </Link>
                                    {
                                        <div className={`${classMapper[data.ApplicationStatus]} status`}>
                                            {data.ApplicationStatus?.charAt(0)?.toUpperCase() + data.ApplicationStatus?.slice(1)?.toLowerCase() || ""}
                                        </div>
                                    }
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
        </Modal>
    );
};

export default TransactionsModal;
