/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import dummy_avtar from '../assets/img/dummy_avtar.png';
import { SidePanel, ProfileHeader } from "@components";
import Preloader from '../plugins/Preloader';
import { apiLogsColumnsList } from '../helpers/DropdownsAndMappers';
import { GetAPILogsList, GetUserList } from '../services/logsServices';
import './styles/Users.css';
import Constants from "../helpers/Constants";
import reducersAndActions from "../_redux/slices/index";
import { AllcheckformatDate } from './loan-app-tabs/personalProfile/DatesCheck';
import DynamicTable from '../plugins/DynamicTable';

const providerListOption = [
    { value: '', label: 'All Providers' },
    { value: 'Microbilt', label: 'Microbilt' },
    { value: 'Hello Sign', label: 'Hello Sign' },
];

const formatDateTime = (dateTimeString) => {
    return moment.utc(dateTimeString).local().format('MMM D, YYYY, h:mm A');
};

const formatDate = (date) => {
    return date ? moment(date).format('MM/DD/YYYY') : '';
};

const useFetchData = (initialFetchFunction, dispatch) => {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(reducersAndActions.actions.sessionActions.setLoading(loading));
    }, [loading]);

    const [data, setData] = useState([]);

    useEffect(() => {
        document.title = "API Logs";
        const fetchData = async () => {
            setLoading(true);
            const result = await initialFetchFunction();
            setData(result || []);
            setLoading(false);
        };
        fetchData();
    }, []);

    return [data, loading];
};

function APILogs() {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.auth.user);

    const [searchText, setSearchText] = useState("");
    const [selectValue, setSelectValue] = useState("");
    const [selectedProvider, setSelectedProvider] = useState("");
    const [dateRange, setDateRange] = useState([null, null]);
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalData, setTotalData] = useState(0);
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(reducersAndActions.actions.sessionActions.setLoading(loading));
    }, [loading]);
    // Add loading state


    const handleDate = useCallback(() => {
        const startDateFormatted = dateRange[0] ? AllcheckformatDate(moment(dateRange[0]).format('YYYY-MM-DD')) : null;
        const endDateFormatted = dateRange[1] ? AllcheckformatDate(moment(dateRange[1]).format('YYYY-MM-DD')) : null;
        return {
            startDateNew: startDateFormatted,
            endDateNew: endDateFormatted,
        };
    }, [dateRange]);

    const fetchUsers = async () => {
        const storedSessionId = localStorage.getItem('sessionId');
        const response = await GetUserList({
            PageIndex: 1,
            PageSize: 5000,
            roleName: "",
            SessionId: storedSessionId,
        });
        return response?.resultObject || [];
    };

    const fetchInquiries = async () => {
        try {
            const queryObj = {
                PageIndex: pageIndex,
                PageSize: pageSize,
                UserId: selectValue === "" ? 0 : selectValue,
                RoleName: user.roleName,
                DateFrom: handleDate().startDateNew || formatDate(moment().subtract(15, 'days')),
                DateTo: handleDate().endDateNew || formatDate(new Date()),
                IsBulk: 0,
                SessionId: localStorage.getItem('sessionId'),
                RoleType: -1,
                SearchText: searchText,
                Provider: selectedProvider
            };

            const response = await GetAPILogsList(queryObj);

            if (response?.status === 404) {
                dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "Resource not found. Please check the URL or try again later.", type: "error", }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                return;
            }

            const totalRecords = response?.message.match(/Total (\d+) Records/)?.[1] || 0;
            setTotalData(Number(totalRecords));

            return JSON.parse(response?.resultObject || '[]');
        } catch (error) {
            dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: "An error occurred while fetching data. Please try again later.", type: "error", }));
            setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
            return;
        } finally {
            setLoading(false); // Reset loading state
        }
    };

    // Debounce function for search input
    const debouncedFetchInquiries = useCallback(debounce(async () => {
        setLoading(true); // Set loading state
        const inquiries = await fetchInquiries();
        setData(inquiries);
        setLoading(false); // Reset loading state
    }, 1000), [searchText, selectValue, pageIndex, pageSize, dateRange, selectedProvider]);

    const [users, usersLoading] = useFetchData(fetchUsers, dispatch);

    useEffect(() => {
        if (dateRange[0] && dateRange[1]) {
            fetchInquiries();
        }
    }, [dateRange]);

    useEffect(() => {
        debouncedFetchInquiries();
        return () => {
            debouncedFetchInquiries.cancel();
        };
    }, [debouncedFetchInquiries]);

    const userOptions = useMemo(() => [
        { value: '', label: 'All users' },
        ...users.map(user => ({ value: user.id, label: `${user.firstName} ${user.lastName}` }))
    ], [users]);

    const dynamicTableDisplay = (data) => (
        <tr key={data.id}>
            <td><b className="user_name">{data.APIRequest || "NA"}</b></td>
            <td>{data.UserName || "NA"}</td>
            <td>{data.TransctionNumber || "NA"}</td>
            <td>{data.TransctionName || "NA"}</td>
            <td>{formatDateTime(data.CreatedDate)}</td>
            <td><b>{data.Provider || "NA"}</b></td>
        </tr>
    );

    const handleSetList = (newList) => {
        setData(newList);
    };

    return (
        <>
            <SidePanel />
            <ProfileHeader />

            {usersLoading || loading ? <Preloader /> : (
                <div>
                    <div className="dashboard-wrap users-screen">
                        <div className="d-sm-flex d-block justify-content-between filter-search-group">
                            <div className='d-flex flex-wrap mb-3 mb-sm-0'>
                                <>
                                    <div style={{ minWidth: '200px' }}>
                                        <Select
                                            className="basic-single mr-3"
                                            classNamePrefix="select"
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            value={userOptions.find(option => option.value === selectValue)}
                                            options={userOptions}
                                            onChange={(selectedOption) => {
                                                setPageIndex(1);
                                                setSelectValue(selectedOption.value);
                                            }}
                                        />
                                    </div>
                                    <div style={{ minWidth: '200px' }}>
                                        <Select
                                            className="basic-single"
                                            classNamePrefix="select"
                                            menuPortalTarget={document.body}
                                            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                                            options={providerListOption}
                                            value={providerListOption.find(option => option.value === selectedProvider)}
                                            onChange={(selectedOption) => {
                                                setPageIndex(1);
                                                setSelectedProvider(selectedOption.value);
                                            }}
                                        />
                                    </div>
                                </>
                            </div>
                            <div className='d-flex'>
                                <div className="input-date two-month-datepicker log-date mr-3">
                                    <DatePicker
                                        showIcon
                                        toggleCalendarOnIconClick
                                        selected={dateRange[0]}
                                        selectsRange={true}
                                        startDate={dateRange[0]}
                                        endDate={dateRange[1]}
                                        onChange={(update) => setDateRange(update)}
                                        onCalendarClose={() => {
                                            if (dateRange[0] && dateRange[1]) {
                                                setPageIndex(1);
                                                fetchInquiries();
                                            }
                                        }}
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                        monthsShown={2}
                                        isClearable={true}
                                        dateFormat="MMM d, yyyy"
                                        placeholderText="Month DD, YYYY - Month DD, YYYY"
                                        yearDropdownItemNumber={100}
                                        scrollableYearDropdown
                                        showMonthDropdown
                                        showYearDropdown
                                    />
                                </div>
                                <div className="lm-search-bar mb-0">
                                    <input
                                        type="text"
                                        id="searchInput"
                                        placeholder="Search"
                                        value={searchText}
                                        onChange={(e) => {
                                            setSearchText(e.target.value)
                                            setPageIndex(1)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        {data && data.length > 0 ? (
                            <DynamicTable
                                data={data}
                                dynamicTableDisplay={dynamicTableDisplay}
                                columns={apiLogsColumnsList}
                                changePageSize={setPageSize}
                                pageSize={pageSize}
                                setList={handleSetList}
                                pageIndex={pageIndex}
                                setPageIndex={setPageIndex}
                                total={totalData}
                            />
                        ) : (
                            <div className='no-data-card text-center place-content-center'>
                                <img src={dummy_avtar} alt="" className='mb-4' />
                                <h3 className='f-700 mb-2 ml-2'>You're all caught up!</h3>
                            </div>
                        )
                        }
                    </div>
                </div>
            )}
        </>
    );
}

export default APILogs;