/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { DownloadHelloSignedFile } from "../services/documentsServices";

function DownloadHelloSignDocuments() {
    useEffect(() => {
        downloadHelloSignFiles();
    }, []);

    function getCurrentUrlParams() {
        const params = {};
        const parsedUrl = new URL(window.location.href);

        // Iterate through all search parameters
        for (const [key, value] of parsedUrl.searchParams.entries()) {
            params[key] = value;
        }

        return params;
    }

    const downloadHelloSignFiles = async () => {
        const currentUrlParams = getCurrentUrlParams();
        await DownloadHelloSignedFile({ ...currentUrlParams }).then((response) => {
            if (response) {
                window.open('/loan-application', '_self');
            }
        }
        ).catch((error) => {
            alert('Error downloading file');
            console.log(error);
        });
    };


    const AccessRestricted = () => {
        return (
            <>
                <div className="">
                    <div className="not-found-page-body">
                        <div className="success-page-box not-found-box vh-100">
                            <p>Please wait while we are processing your request...</p>
                        </div>
                    </div>
                </div>
            </>
        );
    }

    return <AccessRestricted />;
}

export default DownloadHelloSignDocuments;
