/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import { SidePanel, ProfileHeader } from "@components";
import Select from 'react-select';
import Preloader from '../plugins/Preloader';
import React, { useState, useEffect } from 'react';
import './styles/Users.css';
import back_arrow from '../assets/icons/back_arrow.svg';
import brdcrm_nav from '../assets/icons/brdcrm_nav.svg';
import axiosInstance from '../helpers/axiosConfig';
import { useLocation, useNavigate } from 'react-router-dom';
import ChangePasswordModal from "./user/adminAndAdvisor/ChangePasswordModal";
import SelectAdminModal from "./user/adminAndAdvisor/SelectAdminModal";
import { useDispatch } from "react-redux";
import reducersAndActions from "../_redux/slices";
import Constants from "../helpers/Constants";
import convertToIST from "./user/commonFunctions/User";
import { FieldControl, FieldGroup, FormBuilder, Validators } from "react-reactive-form";
import { contactNumberValidate, emailValidator, validStringValidator } from "../helpers/DynamicFunctions";
import FormField from "../components/FormField/FormField";
import ContactNumberField from "../components/ContactField/ContactField";

function UserForm() {
  const location = useLocation();
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const queryParams = new URLSearchParams(location.search);
  const userId = queryParams.get("id") || '';

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(reducersAndActions.actions.sessionActions.setLoading(loading));
  }, [loading]);

  // Dropdown options for role and status
  const DropdownOptionsForRole = [
    { value: 2, label: 'Advisor' },
    { value: 1, label: 'Admin' }
  ];

  const DropdownOptionsForStatus = [
    { value: false, label: 'Inactive' },
    { value: true, label: 'Active' }
  ];

  const [formData, setFromData] = useState(
    FormBuilder.group({
      id: [0],
      firstName: ["", [Validators.required, validStringValidator]],
      lastName: ["", [Validators.required, validStringValidator]],
      company: ["", [Validators.required, validStringValidator]],
      institution: [""],
      email: ["", [Validators.required, emailValidator]],
      officeNumber: ["", [Validators.required, contactNumberValidate]],
      mobileNumber: ["", [Validators.required, contactNumberValidate]],
      program: [""],
      role: [""],
      status: [""],
      code: "",
      allowESignature: [false],
      creditReports: [false],
      thirdPartyReports: [false],
      lockAccount: [false],
      allowPasswordReset: [true],
      RoleId: [1],
      DemoCode: [""],
      LastLoginDateTime: [""],
      ROLENAME: [""],
    })
  );

  // Set document title
  useEffect(() => {
    document.title = "User Detail";
    setFromData(formData);
    getUseDetailsById();
  }, []);


  const getUseDetailsById = async () => {
    if (userId) {
      setLoading(true)
      const apiUrl = '/api/User/GetUserDetailsById';
      const queryParams = `?userId=${userId}&SessionId=${localStorage.getItem('sessionId')}`;

      axiosInstance.get(`${apiUrl}${queryParams}`).then((res) => {
        const resultObject = JSON.parse(res.data.resultObject)[0];
        console.log(resultObject);
        formData.patchValue({
          id: resultObject.ID,
          firstName: resultObject.FirstName,
          lastName: resultObject.LastName,
          company: resultObject.CompanyEntity,
          institution: resultObject.Institution,
          email: resultObject.Email,
          officeNumber: resultObject.OfficeNumber,
          mobileNumber: resultObject.MobileNumber,
          program: resultObject.Program,
          role: resultObject.ROLENAME === "Admin" ? DropdownOptionsForRole[1] : DropdownOptionsForRole[0],
          status: resultObject.StatusDesc === "Active" ? DropdownOptionsForStatus[1] : DropdownOptionsForStatus[0],
          allowESignature: resultObject.AdvisorDocuSign !== "" ? resultObject.AdvisorDocuSign : "False",
          creditReports: resultObject.CreditReports !== "" ? resultObject.CreditReports : "False",
          thirdPartyReports: resultObject.ThirdPartyReports !== "" ? resultObject.ThirdPartyReports : "False",
          lockAccount: resultObject.IsUserLocked !== "" ? resultObject.IsUserLocked : "False",
          allowPasswordReset: resultObject.IsAllowChange !== "" ? resultObject.IsAllowChange : "True",
          RoleId: parseInt(resultObject.RoleId, 10),
          DemoCode: resultObject.DemoCode || "",
          LastLoginDateTime: resultObject.LastLoginDateTime,
          ROLENAME: resultObject.ROLENAME
        })
        setLoading(false)
      }).catch((error) => {
        setLoading(false)
        dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: error?.response.data.message, type: 'error' }));
        setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        console.error('Error submitting form:', error.response ? error.response.data : error.message);
      })
    } else {
      formData.patchValue({ allowPasswordReset: "True", status: DropdownOptionsForStatus[1], CreditReports: "False", thirdPartyReports: "False", allowESignature: "False", lockAccount: "False", RoleId: 1, role: DropdownOptionsForRole[0] });
    }
  }

  const [changePassPopupShow, setChangePassPopup] = useState(false);
  const [adminPopup, setAdminPopup] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);

  // Go back to previous page
  const goBack = () => {
    window.history.back();
  };

  // Handle select field change
  const handleSelectChange = (selectedOption, actionMeta) => {
    if (selectedOption.label === "Admin") {
      setAdminPopup(true)
    } else {
      formData.patchValue({ [actionMeta.name]: selectedOption });
      setIsConfirm(false)
    }
  };

  useEffect(() => {
    if (isConfirm) {
      formData.patchValue({ role: { value: 1, label: "Admin" } });
    }
  }, [isConfirm]);

  // Marking all input fields as touched if the form is not valid
  const markAllAsTouched = (formGroup) => {
    Object.keys(formGroup.controls).forEach((controlName) => {
      const control = formGroup.get(controlName);
      if (control && control.status === "INVALID") {
        control.active = true;
        control.touched = true;
        if (document.getElementById(controlName)) {
          document.getElementById(controlName).focus();
        }
      }
    });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    try {
      if (formData.status === "VALID") {
        setLoading(true)

        // Append form data 
        const formDataToSend = new FormData();
        formDataToSend.append("id", formData.value.id ? formData.value.id : 0);
        formDataToSend.append("FirstName", formData.value.firstName);
        formDataToSend.append("LastName", formData.value.lastName);
        formDataToSend.append("EmailAddress", formData.value.email);
        formDataToSend.append("CompanyOrEntity", formData.value.company);
        formDataToSend.append("Telephone", formData.value.officeNumber);
        formDataToSend.append("Mobile", formData.value.mobileNumber);
        formDataToSend.append("RoleId", formData.value.role.value);
        formDataToSend.append("IsPasswordResetAllowed", formData.value.allowPasswordReset);
        formDataToSend.append("CreditReports", formData.value.creditReports);
        formDataToSend.append("ThirdPartyReports", formData.value.thirdPartyReports);
        formDataToSend.append("AdvisorDocuSign", formData.value.allowESignature);
        formDataToSend.append("IsUserLocked", formData.value.lockAccount);
        formDataToSend.append("Status", formData.value.status.value);

        const response = await axiosInstance.post("/api/User/InsertUpdateAdminUserInfo", formDataToSend, {
          params: {
            WebURL: "http://localhost:3000/",
            SessionId: localStorage.getItem('sessionId')
          }
        });;
        setLoading(false)
        if (response.status === 200) {
          dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.data.message, type: "success" }));
          setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
          setAdminPopup(false);
          navigate('/users/advisor-admin')
        } else {
          dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.message, type: 'error' }));
          setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
        }
      } else {
        markAllAsTouched(formData);
      }
    } catch (error) {
      setLoading(false)
      dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: error.response.data.message, type: 'error' }));
      setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
      console.error('Error submitting form:', error, error.response ? error.response.data : error.message);
    }
  };

  return (
    <>
      {/* SidePanel */}
      <SidePanel />

      {/* ProfileHeader */}
      <ProfileHeader />

      {loading ? <Preloader /> : (
        <>
          <div className="dashboard-wrap users-screen">
            {/* User breadcrumb */}
            <div className="lm-breadcrumb d-flex">
              <a onClick={goBack} className="back-btn mr-2" >
                <img src={back_arrow} alt="" />
              </a>
              <span onClick={goBack} className="text-light mr-1 cursor-pointer">
                Users
              </span>
              <img src={brdcrm_nav} alt="" />
              <span className="text-dark ml-1">
                {formData.value?.firstName} {formData.value?.lastName}
              </span>
            </div>
            {/* End User breadcrumb */}


            {
              userId && (
                <div className="last-login-box">
                  <span>Last Login: {convertToIST(formData.value.LastLoginDateTime)}</span>
                </div>
              )
            }


            <FieldGroup
              control={formData}
              render={({ get, invalid }) => (
                <>
                  <div className="row mt-4 mb-5 pb-2">
                    <div className="col-md-4 col-lg-3">
                      <div className="form-group">
                        <FormField
                          name="firstName"
                          label="First Name"
                          id="firstName"
                          placeholder="Enter First Name"
                          onlyCharacters={true}
                          required={true}
                          errorMessage="First Name is required"
                          maxlength={50}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-3">
                      <div className="form-group">
                        <FormField
                          name="lastName"
                          label="Last Name"
                          id="lastName"
                          placeholder="Enter Last Name"
                          onlyCharacters={true}
                          required={true}
                          errorMessage="Last Name is required"
                          maxlength={50}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-3">
                      <div className="form-group">
                        <FormField
                          name="company"
                          label="Company / Entity"
                          id="company"
                          placeholder="Enter Company / Entity"
                          required={true}
                          errorMessage="Company / Entity is required"
                          maxlength={50}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-3">
                      <div className="form-group">
                        <FormField
                          name="email"
                          label="Email"
                          id="email"
                          placeholder="Enter Email"
                          required={true}
                          disabled={userId ? true : false}
                          errorMessage="Email is required"
                          patternRequired={true}
                          patternMessage="Please enter a valid email"
                          maxlength={50}
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-3">
                      <div className="form-group">
                        <ContactNumberField
                          id="officeNumber"
                          name="officeNumber"
                          required={true}
                          label="Office Number"
                          placeholder="Enter Office Number"
                          errorMessage="Office Number is required"
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-3">
                      <div className="form-group">
                        <ContactNumberField
                          id="mobileNumber"
                          name="mobileNumber"
                          required={true}
                          label="Mobile Number"
                          placeholder="Enter Mobile Number"
                          errorMessage="Mobile Number is required"
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-3">
                      <div className="form-group">
                        <label htmlFor="role">Role</label>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          options={DropdownOptionsForRole}
                          value={formData.value.role}
                          onChange={handleSelectChange}
                          name="role"
                        />
                      </div>
                    </div>
                    <div className="col-md-4 col-lg-3">
                      <div className="form-group">
                        <label htmlFor="status">Status</label>
                        <Select
                          className="basic-single"
                          classNamePrefix="select"
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          }}
                          options={DropdownOptionsForStatus}
                          value={formData.value.status}
                          onChange={handleSelectChange}
                          name="status"
                        />
                      </div>
                    </div>

                    {/* Start Checkboxes */}
                    <div className="col-12">
                      {/* Checkboxes for various options */}
                      <div className="form-group mb-2 form-check">
                        <FieldControl
                          name="allowESignature"
                          render={({ handler }) => (
                            <>
                              <input type="checkbox" className="form-check-input" id="allowESignature" checked={handler().value === "True"}
                                onChange={(e) => {
                                  handler().onChange(e.target.checked ? "True" : "False")
                                  formData.patchValue({ allowESignature: e.target.checked ? "True" : "False" })
                                }}
                              />
                              <label className="form-check-label" htmlFor="allowESignature">Allow E-Signature</label>
                            </>
                          )}
                        />
                      </div>
                      <div className="form-group mb-2 form-check">
                        <FieldControl
                          name="creditReports"
                          render={({ handler }) => (
                            <>
                              <input type="checkbox" className="form-check-input" id="creditReports" checked={handler().value === "True"}
                                onChange={(e) => {
                                  handler().onChange(e.target.checked ? "True" : "False")
                                  formData.patchValue({ creditReports: e.target.checked ? "True" : "False" })
                                }}
                              />
                              <label className="form-check-label" htmlFor="creditReports">Credit Reports</label>
                            </>
                          )}
                        />
                      </div>
                      <div className="form-group mb-2 form-check">
                        <FieldControl
                          name="thirdPartyReports"
                          render={({ handler }) => (
                            <>
                              <input type="checkbox" className="form-check-input" id="thirdPartyReports" checked={handler().value === "True"}
                                onChange={(e) => {
                                  handler().onChange(e.target.checked ? "True" : "False")
                                  formData.patchValue({ thirdPartyReports: e.target.checked ? "True" : "False" })
                                }}
                              />
                              <label className="form-check-label" htmlFor="thirdPartyReports">Third Party Reports</label>
                            </>
                          )}
                        />
                      </div>
                      <div className="form-group mb-2 form-check">
                        <FieldControl
                          name="lockAccount"
                          render={({ handler }) => (
                            <>
                              <input type="checkbox" className="form-check-input" id="lockAccount" checked={handler().value === "True"}
                                onChange={(e) => {
                                  handler().onChange(e.target.checked ? "True" : "False")
                                  formData.patchValue({ lockAccount: e.target.checked ? "True" : "False" })
                                }}
                              />
                              <label className="form-check-label" htmlFor="lockAccount">Lock Account</label>
                            </>
                          )}
                        />
                      </div>
                      <div className="form-group mb-2 form-check">
                        <FieldControl
                          name="allowPasswordReset"
                          render={({ handler }) => (
                            <>
                              <input type="checkbox" className="form-check-input" id="allowPasswordReset" checked={handler().value === "True"}
                                onChange={(e) => {
                                  handler().onChange(e.target.checked ? "True" : "False")
                                  formData.patchValue({ allowPasswordReset: e.target.checked ? "True" : "False" })
                                }}
                              />
                              <label className="form-check-label" htmlFor="allowPasswordReset">Allow Password Reset</label>
                            </>
                          )}
                        />
                      </div>
                    </div>
                    {/* End Checkboxes */}

                    {
                      userId && (
                        <div className="col-12 mt-4">
                          <button onClick={() => setChangePassPopup(true)} type="button" className="lm-button2">
                            Change Password
                          </button>
                        </div>
                      )
                    }

                  </div>
                </>
              )}
            />

            {/* Sticky buttons at the bottom */}
            <div className="sticky-btn-footer d-flex justify-content-end">
              <button type="button" className="lm-button2" onClick={goBack}>Back</button>
              <button type="button" className="lm-button1 ml-3" onClick={handleSubmit}>Save</button>
            </div>
            {/* End Sticky buttons */}

            {/* Change Password Popup / Modal */}
            <ChangePasswordModal
              userdetails={formData.value}
              changePassPopupShow={changePassPopupShow}
              setChangePassPopup={setChangePassPopup}
            />

            {/* End Change Password Popup / Modal */}
            <SelectAdminModal
              adminPopup={adminPopup}
              setAdminPopup={setAdminPopup}
              setIsConfirm={setIsConfirm}
            />
          </div>
        </>
      )}
    </>


  );
}

export default UserForm;
