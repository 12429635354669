/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { SidePanel, ProfileHeader } from "@components";
import Preloader from '../plugins/Preloader';
import { getInquiryList } from '../services/inquiryServices';
import { getSessionId } from '../services/storageData';
import { useDispatch } from 'react-redux';
import Constants from '../helpers/Constants';
import reducersAndActions from '../_redux/slices/index';
import DynamicTable from '../plugins/DynamicTable';
import { inquiryHeader } from '../helpers/DropdownsAndMappers';
import dummy_avtar from '../assets/img/dummy_avtar.png';
import moment from 'moment';

function Inquiries() {
    // Meta Title
    useEffect(() => {
        document.title = "Inquiries";
    }, []);

    // Declaring variables
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        dispatch(reducersAndActions.actions.sessionActions.setLoading(loading));
    }, [loading]);

    const [totalData, setTotalData] = useState(0);
    const [inquiries, setInquiries] = useState([]);
    const [pageIndex, setPageIndex] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [searchText, setSearchText] = useState("");

    useEffect(() => {
        getInquiries();
    }, []);
    // Preloader End

    const getInquiries = async () => {
        try {
            setLoading(true);
            let queryObj = {
                PageIndex: pageIndex,
                PageSize: pageSize,
                SessionId: getSessionId()
            };

            if (searchText) {
                queryObj["SearchText"] = searchText;
            }
            let response = await getInquiryList(queryObj);
            if (response.status === 200 && response.resultObject) {
                setInquiries(response.resultObject);
                let total = response.message.split(" ");
                setTotalData(Number(total[1]));
                setLoading(false);
            } else {
                dispatch(reducersAndActions.actions.toasterActions.showToaster({ visible: true, message: response.message, type: "error" }));
                setTimeout(() => dispatch(reducersAndActions.actions.toasterActions.hideToaster()), Constants.TOASTERHIDETIME);
                setLoading(false);
                setTotalData(0);
                setInquiries([]);
            }
        } catch (err) {
            console.log(err);
            setLoading(false);
            setTotalData(0);
        }
    }

    useEffect(() => {
        getInquiries();
    }, [pageSize, pageIndex]);

    const formatDateTime = (dateTimeString) => {
        return moment.utc(dateTimeString).local().format('MMM D, YYYY, h:mm A');
    };

    const dynamicTableDisplay = (data) => {
        return (
            <tr>
                <td>
                    <div className="user_name">{data.name}</div>
                    <span className="user_email text-light">{data.email}</span>
                </td>
                <td>
                    {data.subject}
                </td>
                <td>
                    <span className="text-light">{formatDateTime(data.date)}</span>
                </td>
                <td className="msg-col">
                    <div className="msg-content">
                        {data.message}
                    </div>
                </td>
            </tr>
        )
    }

    return (
        <>
            {/* SidePanel */}
            <SidePanel />

            {/* ProfileHeader */}
            <ProfileHeader />

            {loading ? (<Preloader />) : (
                <div>
                    <div className="dashboard-wrap">
                        <div className="inquiries-wrap">
                            {/* Search Input Box */}
                            <div className="d-flex justify-content-between filter-search-group">
                                <div></div>
                                <div className="lm-search-bar">
                                    <input type="text" id="searchInput" placeholder="Search" value={searchText} onChange={(e) => setSearchText(e.target.value)} onBlur={getInquiries} />
                                </div>
                            </div>

                            {/* Table Starts */}
                            {/* <table className="lm-grid-table inquiries-table" id="loanAppTable">
                        </table> */}

                            {
                                inquiries.length > 0 ? (
                                    <DynamicTable
                                        data={inquiries}
                                        dynamicTableDisplay={dynamicTableDisplay}
                                        columns={inquiryHeader}
                                        changePageSize={setPageSize}
                                        pageSize={pageSize}
                                        total={totalData}
                                        setList={setInquiries}
                                        setPageIndex={setPageIndex}
                                        pageIndex={pageIndex}
                                    />) : (
                                    <div className='no-data-card text-center place-content-center'>
                                        <img src={dummy_avtar} alt="" className='mb-4' />
                                        <h3 className='f-700 mb-2 ml-2'>You're all caught up!</h3>
                                    </div>
                                )
                            }
                        </div>

                    </div>
                </div>
            )}
        </>
    );
}

export default Inquiries;